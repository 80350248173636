import firebase from "firebase/compat/app";
import Geojson, { isCachedGeojson, isCacheEnabled } from "types/Geojson";
import * as localforage from "localforage";
import * as Rx from "rxjs";
import { bufferTime, filter } from "rxjs/operators";
import { Map } from "immutable";
import React, { useContext, useEffect, useState } from "react";
import { dbFirebase } from "features/firebase";
import Photo from "types/Photo";
import Feature, {
  EMPTY_FEATURES_CONTAINER,
  FeaturesContainer,
  merge
} from "types/Feature";
import { RealtimeUpdate } from "features/firebase/dbFirebase";
import _ from "lodash";
import useAsyncEffect from "hooks/useAsyncEffect";
import { useUser } from "./UserProvider";
import { NITRATE_READING_TYPE } from "types/Readings/Nitrate";
import { PHOSPHATE_READING_TYPE } from "types/Readings/Phosphate";
import { PH_READING_TYPE } from "types/Readings/Ph";
import { TEMPERATURE_READING_TYPE } from "types/Readings/Temperature";
import { COLIFORMS_READING_TYPE } from "types/Readings/Coliforms";

const CACHE_KEY = "cachedGeoJson";

const photoToFeature = (photo: Photo): Feature => ({
  feature: "Feature",
  geometry: {
    type: "Point",
    coordinates: [photo.location.longitude, photo.location.latitude]
  },
  properties: photo
});

const toFeaturesDict = (photos: Photo[]): Map<string, Feature> => {
  return Map(photos.map((photo) => [photo.id, photoToFeature(photo)]));
};

const cachedGeojsonToPhotosContainer = (
  geojson: Geojson
): FeaturesContainer => {
  return {
    featuresDict: toFeaturesDict(
      geojson.features.map((f: Feature): Photo => {
        const serializedProperties = f.properties as Photo;
        return {
          ...serializedProperties,
          location: new firebase.firestore.GeoPoint(
            f.geometry.coordinates[1],
            f.geometry.coordinates[0]
          )
        };
      })
    ),
    geojson
  };
};

const photosToPhotosContainer = (photos: Photo[]): FeaturesContainer => {
  const features = photos.map((photo) => photoToFeature(photo));
  return {
    featuresDict: Map(
      features.map((feature) => [feature.properties.id, feature])
    ),
    geojson: {
      type: "FeatureCollection",
      features
    }
  };
};

const applyUpdates = (
  photos: FeaturesContainer,
  updates: RealtimeUpdate[]
): FeaturesContainer => {
  const updatedFeaturesDict = _.reduce(
    updates,
    (featuresDict, update) => {
      var updated;
      switch (update.type) {
        case "added":
        case "modified":
          updated = featuresDict.set(
            update.photo.id,
            photoToFeature(update.photo)
          );
          break;
        case "removed":
          updated = featuresDict.remove(update.photo.id);
          break;
      }
      return updated;
    },
    photos.featuresDict
  );
  return {
    featuresDict: updatedFeaturesDict,
    geojson: {
      type: "FeatureCollection",
      features: Array.from(updatedFeaturesDict.values())
    }
  };
};

export type PhotosProviderData = {
  features: FeaturesContainer;
  reload: () => Promise<void>;
};

const EMPTY_PHOTOS_CONTEXT: PhotosProviderData = {
  features: EMPTY_FEATURES_CONTAINER,
  reload: async () => {}
};

export const PhotosContext =
  React.createContext<PhotosProviderData>(EMPTY_PHOTOS_CONTEXT);

type Props = {
  children?: React.ReactChild[];
};

export const PhotosProvider = ({ children }: Props) => {
  const fetchNewPhotos = async () => {
    try {
      const newPhotos = await dbFirebase.fetchPhotos();
      setPhotosAndWriteToCache((current: PhotosProviderData) => ({
        ...current,
        features: merge(current.features, photosToPhotosContainer(newPhotos))
      }));
    } catch (error) {
      console.error(`Error fetching photos from Firestore functions. ${error}`);
    }
  };

  const [data, setData] = useState<PhotosProviderData>({
    features: EMPTY_FEATURES_CONTAINER,
    reload: async () => {
      setData(EMPTY_PHOTOS_CONTEXT);
      await fetchNewPhotos();
    }
  });

  const setPhotosAndWriteToCache = (
    updateHandler: (photos: PhotosProviderData) => PhotosProviderData
  ) => {
    setData((current: PhotosProviderData) => {
      const updated = updateHandler(current);
      localforage
        .setItem(CACHE_KEY, {
          timestamp: new Date().getTime(),
          geojson: updated.features.geojson
        })
        .catch((e) => {
          console.log("Failed to cache geojson");
        });
      return updated;
    });
  };

  const { user } = useUser();
  useAsyncEffect(async () => {
    // set up realtime subscription to our own photos
    // use the local one if we have them: faster boot.
    try {
      const cached = await localforage.getItem(CACHE_KEY);
      if (
        isCacheEnabled() &&
        isCachedGeojson(cached) &&
        // if the cache is from < 30 days ago, use it
        new Date().getTime() - cached.timestamp < 30 * 86400 * 1000
      ) {
        setData((current: PhotosProviderData) => ({
          ...current,
          features: merge(
            current.features,
            cachedGeojsonToPhotosContainer(cached.geojson)
          )
        }));
      } else {
        await fetchNewPhotos();
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (user === undefined || !user) {
      return;
    }

    const updates = new Rx.Subject<RealtimeUpdate>();
    const unsubscribe = dbFirebase.ownPhotosRT(user.id, (update) =>
      updates.next(update)
    );

    // buffer updates to collapse 1000ms of realtime updates so that we don't
    // repeatedly refresh the photos list (its very large)
    updates
      .pipe(
        bufferTime(1000),
        filter((x) => x.length > 0)
      )
      .subscribe((updates) => {
        setPhotosAndWriteToCache((current: PhotosProviderData) => ({
          ...current,
          features: applyUpdates(current.features, updates)
        }));
      });

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    if (user === undefined || !user) {
      return;
    }

    const updates = new Rx.Subject<RealtimeUpdate>();
    const unsubscribe = dbFirebase.ownReadingsPhotosRT(
      user.id,
      NITRATE_READING_TYPE,
      (update) => updates.next(update)
    );

    updates
      .pipe(
        bufferTime(1000),
        filter((x) => x.length > 0)
      )
      .subscribe((updates) => {
        setPhotosAndWriteToCache((current: PhotosProviderData) => ({
          ...current,
          features: applyUpdates(current.features, updates)
        }));
      });

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    if (user === undefined || !user) {
      return;
    }

    const updates = new Rx.Subject<RealtimeUpdate>();
    const unsubscribe = dbFirebase.ownReadingsPhotosRT(
      user.id,
      PHOSPHATE_READING_TYPE,
      (update) => updates.next(update)
    );

    updates
      .pipe(
        bufferTime(1000),
        filter((x) => x.length > 0)
      )
      .subscribe((updates) => {
        setPhotosAndWriteToCache((current: PhotosProviderData) => ({
          ...current,
          features: applyUpdates(current.features, updates)
        }));
      });

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    if (user === undefined || !user) {
      return;
    }

    const updates = new Rx.Subject<RealtimeUpdate>();
    const unsubscribe = dbFirebase.ownReadingsPhotosRT(
      user.id,
      PH_READING_TYPE,
      (update) => updates.next(update)
    );

    updates
      .pipe(
        bufferTime(1000),
        filter((x) => x.length > 0)
      )
      .subscribe((updates) => {
        setPhotosAndWriteToCache((current: PhotosProviderData) => ({
          ...current,
          features: applyUpdates(current.features, updates)
        }));
      });

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    if (user === undefined || !user) {
      return;
    }

    const updates = new Rx.Subject<RealtimeUpdate>();
    const unsubscribe = dbFirebase.ownReadingsPhotosRT(
      user.id,
      TEMPERATURE_READING_TYPE,
      (update) => updates.next(update)
    );

    updates
      .pipe(
        bufferTime(1000),
        filter((x) => x.length > 0)
      )
      .subscribe((updates) => {
        setPhotosAndWriteToCache((current: PhotosProviderData) => ({
          ...current,
          features: applyUpdates(current.features, updates)
        }));
      });

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    if (!user || user === undefined) {
      return;
    }

    const updates = new Rx.Subject<RealtimeUpdate>();
    const unsubscribe = dbFirebase.ownReadingsPhotosRT(
      user.id,
      COLIFORMS_READING_TYPE,
      (update) => updates.next(update)
    );

    updates
      .pipe(
        bufferTime(1000),
        filter((x) => x.length > 0)
      )
      .subscribe((updates) => {
        setPhotosAndWriteToCache((current: PhotosProviderData) => ({
          ...current,
          features: applyUpdates(current.features, updates)
        }));
      });

    return unsubscribe;
  }, [user]);

  return (
    <PhotosContext.Provider value={data}>{children}</PhotosContext.Provider>
  );
};

export const usePhotos = () => useContext(PhotosContext);
