import { ChangeEvent, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import styles from "standard.module.scss";

import { getLocalDateTimeString } from "utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  heading: {
    marginBottom: `${theme.spacing(2)}px`
  },
  dateInput: {
    marginBottom: `${theme.spacing(2)}px`,
    border: "none",
    borderRadius: "5px",
    padding: "5px",
    background: styles.lightGrey,
    fontSize: 16,
    boxSizing: "border-box",
    textOverflow: "ellipsis",
    "&:focus": {
      outline: "none"
    }
  },
  button: {
    width: "100%"
  }
}));

type Props = {
  currentDateTime: number;
  onSubmit: (dateTime: Date) => void;
};

const IncidentDateTimeEntry = ({ currentDateTime, onSubmit }: Props) => {
  const classes = useStyles();

  const [date, setDate] = useState<Date>(new Date(currentDateTime));

  const updateStartDate = (e: ChangeEvent<HTMLInputElement>) => {
    let date = new Date(e.currentTarget.value);
    setDate(date);
  };

  return (
    <>
      <div className={classes.heading}>
        Add the time and date the incident photo was taken:
      </div>
      <input
        className={classes.dateInput}
        type="datetime-local"
        value={getLocalDateTimeString(date)}
        onChange={updateStartDate}
      />
      <Button
        className={classes.button}
        onClick={() => onSubmit(date)}
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

export default IncidentDateTimeEntry;
