import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";

import _ from "lodash";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import PageWrapper from "../../components/PageWrapper";

import { linkToRecordReading } from "../../routes/record/links";

import {
  ALL_READING_TYPES,
  WHAT_LIES_BENEATH_WEB_LINK,
  getFullReadingName
} from "../../types/Readings/Shared";
import User from "../../types/User";
import Geojson from "types/Geojson";
import { MapReadingData } from "types/Readings/Base";

import { removeUserNotification } from "features/firebase/userNotifications";
import { getDaysUntilNextNotification } from "utils/notifications";
import {
  getCurrentReadingsStreak,
  getMaximumReadingsStreak
} from "utils/waterReadingAnalytics";
import { linkToMap } from "custom/config";

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: `${theme.spacing(2)}px`
  },
  divider: {
    margin: theme.spacing(1)
  },
  gridItem: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1)
  },
  gridItemHighlight: {
    fontSize: "1.1rem",
    fontWeight: 700,
    color: theme.palette.primary.main
  },
  header: {
    fontWeight: "bold"
  },
  textBody: {
    marginBottom: `${theme.spacing(2)}px`
  },
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: theme.spacing(2),
    height: "100%"
  }
}));

type Props = {
  user: User;
  geojson: Geojson;
  setUser: (user: User) => void;
};

const WaterTestResultsHome = (props: Props) => {
  const { geojson, user, setUser } = props;
  const classes = useStyles();
  const history = useHistory();
  const handleClose = () => history.push(linkToMap());

  const goToUpload = (readingType: string) => {
    history.push(linkToRecordReading(readingType));
  };

  const userReadingPhotos =
    geojson &&
    _.reverse(
      _.sortBy(
        geojson.features.filter(
          (f) => (f.properties as MapReadingData).userId === user?.id
        ),
        ["properties.dateTime"]
      )
    );

  const handleCancelReminders = async () => {
    const notifications = await removeUserNotification(
      user?.id,
      "Water quality reading reminder"
    );
    setUser({
      ...user,
      notifications: notifications
    });
  };

  const waterNotification = user?.notifications?.find(
    (n) => n.title === "Water quality reading reminder"
  );

  let daysUntilNextTest = 0;
  let currentStreak = 0;
  let bestStreak = 0;
  if (waterNotification) {
    daysUntilNextTest = getDaysUntilNextNotification(waterNotification);
    currentStreak = getCurrentReadingsStreak(userReadingPhotos);
    bestStreak = getMaximumReadingsStreak(userReadingPhotos);
  }

  return (
    <PageWrapper
      label={"Water quality testing"}
      navigationHandler={{ handleClose: handleClose }}
    >
      <div className={classes.wrapper}>
        {waterNotification && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className={classes.gridItem}>
                  Current streak
                  <br />
                  <span className={classes.gridItemHighlight}>
                    {currentStreak}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.gridItem}>
                  Best streak
                  <br />
                  <span className={classes.gridItemHighlight}>
                    {bestStreak}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.gridItem}>
                  Next test in
                  <br />
                  <span className={classes.gridItemHighlight}>
                    {`${daysUntilNextTest} days`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={handleCancelReminders}
              disableElevation
            >
              Cancel test reminder
            </Button>
            <Divider className={classes.divider} />
          </>
        )}
        <div className={classes.header}>Choose a reading type to begin</div>
        <br />
        {ALL_READING_TYPES.map((readingType: string) => (
          <Button
            variant="contained"
            size="large"
            disableElevation
            key={`${readingType}UploadButton`}
            className={classes.button}
            color="primary"
            onClick={() => goToUpload(readingType)}
          >
            {getFullReadingName(readingType)}
          </Button>
        ))}
        <Divider className={classes.divider} />
        <div className={classes.textBody}>
          Order your test kit to help us measure water quality in a river,
          stream, lake or canal near you.
        </div>
        <Button
          variant="contained"
          size="large"
          disableElevation
          className={classes.button}
          color="primary"
          onClick={() => window.open(WHAT_LIES_BENEATH_WEB_LINK)}
        >
          Request test kit
        </Button>
      </div>
    </PageWrapper>
  );
};

export default WaterTestResultsHome;
