import { useState } from "react";
import { useHistory } from "react-router";

import { Link } from "react-router-dom";

import { Capacitor } from "@capacitor/core";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/core/styles";

import PageWrapper from "components/PageWrapper";

import useLocationOnMount from "hooks/useLocationOnMount";

import { linkToRegister } from "routes/register/links";
import { linkToResetPassword } from "routes/reset-password/links";

import {
  signInMobileProvider,
  signInMobileEmail
} from "../../features/firebase/authFirebase";

import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLoginButton } from "react-social-login-buttons";
import { AppleLoginButton } from "react-social-login-buttons";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing()
  },
  center: {
    textAlign: "center"
  },
  formControl: {
    marginTop: theme.spacing(2)
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  margin: {
    margin: theme.spacing()
  },
  padding: {
    padding: theme.spacing()
  },
  page: {
    margin: theme.spacing(2)
  },
  socialButton: {
    boxShadow: "none !important",
    border: "1px solid #dcdcdc !important"
  }
}));

function useGetRedirectOnSuccess() {
  const location = useLocationOnMount<{ redirectToOnSuccess?: string }>();
  const locationState = location.state;
  const redirectToOnSuccess =
    locationState && locationState.redirectToOnSuccess;
  const history = useHistory();

  if (redirectToOnSuccess) {
    return () => history.push(redirectToOnSuccess);
  }

  return false;
}

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [formError, setFormError] = useState("");

  const history = useHistory();
  const handleClose = () => history.push("/");
  const redirectOnSuccess = useGetRedirectOnSuccess();

  const classes = useStyles();

  const validEmail = (value = email) => {
    const valid = value && value.length > 0;
    if (valid) {
      setEmailError("");
    } else {
      setEmailError("Email is required");
    }
    setEmailValid(valid === true);
    return valid;
  };

  const validPassword = (value = password) => {
    const valid = value && value.length > 5;
    if (valid) {
      setPasswordError("");
    } else {
      setPasswordError("Password should be at least 6 characters");
    }
    setPasswordValid(valid === true);
    return valid;
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    validEmail(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    validPassword(event.target.value);
  };

  const handleEmailLoginClick = async () => {
    setFormError("");

    const emailValid = validEmail();
    const passwordValid = validPassword();

    setEmailValid(emailValid === true);
    setPasswordValid(passwordValid === true);

    if (!emailValid || !passwordValid) return;

    const user = await signInMobileEmail(email, password);

    if (user) {
      handleClose();
      if (redirectOnSuccess) {
        redirectOnSuccess();
      }
    } else {
      setFormError("Unable to login. Please check email and password");
    }
  };

  const handleFacebookLoginClick = async () => {
    await signInMobileProvider("facebook.com");
    handleClose();
  };

  const handleGoogleLoginClick = async () => {
    await signInMobileProvider("google.com");
    handleClose();
  };

  const handleAppleLoginClick = async () => {
    await signInMobileProvider("apple.com");
    handleClose();
  };

  const handleRegisterClick = () => {
    history.push(linkToRegister());
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <PageWrapper
      label="Sign in "
      navigationHandler={{ handleClose: handleClose }}
      fillIPhoneScreen={true}
    >
      <div className={classes.page}>
        <FormControl
          required={true}
          error={false}
          component="fieldset"
          className={classes.formControl}
          fullWidth
        >
          <FormLabel component="legend" className={classes.legend}>
            Email
          </FormLabel>
          <FormGroup>
            <TextField
              fullWidth
              label={"Email"}
              variant="outlined"
              value={email}
              onChange={handleChangeEmail}
              error={!emailValid}
              helperText={emailError}
            />
          </FormGroup>
          <FormHelperText></FormHelperText>
        </FormControl>
        <FormControl
          required={true}
          error={false}
          component="fieldset"
          className={classes.formControl}
          fullWidth
        >
          <FormLabel component="legend" className={classes.legend}>
            Password
          </FormLabel>
          <FormGroup>
            <TextField
              fullWidth
              label={"Password"}
              variant="outlined"
              value={password}
              onChange={handleChangePassword}
              type={showPassword ? "text" : "password"}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormGroup>
          <FormHelperText error>{formError}</FormHelperText>
        </FormControl>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.padding}
        >
          <Link to={linkToResetPassword()}>
            Trouble signing in? Reset password
          </Link>
        </Typography>
        <Button
          size="large"
          variant="contained"
          className={classes.button}
          disableElevation
          fullWidth
          onClick={handleEmailLoginClick}
          color="primary"
          disabled={!emailValid || !passwordValid}
        >
          Sign in
        </Button>
        <Divider className={classes.margin} />
        <FacebookLoginButton
          onClick={handleFacebookLoginClick}
          className={classes.socialButton}
        >
          Sign in with Facebook
        </FacebookLoginButton>
        <GoogleLoginButton
          onClick={handleGoogleLoginClick}
          className={classes.socialButton}
        >
          Sign in with Google
        </GoogleLoginButton>
        {Capacitor.getPlatform() === "ios" && (
          <AppleLoginButton
            onClick={handleAppleLoginClick}
            className={classes.socialButton}
          >
            Sign in with Apple
          </AppleLoginButton>
        )}
        <Typography className={classes.center} component="h1" variant="h5">
          or
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          className={classes.button}
          disableElevation
          fullWidth
          onClick={handleRegisterClick}
        >
          Register
        </Button>
      </div>
    </PageWrapper>
  );
};

export default LoginPage;
