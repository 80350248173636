import { useEffect, useRef, useState } from "react";
import { fetchTesters, addTester } from "features/firebase/betaTesters";
import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { TesterEmailCheck } from "types/User";
import {
  getEmailsFromUserIds,
  getUserIdFromEmail
} from "../../../features/firebase/betaTesters";

const isValidEmail = (email: string): boolean => {
  return email.includes("@");
};

export default function BetaControlPage() {
  const [idInput, setIdInput] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>("");
  const [testers, setTesters] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const lastTestersArray = useRef<string[]>();

  const fetchBetaUsers = async () => {
    const testers = await fetchTesters();
    const emails = await getEmailsFromUserIds({
      userIds: testers.map((user) => user.id)
    });
    lastTestersArray.current = emails.emails;
    setTesters(lastTestersArray.current);
  };

  const submitId = async () => {
    if (!idInput.length) {
      alert("Please enter user UID!");
    } else {
      await addTester(idInput);
      fetchBetaUsers();
    }
  };

  const submitEmail = async () => {
    if (!emailInput.length || !isValidEmail(emailInput)) {
      alert("Please enter a valid user email");
      setError("Please enter a valid user email");
      return;
    }

    const checkInfo: TesterEmailCheck = await getUserIdFromEmail(emailInput);
    if (!checkInfo.emailFound) {
      setError(checkInfo.retrieveError);
      return;
    }

    await addTester(checkInfo.userId);
    await fetchBetaUsers();

    setEmailInput("");
  };

  useEffect(() => {
    if (testers !== lastTestersArray.current) {
      fetchBetaUsers();
    }
  }, [testers]);

  return (
    <>
      <Box p={2}>
        <h3>Please enter user email:</h3>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8} md={4}>
            <input
              style={{ width: "100%" }}
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} md={8}>
            <Button color="primary" variant="contained" onClick={submitEmail}>
              Add
            </Button>
          </Grid>
        </Grid>
        {error !== "" && <div>{error}</div>}
      </Box>
      <Box p={2}>
        <h3>or enter user UID:</h3>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8} md={4}>
            <input
              style={{ width: "100%" }}
              value={idInput}
              onChange={(e) => setIdInput(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} md={8}>
            <Button color="primary" variant="contained" onClick={submitId}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <h2>Beta Testers</h2>
        <Table size="small" aria-label="beta tester table">
          <TableHead>
            <TableRow>
              <TableCell>Emails</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testers.map((tester, index) => (
              <TableRow key={index}>
                <TableCell>{tester}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
