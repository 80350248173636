import "features/firebase/firebaseInit.js";

import { useEffect } from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import { createTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";

import config from "./custom/config";
import { isIphoneAndCordova } from "./utils";
import { gtagInit } from "./gtag.js";

import { useConfig } from "./providers/ConfigProvider";
import { FeaturesProvider, useFeatures } from "./providers/FeaturesProvider";
import { IncidentsProvider } from "./providers/IncidentsProvider";
import LocationProvider, { useGPSLocation } from "./providers/LocationProvider";
import { MissionsProvider } from "./providers/MissionsProvider";
import { useOnline } from "./providers/OnlineProvider";
import { PhotosProvider } from "./providers/PhotosProvider";
import { ReadingsProvider } from "./providers/ReadingsProvider";
import SelectedFeatureProvider, {
  useSelectedFeature
} from "./providers/SelectedFeatureProvider";
import StatsProvider from "./providers/StatsProvider";
import { SurveysProvider } from "./providers/SurveysProvider";
import UserProvider, { useUser } from "./providers/UserProvider";

import { dbFirebase } from "features/firebase";

import i18n from "./custom/i18n";

import { Device } from "@capacitor/device";

import firebase from "firebase/compat/app";

import * as serviceWorker from "./serviceWorker";

import App from "./App";

import "./index.scss";

serviceWorker.register();

function initialiseCypressVars () {
  if (window.Cypress) {
    window.__firebase__ = firebase.app();
  }
}

initialiseCypressVars();

if (isIphoneAndCordova) {
  window?.StatusBar?.styleDefault();
}

if (
  process.env.NODE_ENV !== "development" &&
  localStorage.getItem("debug") !== "true"
) {
  console.log =
    console.info =
    console.trace =
    console.warn =
    console.error =
    console.debug =
      _ => {};
}
// it must set to fals (not enough to be absent)
const devDissableDebugLog = localStorage.getItem("debug") === "false";
if (devDissableDebugLog) {
  console.debug = _ => {};
}

const theme = createTheme(config.THEME);

const Wrapper = () => {
  const gpsLocation = useGPSLocation();
  const features = useFeatures();
  const online = useOnline();
  const selectedFeature = useSelectedFeature();
  const { sponsorImage } = useConfig();
  const { user, setUser } = useUser();

  useEffect(() => {
    Device.getLanguageCode().then(langCode =>
      i18n.changeLanguage(langCode.value)
    );

    return () => dbFirebase.disconnect();
  }, []);

  return (
    <App
      config={config}
      gpsLocation={gpsLocation}
      geojson={features.features.geojson}
      reloadFeatures={features.reload}
      online={online}
      sponsorImage={sponsorImage}
      selectedFeature={selectedFeature}
      user={user}
      setUser={setUser}
    />
  );
};

const startApp = async () => {
  await gtagInit();
  ReactDOM.render(
    <Router>
      <MuiThemeProvider theme={theme}>
        <LocationProvider>
          <SelectedFeatureProvider>
            <StatsProvider>
              <MissionsProvider>
                <UserProvider>
                  <PhotosProvider>
                    <ReadingsProvider>
                      <IncidentsProvider>
                        <FeaturesProvider>
                          <I18nextProvider i18n={i18n}>
                            <SurveysProvider>
                              <Wrapper />
                            </SurveysProvider>
                          </I18nextProvider>
                        </FeaturesProvider>
                      </IncidentsProvider>
                    </ReadingsProvider>
                  </PhotosProvider>
                </UserProvider>
              </MissionsProvider>
            </StatsProvider>
          </SelectedFeatureProvider>
        </LocationProvider>
      </MuiThemeProvider>
    </Router>,
    document.getElementById("root")
  );
};

startApp();
