import firebase from "firebase/compat/app";

import User from "types/User";

import dbFirebase from "./dbFirebase";

import { addGravatarInfo } from "utils/gravatar";

import { enableOrDisableFeatures } from "custom/featuresFlags";
import config from "custom/config";

import { gtagEvent, gtagSetId } from "gtag.js";

import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

import {
  getAuth,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
  fetchSignInMethodsForEmail
} from "firebase/auth";

type Args = {
  onSignOut: () => void;
  setUser: (user?: User | undefined | null) => void;
};

export const onAuthStateChanged = ({ onSignOut, setUser }: Args) => {
  let userRef: any;

  const firebaseStatusChange = async (user: firebase.User | null) => {
    if (!user) {
      // if the user is signed in, then sign out
      if (userRef) {
        userRef = undefined;
        onSignOut();
        setUser(null);
      }
      return;
    }

    gtagSetId(user?.uid);
    gtagEvent("Logged in", "User" as any, user?.uid as any);

    const displayName = localStorage.getItem("displayName"); // apple login the first time missing names workaround
    const idTokenResult = await user.getIdTokenResult();

    let currentUser = new User(
      user.uid,
      displayName ? displayName : user.displayName || "",
      false,
      false,
      false,
      user.email || "",
      user.isAnonymous,
      user.phoneNumber || "",
      user.photoURL || "",
      "",
      null,
      "",
      idTokenResult.signInProvider,
      [],
      [],
      []
    );

    config.ENABLE_GRAVATAR_PROFILES && addGravatarInfo(currentUser);

    setUser(currentUser);
    userRef = currentUser;

    try {
      const userFirebaseData = await dbFirebase.getUser(user.uid);
      currentUser = {
        ...currentUser,
        ...userFirebaseData
      };

      enableOrDisableFeatures(currentUser);
    } catch {}

    // creates a new object ref so react updates
    console.log(
      `calling setUser inside firebase status change for ${user.uid}`
    );
    setUser({ ...currentUser });
  };
  return firebase.auth().onAuthStateChanged(firebaseStatusChange);
};

export const signOut = async () => {
  // 1. Sign out on the native layer
  await FirebaseAuthentication.signOut();
  // 1. Sign out on the web layer
  await firebase.auth().signOut();
};

export const sendEmailVerification = () => {
  return firebase
    .auth()
    .currentUser?.sendEmailVerification()
    .then(() => {
      const message = {
        title: "Notification",
        body:
          "A verification link has been sent to email account: " +
          firebase.auth().currentUser?.email
      };
      return message;
    })
    .catch((error) => {
      return {
        title: "Warning",
        body: error.message
      };
    });
};

export const checkUserExists = async (email: string) => {
  try {
    const result = await fetchSignInMethodsForEmail(getAuth(), email);
    return result.length > 0;
  } catch (error) {
    return null;
  }
};

export const registerEmailPassword = async (
  email: string,
  password: string,
  displayName: string,
  setUser: any
) => {
  try {
    const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
      email: email,
      password: password
    });
    const credential = EmailAuthProvider.credential(email, password);
    const auth = getAuth();
    await signInWithCredential(auth, credential);
    await FirebaseAuthentication.updateProfile({
      displayName: displayName
    });
    await setUser((prevState: any) => ({
      ...prevState,
      displayName: displayName
    }));
    return result.user;
  } catch (error) {
    setUser(null);
    return null;
  }
};

export const resetPassword = async (email: string) => {
  try {
    await FirebaseAuthentication.sendPasswordResetEmail({
      email: email
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const signInMobileEmail = async (email: string, password: string) => {
  try {
    const result = await FirebaseAuthentication.signInWithEmailAndPassword({
      email: email,
      password: password
    });
    const credential = EmailAuthProvider.credential(email, password);
    const auth = getAuth();
    await signInWithCredential(auth, credential);
    return result.user;
  } catch (error) {
    return null;
  }
};

export const signInMobileProvider = async (provider: string) => {
  let credential = null;

  var result = null;

  try {
    switch (provider) {
      case "apple.com":
        result = await FirebaseAuthentication.signInWithApple({
          skipNativeAuth: true
        });

        const displayName = result.user?.displayName;
        if (displayName) localStorage.setItem("displayName", displayName);

        const provider = new OAuthProvider("apple.com");
        credential = provider.credential({
          idToken: result.credential?.idToken,
          rawNonce: result.credential?.nonce
        });

        break;
      case "facebook.com":
        result = await FirebaseAuthentication.signInWithFacebook({
          skipNativeAuth: true
        });
        credential = FacebookAuthProvider.credential(
          result.credential?.accessToken as string
        );
        break;
      case "google.com":
        result = await FirebaseAuthentication.signInWithGoogle();
        credential = GoogleAuthProvider.credential(result.credential?.idToken);
        break;
      default:
        break;
    }
  } catch (error) {
    return null;
  }

  const auth = getAuth();
  await signInWithCredential(auth as any, credential as any);

  return result?.user;
};
