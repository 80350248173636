import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import _ from "lodash";

import {
  ConfigurableMissionData,
  MissionFirestoreData,
  missionHasEnded,
  PendingUser,
  userCollectedPiecesForMission,
  userOnMissionLeaderboard
} from "../../types/Missions";
import Photo, { ImageMetaData, photoIsMetaData } from "../../types/Photo";
import User from "../../types/User";
import {
  MISSION_FIRESTORE_COLLECTION,
  MISSION_PHOTO_STORAGE_BUCKET,
  PHOTO_UPLOAD_NAME
} from "../../shared/utils";

export const getMissionCoverPhotoUrl = async (
  missionId: string
): Promise<string | undefined> => {
  const storageRef = firebase
    .storage()
    .ref(`${MISSION_PHOTO_STORAGE_BUCKET}/${missionId}/${PHOTO_UPLOAD_NAME}`);

  let coverPhotoUrl;
  try {
    coverPhotoUrl = await storageRef.getDownloadURL();
  } catch (err: any) {
    if (err.code === "storage/object-not-found") {
      console.log(
        `Failed to download mission ${missionId} cover photo. User probably didn't upload, we display a default.`
      );
    } else {
      console.error(
        `Failed to download mission ${missionId} cover photo for unexpected reason.`
      );
      console.error(err);
    }
    return undefined;
  }

  return coverPhotoUrl;
};

const getMissionRefFromId = (
  firestore: firebase.firestore.Firestore,
  missionId: string
) => {
  return firestore.collection(MISSION_FIRESTORE_COLLECTION).doc(missionId);
};

export const createMission = async (
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  ownerUserId: string,
  mission: ConfigurableMissionData
): Promise<string> => {
  // We get rid of coverPhoto from the Firestore data because
  // the file is kept in storage instead.
  const missionToPersist: Omit<MissionFirestoreData, "id"> = {
    ..._.omit(mission, "coverPhoto"),
    ownerUserId,
    totalPieces: 0,
    totalUserPieces: {},
    pendingUsers: [],
    hidden: false
  };

  const { id } = await firestore
    .collection(MISSION_FIRESTORE_COLLECTION)
    .add(missionToPersist);

  if (mission.coverPhoto !== undefined && photoIsMetaData(mission.coverPhoto)) {
    await uploadMissionCoverPhoto(storage, id, mission.coverPhoto);
  }

  return id;
};

export const fetchAllMissions = async (
  firestore: firebase.firestore.Firestore
): Promise<MissionFirestoreData[]> => {
  const snapshot = await firestore
    .collection(MISSION_FIRESTORE_COLLECTION)
    .where("hidden", "==", false)
    .get();

  if (snapshot.empty) {
    return [];
  }

  const missions = snapshot.docs
    .filter((doc) => {
      // Filter out missions that have ended over 6 months ago
      const mission = doc.data() as MissionFirestoreData;
      return (
        !missionHasEnded(mission.endTime) ||
        mission.endTime > Date.now() - 15778476000
      );
    })
    .map((doc) => ({
      ...doc.data(),
      id: doc.id
    })) as MissionFirestoreData[];

  return missions.map(defaultPrecedence);
};

// Edit mission with pending user
export const joinMission = async (
  firestore: firebase.firestore.Firestore,
  missionId: string,
  userId: string,
  userDisplayName: string
) => {
  const mission = await getMissionIfExists(firestore, missionId);
  if (missionHasEnded(mission.endTime)) {
    throw new Error(
      `User ${userId} not allowed to join ended mission ${mission.id}`
    );
  }

  await addUserToMission(firestore, mission, userId, userDisplayName);
};

export const addToPendingMissionUsers = async (
  firestore: firebase.firestore.Firestore,
  missionId: string,
  user: User
) => {
  const missionRef = getMissionRefFromId(firestore, missionId);
  await missionRef.set(
    {
      pendingUsers: [
        {
          uid: user.id,
          displayName: user.displayName,
          email: user.email
        }
      ]
    },
    { merge: true }
  );
};

export const addUserToMission = async (
  firestore: firebase.firestore.Firestore,
  mission: MissionFirestoreData,
  userId: string,
  userDisplayName: string
) => {
  // If the user left and rejoined the mission, they'll already have a piece count,
  // so we don't need to add a new one.
  if (!userOnMissionLeaderboard(mission, userId)) {
    const missionRef = getMissionRefFromId(firestore, mission.id);
    await missionRef.set(
      {
        totalUserPieces: {
          [userId]: {
            uid: userId,
            displayName: userDisplayName,
            pieces: 0
          }
        }
      },
      { merge: true }
    );
  }

  await addMissionToUser(firestore, userId, mission.id);
};

// Edit user to remove missionId
export const leaveMission = async (
  firestore: firebase.firestore.Firestore,
  missionId: string,
  userId: string
) => {
  const mission = await getMissionIfExists(firestore, missionId);

  if (missionHasEnded(mission.endTime)) {
    console.log(`User not leaving mission because the mission had ended.`);
    return;
  }

  // We remove the mission from the user data list of  missions.
  // This prevents their future uploads contributing.
  const userDocRef = await firestore.collection("users").doc(userId);

  if (!(await userDocRef.get()).exists) {
    console.warn(
      `Failed to remove mission ${missionId} from user ${userId}. User Firebase entry didn't exist.`
    );
    return;
  }

  await userDocRef.update({
    missions: firebase.firestore.FieldValue.arrayRemove(missionId)
  });

  // Unless the user upload count for this mission is still 0, we still leave
  // their piece uploads in the mission so the total makes sense.
  if (!userCollectedPiecesForMission(mission, userId)) {
    const missionRef = getMissionRefFromId(firestore, missionId);
    await missionRef.update({
      [`totalUserPieces.${userId}`]: firebase.firestore.FieldValue.delete()
    });
  }
};

// Edit mission to remove user from pending users and add user count (if not present).
// Edit user to add missionId.
export const approveNewMember = async (
  firestore: firebase.firestore.Firestore,
  missionId: string,
  user: PendingUser
) => {
  const missionRef = getMissionRefFromId(firestore, missionId);
  const currentMissionSnapshot = await missionRef.get();
  const missionData = currentMissionSnapshot.data() as MissionFirestoreData;

  console.log(`Accepting pending member ${user.uid} for mission ${missionId}`);

  const newPendingUsers = missionData.pendingUsers.filter(
    (pendingUser) => pendingUser.uid !== user.uid
  );

  await missionRef.set(
    {
      pendingUsers: newPendingUsers,
      totalUserPieces: {
        [user.uid]: {
          uid: user.uid,
          displayName: user.displayName,
          pieces: 0
        }
      }
    },
    { merge: true }
  );

  await addMissionToUser(firestore, user.uid, missionId);
};

// Edit mission to remove user from pending users.
export const rejectNewMember = async (
  firestore: firebase.firestore.Firestore,
  uid: string,
  missionId: string
) => {
  const missionRef = getMissionRefFromId(firestore, missionId);
  const currentMissionSnapshot = await missionRef.get();
  const missionData = currentMissionSnapshot.data() as MissionFirestoreData;

  console.log(`Rejecting pending member ${uid} for mission ${missionId}`);

  const newPendingUsers = missionData.pendingUsers.filter(
    (pendingUser) => pendingUser.uid !== uid
  );

  await missionRef.set(
    {
      pendingUsers: newPendingUsers
    },
    { merge: true }
  );
};

// Edit mission configurable data
export const editMission = async (
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  missionId: string,
  mission: ConfigurableMissionData
) => {
  const missionRef = getMissionRefFromId(firestore, missionId);

  console.log(`Editing mission ${missionId}`);
  console.log(mission);

  await missionRef.set(
    {
      ..._.omit(mission, "coverPhoto")
    },
    { merge: true }
  );

  if (
    mission.coverPhoto === undefined ||
    !photoIsMetaData(mission.coverPhoto)
  ) {
    return;
  }

  await uploadMissionCoverPhoto(storage, missionId, mission.coverPhoto);
};

export const setMissionPrecedence = async (
  firestore: firebase.firestore.Firestore,
  missionId: string,
  precedence: number
) => {
  const missionRef = getMissionRefFromId(firestore, missionId);

  return await missionRef.set({ precedence }, { merge: true });
};

const uploadMissionCoverPhoto = async (
  storage: firebase.storage.Storage,
  missionId: string,
  coverPhoto: ImageMetaData
) => {
  console.log(`Uploading cover photo for mission ${missionId}`);
  try {
    const coverPhotoStorageRef = await storage
      .ref()
      .child(MISSION_PHOTO_STORAGE_BUCKET)
      .child(missionId)
      .child(PHOTO_UPLOAD_NAME);

    const base64Image = coverPhoto.imgSrc.split(",")[1];
    await coverPhotoStorageRef.putString(base64Image, "base64", {
      contentType: "image/jpeg"
    });
  } catch (err) {
    console.error(`Failed to upload mission cover photo: ${err}`);
  }
};

// Delete mission (maybe just mark as hidden to avoid accidents).
export const deleteMission = async (
  firestore: firebase.firestore.Firestore,
  missionId: string
) => {
  const missionRef = getMissionRefFromId(firestore, missionId);
  try {
    await missionRef.set(
      {
        hidden: true
      },
      { merge: true }
    );
  } catch (err) {
    console.error(`Failed to set mission ${missionId} as hidden. ${err}`);
  }
};

const addMissionToUser = async (
  firestore: firebase.firestore.Firestore,
  userId: string,
  missionId: string
) => {
  const userDoc = await firestore.collection("users").doc(userId);
  const currentUserDoc = await userDoc.get();

  if (!currentUserDoc.exists) {
    await userDoc.set({
      missions: [missionId]
    });
  } else {
    try {
      await userDoc.update({
        missions: firebase.firestore.FieldValue.arrayUnion(missionId)
      });
    } catch (err) {
      console.error(`Failed to add mission ID to user data: ${err}`);
    }
  }
};

const getMissionIfExists = async (
  firestore: firebase.firestore.Firestore,
  missionId: string
): Promise<MissionFirestoreData> => {
  let snapshot;
  try {
    const missionRef = getMissionRefFromId(firestore, missionId);
    snapshot = await missionRef.get();
  } catch (err) {
    throw new Error(`Failed to get mission by mission ID: ${err}`);
  }

  if (!snapshot.exists) {
    throw new Error("No mission exists for id");
  }

  return { ...snapshot.data(), id: missionId } as MissionFirestoreData;
};

export const updateMissionOnPhotoUploaded = async (
  firestore: firebase.firestore.Firestore,
  uploaderId: string,
  pieces: number,
  missionIds: string[],
  userDisplayName: string
) => {
  console.log(
    `User ${uploaderId} uploaded photo with ${pieces} for ${missionIds.length} missions`
  );
  console.log(missionIds);

  await Promise.all(
    missionIds.map(async (missionId: string) => {
      try {
        const mission = await getMissionIfExists(firestore, missionId);

        // If the user is part of a mission, but the mission has ended,
        // their new pieces uploaded should not count towards it.
        // n.b. This means people can't upload late for things like World Cleanup Day
        if (missionHasEnded(mission.endTime)) {
          console.log(
            `Mission ${missionId} wasn't updated with new pieces because it had ended when photo was uploaded.`
          );
          return;
        }

        console.log(
          `Photo with ${pieces} pieces uploaded for mission: ${missionId}.`
        );

        const missionRef = getMissionRefFromId(firestore, missionId);
        await missionRef.update({
          totalPieces: firebase.firestore.FieldValue.increment(pieces),
          [`totalUserPieces.${uploaderId}.pieces`]:
            firebase.firestore.FieldValue.increment(pieces),
          [`totalUserPieces.${uploaderId}.displayName`]: userDisplayName
        });
      } catch (err) {
        console.info(
          `Error updating mission with uploaded photo pieces: ${err}`
        );
      }
    })
  );
};

export const updateMissionOnPhotoModerated = async (
  firestore: firebase.firestore.Firestore,
  photo: Photo,
  photoWasApproved: boolean
) => {
  if (!photo.missions || photo.missions.length === 0 || photo.pieces === 0) {
    console.log("Photo was moderated but no mission updated.");
    return;
  }

  await Promise.all(
    photo.missions.map(async (missionId: string) => {
      try {
        if (!photoWasApproved) {
          console.log(
            `Moderator rejected photo ${photo.id} which was part of mission ${missionId}.`
          );

          // If a photo that was part of a mission is rejected, we decrement:
          //  - the total mission pieces,
          //  - the piece count for the individual user.
          const missionRef = getMissionRefFromId(firestore, missionId);
          await missionRef.update({
            totalPieces: firebase.firestore.FieldValue.increment(-photo.pieces),
            [`totalUserPieces.${photo.owner_id}.pieces`]:
              firebase.firestore.FieldValue.increment(-photo.pieces)
          });
        }
      } catch (err) {
        console.error(err);
      }
    })
  );
};

const defaultPrecedence = (
  mission: MissionFirestoreData
): MissionFirestoreData => {
  if (mission.precedence === undefined) {
    return {
      ...mission,
      precedence: 0
    };
  }

  return mission;
};
