import { useState } from "react";

import { makeStyles } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { Section, QuestionType, Answer } from "types/Survey";

import QuestionCheckbox from "./QuestionCheckbox";
import QuestionImageSelect from "./QuestionImageSelect";
import QuestionLocation from "./QuestionLocation";
import QuestionNumber from "./QuestionNumber";
import QuestionPhoto from "./QuestionPhoto";
import QuestionMultiplePhotos from "./QuestionMultiplePhotos";
import QuestionRadio from "./QuestionRadio";
import QuestionRange from "./QuestionRange";
import QuestionText from "./QuestionText";
import QuestionTimestamp from "./QuestionTimestamp";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    height: "calc(100vh - 300px)",
    overflowY: "scroll",
    maxWidth: "100%"
  },
  description: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    boxShadow: "none"
  }
}));

type Props = {
  section: Section;
  onChangeSectionAnswers: (value: Answer[]) => void;
};

export default function SurveySection({
  section,
  onChangeSectionAnswers
}: Props) {
  const classes = useStyles();

  const generateAnswerArray = () => {
    const answers = section.questions.map((question) => {
      let answer: Answer = {
        questionId: question.id,
        value: undefined,
        valid: question.required ? (question.default ? true : false) : true
      };
      return answer;
    });

    return answers;
  };

  const [questionAnswers, setQuestionAnswers] = useState(generateAnswerArray());

  const onChangeQuestionValue = (
    questionId: string,
    value: any,
    valid: boolean
  ) => {
    let currentAnswers = questionAnswers.map((x) => x);
    const questionIndex = currentAnswers.findIndex(
      (a) => a.questionId === questionId
    );
    currentAnswers[questionIndex].value = value;
    currentAnswers[questionIndex].valid = valid;
    setQuestionAnswers(currentAnswers);
    onChangeSectionAnswers(currentAnswers);
  };

  return (
    <div className={classes.wrapper}>
      {section.title && (
        <Typography variant="h5" color="textPrimary" gutterBottom>
          <span dangerouslySetInnerHTML={{ __html: section.title }} />
        </Typography>
      )}
      {section.subtitle && (
        <Typography variant="subtitle1" color="textPrimary">
          <span dangerouslySetInnerHTML={{ __html: section.subtitle }} />
        </Typography>
      )}
      {section.description && (
        <Paper className={classes.description}>
          <Typography variant="body1" color="textSecondary">
            <span dangerouslySetInnerHTML={{ __html: section.description }} />
          </Typography>
        </Paper>
      )}
      {section.questions.map((question) => {
        switch (question.type) {
          case QuestionType.text:
            return (
              <QuestionText
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.radio:
            return (
              <QuestionRadio
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.number:
            return (
              <QuestionNumber
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.checkbox:
            return (
              <QuestionCheckbox
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.imageselect:
            return (
              <QuestionImageSelect
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.range:
            return (
              <QuestionRange
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.photo:
            return (
              <QuestionPhoto
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.multiplephotos:
            return (
              <QuestionMultiplePhotos
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.location:
            return (
              <QuestionLocation
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          case QuestionType.timestamp:
            return (
              <QuestionTimestamp
                key={`question_${question.id}`}
                question={question}
                onChangeQuestionValue={onChangeQuestionValue}
              />
            );
          default:
            return <div>Unknown question type</div>;
        }
      })}
    </div>
  );
}
