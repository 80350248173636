import { Route, Switch } from "react-router-dom";

import { SurveyPage } from "pages/surveys";

import { linkToSurveyPage } from "./links";

export default function SurveysRoute() {
  return (
    <Switch>
      <Route path={linkToSurveyPage()}>
        <SurveyPage />
      </Route>
    </Switch>
  );
}
