export function linkToRecordPollutionPage() {
  return "/record";
}

export function linkToRecordWaterTestResults() {
  return `${linkToRecordPollutionPage()}/water-test-results`;
}

export function linkToRecordReading(readingType: string = ":readingType") {
  return `${linkToRecordPollutionPage()}/reading/${readingType}`;
}

export function linkToRecordIncident() {
  return `${linkToRecordPollutionPage()}/incident`;
}
