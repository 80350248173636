import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";

import { Question } from "types/Survey";
import useEffectOnMount from "hooks/useEffectOnMount";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(2)
  }
}));

type Props = {
  question: Question;
  onChangeQuestionValue: (
    questionId: string,
    value: string[],
    valid: boolean
  ) => void;
};

export default function QuestionCheckbox({
  question,
  onChangeQuestionValue
}: Props) {
  const classes = useStyles();

  const [value, setValue] = useState((question.default as string[]) || []);
  const [valid, setValid] = useState(
    question.required ? (question.default ? true : false) : true
  );
  const [other, setOther] = useState("");
  const [validationTriggered, setValidationTriggered] = useState(false);

  useEffectOnMount(() => {
    onChangeQuestionValue(question.id, value, valid);
  });

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentValue = Object.assign([], value);
    const option = event.target.value;
    if (currentValue.indexOf(option) === -1) {
      currentValue.push(option);
      if (option === "Other" && other !== "")
        currentValue.push(`Other - ${other}`);
    } else {
      currentValue = currentValue.filter((v) => v !== option);
      if (option === "Other")
        currentValue = currentValue.filter(
          (v: string) => !v.includes("Other - ")
        );
    }

    setValue(currentValue);
    setValidationTriggered(true);
    onChangeQuestionValue(question.id, currentValue, validate(currentValue));
  };

  const handleChangeOther = (event: React.ChangeEvent<HTMLInputElement>) => {
    let otherValue = event.target.value;
    setOther(otherValue);
    let currentValue = Object.assign([], value);
    currentValue = currentValue.filter((v: string) => !v.includes("Other - "));
    if (otherValue !== "") currentValue.push(`Other - ${otherValue}`);
    setValue(currentValue);
    onChangeQuestionValue(question.id, currentValue, valid);
  };

  const validate = (currentValue: string[]) => {
    if (question.required && currentValue.length === 0) {
      setValid(false);
      return false;
    } else {
      setValid(true);
      return true;
    }
  };

  const error = !valid && validationTriggered;

  return (
    <div className={classes.root}>
      <FormControl
        required={question.required}
        error={error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend">{question.name}</FormLabel>
        <FormGroup>
          {question.options.map((option) => (
            <React.Fragment key={`lbl_${option.id}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.indexOf(option.value as string) !== -1}
                    onChange={handleChangeValue}
                    value={option.value}
                    name={option.name}
                  />
                }
                label={option.name}
              />
              {option.value === "Other" &&
              value.indexOf(option.value as string) !== -1 ? (
                <TextField
                  label={"Specify other"}
                  variant="outlined"
                  value={other}
                  onChange={handleChangeOther}
                  onClick={(e) => e.stopPropagation()}
                  onSelect={(e) => e.stopPropagation()}
                />
              ) : null}
            </React.Fragment>
          ))}
        </FormGroup>
        <FormHelperText>
          <span dangerouslySetInnerHTML={{ __html: question.description }} />
        </FormHelperText>
      </FormControl>
    </div>
  );
}
