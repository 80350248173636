import { useState } from "react";

import { makeStyles } from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Slider from "@material-ui/core/Slider";

import { Question } from "types/Survey";
import useEffectOnMount from "hooks/useEffectOnMount";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(2),
    width: "100%"
  },
  slider: {
    margin: theme.spacing(4)
  }
}));

type Props = {
  question: Question;
  onChangeQuestionValue: (
    questionId: string,
    value: number,
    valid: boolean
  ) => void;
};

export default function QuestionRange({
  question,
  onChangeQuestionValue
}: Props) {
  const classes = useStyles();

  const [value, setValue] = useState((question.default as number) || undefined);
  const [valid, setValid] = useState(
    question.required ? (question.default ? true : false) : true
  );
  const [validationTriggered, setValidationTriggered] = useState(false);

  useEffectOnMount(() => {
    onChangeQuestionValue(question.id, value as number, valid);
  });

  const handleChangeValue = (
    event: React.ChangeEvent<{}>,
    changedValue: number | number[]
  ) => {
    setValue(changedValue as number);
    onChangeQuestionValue(
      question.id,
      changedValue as number,
      validate(changedValue as number)
    );
    setValidationTriggered(true);
  };

  const validate = (currentValue: number) => {
    if (question.required && currentValue === null) {
      setValid(false);
      return false;
    } else {
      setValid(true);
      return true;
    }
  };

  const marks = question.options.map((option, idx) => ({
    value: option.value as number,
    label: idx === 0 || idx === question.options.length - 1 ? option.name : ""
  }));

  const error = !valid && validationTriggered;

  return (
    <div className={classes.root}>
      <FormControl
        required={question.required}
        error={error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend">{question.name}</FormLabel>
        <FormGroup className={classes.slider}>
          <Slider
            defaultValue={3}
            step={1}
            marks={marks}
            valueLabelDisplay="on"
            onChange={handleChangeValue}
            value={value}
            min={question.options[0].value as number}
            max={question.options[question.options.length - 1].value as number}
          />
        </FormGroup>
        <FormHelperText>
          <span dangerouslySetInnerHTML={{ __html: question.description }} />
        </FormHelperText>
      </FormControl>
    </div>
  );
}
