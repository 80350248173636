export type GPSLocation = {
  online: boolean;
  updated?: Date;
} & LatLong;

export type LatLong = {
  latitude: number;
  longitude: number;
};

export const INVALID_LAT_LON = {
  latitude: 0,
  longitude: 0
};

export const LONDON_LAT_LON = {
  latitude: 51.499742,
  longitude: -0.124006
};
