import firebase from "firebase/compat/app";

import { LocalNotificationSchema } from "@capacitor/local-notifications";

import { syncNotifications } from "utils/notifications";

const firestore = firebase.firestore();

const usersPath = () => "users";

export const addUserNotification = async (
  userId: string,
  notification: LocalNotificationSchema
) => {
  const userRef = firestore.collection(usersPath()).doc(userId);

  let existingNotifications = ((await userRef.get()).data()?.notifications ||
    []) as LocalNotificationSchema[];

  existingNotifications.push(notification);

  try {
    await userRef.update({
      notifications: existingNotifications
    });
  } catch (e) {}

  await syncNotifications(existingNotifications);

  return existingNotifications;
};

export const removeUserNotification = async (
  userId: string,
  notificationTitle: string
) => {
  const userRef = firestore.collection(usersPath()).doc(userId);

  let existingNotifications = ((await userRef.get()).data()?.notifications ||
    []) as LocalNotificationSchema[];

  existingNotifications = existingNotifications.filter(
    (n) => n.title !== notificationTitle
  );

  try {
    await userRef.update({
      notifications: existingNotifications
    });
  } catch (e) {}

  await syncNotifications(existingNotifications);

  return existingNotifications;
};
