import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PageWrapper from "components/PageWrapper";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {
  approveNewMember,
  rejectNewMember
} from "../../../features/firebase/missions";
import { useMissions } from "../../../providers/MissionsProvider";
import { PendingUser } from "../../../types/Missions";
import firebase from "firebase/compat/app";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: theme.spacing(2)
  },
  memberWrapper: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "20px"
  },
  memberNameWrapper: {
    flex: 1,
    flexGrow: 1,
    overflow: "hidden"
  },
  memberName: {},
  email: {
    fontSize: 10,
    wordWrap: "break-word"
  },
  approveButton: {
    flex: 0,
    marginRight: `${theme.spacing(1)}px`
  },
  rejectButton: {
    flex: 0
  },
  button: {
    textTransform: "none"
  }
}));

export default function ManagePendingMembers() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const missionData = useMissions();
  const missions = missionData?.missions || [];

  const { missionId } = useParams<{ missionId: string }>();
  const mission = missions.find((ch) => ch.id.toString() === missionId);
  if (mission === undefined) {
    const errorMessage = `Trying to manage pending mission members but couldn't find mission ${missionId} data in list.`;
    console.warn(errorMessage);
    return <div>{errorMessage}</div>;
  }

  const handleBack = () => history.goBack();

  return (
    <PageWrapper
      label={t("missions_manage_members")}
      navigationHandler={{ handleBack }}
    >
      <div className={classes.wrapper}>
        {mission.pendingUsers.length === 0 ? (
          <div>{t("missions_private_no_pending_members")}</div>
        ) : (
          mission.pendingUsers.map((pendingUser: PendingUser) => (
            <div className={classes.memberWrapper} key={pendingUser.uid}>
              <div className={classes.memberNameWrapper}>
                <div className={classes.memberName}>
                  {pendingUser.displayName}
                </div>
                <div className={classes.email}>{pendingUser.email}</div>
              </div>
              <div className={classes.approveButton}>
                <Button
                  className={classes.button}
                  onClick={async () => {
                    await approveNewMember(
                      firebase.firestore(),
                      mission.id,
                      pendingUser
                    );
                    await missionData?.refresh();
                  }}
                  color="default"
                  size="small"
                  variant="outlined"
                  disableElevation
                >
                  {t("approve_button_text")}
                  <CheckIcon fontSize={"small"} style={{ color: "green" }} />
                </Button>
              </div>
              <div className={classes.rejectButton}>
                <Button
                  className={classes.button}
                  onClick={async () => {
                    await rejectNewMember(
                      firebase.firestore(),
                      pendingUser.uid,
                      mission.id
                    );
                    await missionData?.refresh();
                  }}
                  color="default"
                  size="small"
                  variant="outlined"
                  disableElevation
                >
                  {t("reject_button_text")}{" "}
                  <CloseIcon fontSize={"small"} style={{ color: "red" }} />
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
    </PageWrapper>
  );
}
