import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";

import { makeStyles } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useTranslation, Trans } from "react-i18next";
import { useEffect } from "react";
import { reassessUserBadges } from "utils/badges";
import { useUser } from "providers/UserProvider";
import { useReadings } from "providers/ReadingsProvider";
import { usePhotos } from "providers/PhotosProvider";
import { updateUserBadges } from "features/firebase/userBadges";

type Props = {
  isInMission: boolean;
  onUploadAnotherPhoto: () => void;
  onGoToMap: () => void;
  onClose: () => void;
  sponsorImage?: string;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: "center",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  button: {
    width: "80%",
    minHeight: "min-content"
  },
  sponsoredBy: {
    width: "100%",
    fontSize: "0.7rem",
    margin: 0,
    marginBottom: theme.spacing(0.5)
  },
  sponsorImage: {
    marginBottom: theme.spacing(2),
    height: "30px",
    width: "auto",
    alignSelf: "center"
  },
  bold: {
    fontWeight: "bold"
  }
}));

export default function UploadSuccessDialog({
  isInMission,
  onUploadAnotherPhoto,
  onGoToMap,
  onClose,
  sponsorImage
}: Props) {
  const styles = useStyles();
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  const waterReadings = useReadings();
  const litterPhotos = usePhotos();

  useEffect(() => {
    // Refresh the user's badges
    if (!user) return;
    const badges =
      reassessUserBadges(user, "litter", litterPhotos, waterReadings) || [];
    updateUserBadges(user.id, badges);
    setUser({
      ...user,
      badges: badges
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open PaperProps={{ className: styles.dialog }}>
      <IconButton
        style={{ position: "absolute", top: "0", right: "0" }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <Trans i18nKey="record_litter_upload_success_text">
        <p>
          <span className={styles.bold}>Thank you.</span>
          <br />
          <br />
          Your photo is now being moderated and will appear on the global map
          within 48 hours 🙌
          {isInMission && (
            <>
              <br />
              <br />
              The pieces you've uploaded have been added to the Mission(s) you
              are part of 🚀
            </>
          )}
        </p>
      </Trans>

      {sponsorImage && (
        <>
          <p className={styles.sponsoredBy}>{t("sponsored_by_text")}</p>
          <img
            className={styles.sponsorImage}
            src={sponsorImage}
            alt={t("sponsor_logo_alt")}
          />
        </>
      )}

      <Button
        onClick={onUploadAnotherPhoto}
        color="primary"
        variant="contained"
        className={styles.button}
        disableElevation
      >
        {t("record_litter_upload_another_photo_button_text")}
      </Button>

      <br />

      <Button
        onClick={onGoToMap}
        color="primary"
        variant="outlined"
        className={styles.button}
        disableElevation
      >
        {t("back_to_the_map_button_text")}
      </Button>
    </Dialog>
  );
}
