// see https://firebase.google.com/docs/web/setup
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/performance";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/remote-config";

import { initializeFirestore } from "firebase/firestore";
import { Capacitor } from "@capacitor/core";
import { usingFirebaseEmulator } from "../../shared/utils";

import { getApp } from "firebase/app";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth
} from "firebase/auth";

const PROD_CONFIG = require("./config.json");
const DEV_CONFIG = require("./config.dev.json");
const TEST_CONFIG = require("./config.test.json");

const getFirebaseAuth = async () => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence
    });
  } else {
    return getAuth();
  }
};

const getFirebaseConfig = () => {
  if (process.env.REACT_APP_USE_PROD_DATA === "true") {
    return PROD_CONFIG;
  }

  if (process.env.REACT_APP_FIREBASE_PROJECT !== "") {
    switch (process.env.REACT_APP_FIREBASE_PROJECT) {
      case DEV_CONFIG.projectId:
        return DEV_CONFIG;
      case TEST_CONFIG.projectId:
        return TEST_CONFIG;
      case PROD_CONFIG.projectId:
        console.warn(
          `Firebase CLI set to prod project. If you want to test against prod data locally, set the REACT_APP_USE_PROD_DATA env var.`
        );
        break;
      default:
        console.warn(
          `REACT_APP_FIREBASE_PROJECT variable was set to something unknown. ${process.env.REACT_APP_FIREBASE_PROJECT}`
        );
    }
  }

  return TEST_CONFIG;
};

const config = getFirebaseConfig();

if (window.location.hostname === "localhost" && config === PROD_CONFIG) {
  console.warn(`RUNNING AGAINST PROD FIREBASE - BE VERY CAREFUL`);
}

// Initialize Firebase
if (firebase.apps.length === 0) {
  console.log("Initializing Firebase app");
  firebase.initializeApp(config);
}

getFirebaseAuth();

initializeFirestore(firebase.app(), {
  ignoreUndefinedProperties: true,
  experimentalForceLongPolling: true
});

console.log("env");
console.log(process.env);

// If running on localhost, connect to a locally running emulator
if (usingFirebaseEmulator()) {
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.firestore().clearPersistence();
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.functions().useEmulator("localhost", 5001);
  firebase.database().useEmulator("localhost", 9000);
  firebase.storage().useEmulator("localhost", 9199);
  console.log("Connecting to: local Firebase emulator suite");
} else {
  console.log(`Connecting to: ${config.projectId}`);
  console.log(config);
}

function getFunctionApiUrl () {
  if (usingFirebaseEmulator()) {
    return `http://localhost:5001/${config.projectId}/us-central1/api`;
  } else {
    return config.apiURL;
  }
}

function getGeoDataURL () {
  return config.geoDataURL;
}

// remote config: https://firebase.google.com/docs/remote-config/get-started?platform=web
const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis =
  process.env.NODE_ENV !== "development" ? 3600000 : 0;
remoteConfig.defaultConfig = {
  enable_missions: true,
  is_mission_launch_day: true
};

// need to refetch for next time.
remoteConfig
  .fetchAndActivate()
  .then(() => {
    console.log("Fetched remote config");
    console.log(firebase.remoteConfig().getAll());
  })
  .catch(err => {
    console.log("ERROR");
  });

// measuring web performance. See https://firebase.google.com/docs/perf-mon/get-started-web
firebase.performance();
// const perf = firebase.performance(); //don't use the reference yet
// TODO: to measure input delay: https://github.com/GoogleChromeLabs/first-input-delay

export { remoteConfig, getFunctionApiUrl, getGeoDataURL };
