import { GeoData } from "../GeoData";
import { LatLong } from "../GPSLocation";
import { ImageMetaData } from "../Photo";

export type ReadingFormData = {
  userId: string;
  readingType: string; // e.g. nitrate / phosphate / coliforms / ph
  location: LatLong;
  dateTime: number; // From photo taken or otherwise use upload time
  waterwayName: String;
  testKitName: String; // 'PlanetPatrol', otherwise user manual entry
  photo?: ImageMetaData | string;
  geodata?: GeoData;
};

export type ReadingFormDataWithId = ReadingFormData & {
  id: string;
};

export type Reading = ReadingFormDataWithId & {
  main: string; // url to photo storage
  second?: string; // url to photo storage
  thumbnail: string; // url to photo storage
};

// For display on the map
// data property will include the reading specific data, e.g. nitrate: 30
export type MapReadingData = Reading & {
  data: Map<string, string>;
};

export const getReadingMapDataEntries = (
  reading: Reading
): [string, string][] => {
  return [
    ["Waterway", reading.waterwayName.toString()],
    ["Test kit", reading.testKitName.toString()],
    ["Test time", new Date(reading.dateTime).toLocaleString()]
  ];
};
