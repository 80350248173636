export function linkToResetPassword() {
  return "/reset-password";
}

export function linkToResetPasswordWithRedirectOnSuccess(
  redirectToOnSuccess: string
) {
  return {
    pathname: linkToResetPassword(),
    state: {
      redirectToOnSuccess
    }
  };
}
