import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import _ from "lodash";

import firebase from "firebase/compat/app";

import User from "types/User";

import { gtagEvent } from "gtag";

import useEffectOnMount from "hooks/useEffectOnMount";

import { onAuthStateChanged } from "../features/firebase/authFirebase";

import { syncNotifications } from "../utils/notifications";

import config from "custom/config";
import { Timestamp } from "firebase/firestore";

const UserContext = React.createContext<{
  user: User | undefined | null;
  setUser: React.Dispatch<React.SetStateAction<User | undefined | null>>;
}>({ user: undefined, setUser: () => {} });
type Props = { children: React.ElementType };

export default function UserProvider({ children }: Props) {
  const [user, setUser] = useState<User | undefined | null>();

  const history = useHistory();
  const onSignOut = () => {
    gtagEvent("Signed out", "User" as any);
    history.push(config.PAGES.home.path);
    window.location.reload();
  };

  useEffect(() => {
    let firestoreUnsubscribe: () => void = () => {};
    const firebaseAuthUnsubscribe = firebase
      .auth()
      .onAuthStateChanged((firebaseUser: firebase.User | null) => {
        if (firebaseUser === null) {
          setUser(null);
          return;
        }

        const userRef = firebase
          .firestore()
          .collection("users")
          .doc(firebaseUser.uid);

        firestoreUnsubscribe = userRef.onSnapshot((data) => {
          // We should wait to grab the main user data from gravatar before doing listening for future changes.
          if (user === undefined) {
            return;
          }

          if (!data.exists) {
            console.warn(
              `Tried to fetch user ${firebaseUser.uid} from firestore but data didn't exist`
            );
            return;
          }

          const userFirebaseData = data.data() as Partial<User>;

          // Hack to avoid continuous re-renders.
          if (
            (!userFirebaseData.missions ||
              _.isEqual(user?.missions, userFirebaseData.missions)) &&
            user?.isModerator === !!userFirebaseData.isModerator
          ) {
            return;
          }

          setUser({
            ...(user as User),
            ...userFirebaseData
          });

          // Sync the user's local notifications with the server data
          if (userFirebaseData?.notifications) {
            userFirebaseData?.notifications.forEach((notification) => {
              if (notification.schedule?.at) {
                notification.schedule.at = (
                  notification.schedule.at as unknown as Timestamp
                ).toDate();
              }
            });
            syncNotifications(userFirebaseData?.notifications || []);
          }
        });
      });

    return () => {
      console.log(
        `UserProvider unmounted. Unsubscribing from Firebase AND stopped listening to firestore user ref.`
      );
      firebaseAuthUnsubscribe();
      firestoreUnsubscribe();
    }; // Unsubscribe callback on unmount
  });

  useEffectOnMount(() => {
    onAuthStateChanged({
      onSignOut,
      setUser
    });
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext);
