import useEffectOnMount from "hooks/useEffectOnMount";
import { useGPSLocation } from "providers/LocationProvider";
import { GPSLocation } from "types/GPSLocation";

import { Question } from "types/Survey";

type Props = {
  question: Question;
  onChangeQuestionValue: (
    questionId: string,
    value: GPSLocation | undefined,
    valid: boolean
  ) => void;
};

export default function QuestionLocation({
  question,
  onChangeQuestionValue
}: Props) {
  const gpsLocation = useGPSLocation();

  useEffectOnMount(() => {
    onChangeQuestionValue(question.id, gpsLocation, true);
  });

  return <></>;
}
