import React, { useCallback, useState } from "react";

import _ from "lodash";

import { toOutcode } from "postcode";

import { Link } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import SearchIcon from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation, Trans } from "react-i18next";

import PageWrapper from "../../components/PageWrapper";
import MapLocation from "../../utils/MapLocation";
import Feature, {
  featurePropertiesIsPhoto,
  featurePropertiesIsReading
} from "types/Feature";
import Geojson from "types/Geojson";
import Tooltip from "components/common/Tooltip";
import User from "types/User";
import { Config } from "custom/config";

import Photo from "../../types/Photo";

import {
  ALL_READING_TYPES,
  getFullReadingName
} from "../../types/Readings/Shared";

import { MapReadingData, ReadingFormData } from "types/Readings/Base";

import { NITRATE_READING_TYPE } from "types/Readings/Nitrate";
import { PH_READING_TYPE } from "types/Readings/Ph";
import { COLIFORMS_READING_TYPE } from "types/Readings/Coliforms";
import { TEMPERATURE_READING_TYPE } from "types/Readings/Temperature";

import { IncidentData } from "types/Incident";
import ListSubheader from "@material-ui/core/ListSubheader";

import { useStats } from "providers/StatsProvider";

import UserBadges from "./common/UserBadges";
import ReadingActivityChart from "./../readings/upload/common/ReadingActivityChart";
import ReadingValuesChart from "./../readings/upload/common/ReadingValuesChart";

import styles from "standard.module.scss";

const LARGE_COLLECTION_THRESHOLD = 1000;

const LARGE_UPLOAD_TOOLTIP =
  `Large uploads track any individual uploads ` +
  `that contain more than ${LARGE_COLLECTION_THRESHOLD} ` +
  `pieces. These are accounted for separately in the ` +
  `leaderboard and here in your account for bookkeeping purposes.`;

const useStyles = makeStyles((theme) => ({
  stat: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1)
  },
  statNumberLitter: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: styles.litter
  },
  statNumberWater: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: styles.water
  },
  tabs: {
    marginBottom: theme.spacing(2)
  }
}));

interface Props {
  user: User;
  geojson: Geojson;
  handlePhotoClick: (feature: Feature) => void;
  handleClose: () => void;
  config: Config;
}

export default function DashboardPage({
  user,
  geojson,
  handlePhotoClick,
  handleClose,
  config
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const stats = useStats();

  const [tabValue, setTabValue] = useState(0);
  const [postcode, setPostcode] = useState("");
  const [tempPostcode, setTempPostcode] = useState("");

  const calcUrl = useCallback(
    (feature: Feature, readingType?: string, isIncident?: boolean): string => {
      const mapLocation = new MapLocation(
        feature.geometry.coordinates[1],
        feature.geometry.coordinates[0],
        config.ZOOM_FLYTO
      );
      const urlFormatted = mapLocation.urlFormatted();
      if (readingType) {
        return `${config.PAGES.displayFeature.path}/${readingType}/${feature.properties.id}@${urlFormatted}`;
      }
      if (isIncident) {
        return `${config.PAGES.displayFeature.path}/incidents/${feature.properties.id}@${urlFormatted}`;
      }
      return `${config.PAGES.displayFeature.path}/photos/${feature.properties.id}@${urlFormatted}`;
    },
    [config]
  );

  let userLitterPhotos: Feature[] = [];
  if (geojson !== null && geojson !== undefined) {
    userLitterPhotos = geojson.features
      .filter((f: Feature) => featurePropertiesIsPhoto(f.properties))
      .filter((f: Feature) => (f.properties as Photo).owner_id === user?.id);
  }

  const recentPhotos: Feature[] = _.reverse(
    _.sortBy(
      userLitterPhotos,
      (f: Feature) => (f.properties as Photo).moderated
    )
  ).slice(0, 20);

  const readingPhotos = geojson?.features.filter((f: Feature) =>
    featurePropertiesIsReading(f.properties)
  );

  const userReadingPhotos =
    readingPhotos &&
    _.reverse(
      _.sortBy(
        readingPhotos.filter(
          (f) => (f.properties as MapReadingData).userId === user?.id
        ),
        ["properties.dateTime"]
      )
    );

  const postcodeReadingPhotos =
    readingPhotos &&
    readingPhotos.filter(
      (f) =>
        toOutcode((f.properties as MapReadingData).geodata?.postcode || "") ===
        toOutcode(postcode)
    );
  const nitrateReadings = userReadingPhotos.filter(
    (f) =>
      (f.properties as ReadingFormData).readingType === NITRATE_READING_TYPE
  );
  const allNitrateReadings = geojson.features.filter(
    (f) =>
      (f.properties as ReadingFormData).readingType === NITRATE_READING_TYPE
  );
  const phosphateReadings = userReadingPhotos.filter(
    (f) => (f.properties as ReadingFormData).readingType === PH_READING_TYPE
  );
  const allPhosphateReadings = geojson.features.filter(
    (f) => (f.properties as ReadingFormData).readingType === PH_READING_TYPE
  );
  const phReadings = userReadingPhotos.filter(
    (f) => (f.properties as ReadingFormData).readingType === PH_READING_TYPE
  );
  const allPhReadings = geojson.features.filter(
    (f) => (f.properties as ReadingFormData).readingType === PH_READING_TYPE
  );
  const coliformsReadings = userReadingPhotos.filter(
    (f) =>
      (f.properties as ReadingFormData).readingType === COLIFORMS_READING_TYPE
  );
  const allColiformsReadings = geojson.features.filter(
    (f) =>
      (f.properties as ReadingFormData).readingType === COLIFORMS_READING_TYPE
  );
  const temperatureReadings = userReadingPhotos.filter(
    (f) =>
      (f.properties as ReadingFormData).readingType === TEMPERATURE_READING_TYPE
  );
  const allTemperatureReadings = geojson.features.filter(
    (f) =>
      (f.properties as ReadingFormData).readingType === TEMPERATURE_READING_TYPE
  );

  const numPieces = _.sumBy(
    userLitterPhotos,
    (f: Feature) => (f.properties as Photo).pieces
  );

  const largeUploads = userLitterPhotos.filter(
    (f: Feature) => (f.properties as Photo).pieces > LARGE_COLLECTION_THRESHOLD
  );

  const largeUploadPieces = _.sumBy(
    largeUploads,
    (f: Feature) => (f.properties as Photo).pieces
  );

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <PageWrapper
      label={t(config.PAGES.dashboard.label)}
      navigationHandler={{ handleClose }}
    >
      <Container>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          className={classes.tabs}
        >
          <Tab label="My stats" />
          <Tab label="Everyone" />
          <Tab label="Your area" />
        </Tabs>
      </Container>

      {tabValue === 0 && (
        <Container>
          <ListSubheader disableSticky disableGutters>
            Your activity
          </ListSubheader>
          <ReadingActivityChart
            userId={user?.id}
            postcode={null}
            readingTypes={["litter", "water"]}
          />

          <ListSubheader disableSticky disableGutters>
            Achievements
          </ListSubheader>
          <UserBadges user={user} />

          <ListSubheader disableSticky disableGutters>
            All time
          </ListSubheader>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberLitter}>
                  {userLitterPhotos.length.toLocaleString()}
                </span>
                <br />
                {t("account_num_of_uploads")}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberLitter}>
                  {numPieces.toLocaleString()}
                </span>
                <br />
                {t("account_total_pieces")}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {userReadingPhotos.length.toLocaleString()}
                </span>
                <br />
                water quality readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {nitrateReadings.length.toLocaleString()}
                </span>
                <br />
                nitrate and nitrite readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {phosphateReadings.length.toLocaleString()}
                </span>
                <br />
                phosphate readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {phReadings.length.toLocaleString()}
                </span>
                <br />
                pH readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {coliformsReadings.length.toLocaleString()}
                </span>
                <br />
                coliforms readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {temperatureReadings.length.toLocaleString()}
                </span>
                <br />
                temperature readings
              </div>
            </Grid>
          </Grid>
          <br />
          {largeUploads.length > 0 && (
            <>
              <Typography variant="body1">
                {t("account_num_of_large_uploads")}{" "}
                <strong>{largeUploads.length}</strong>
                <Tooltip tooltip={LARGE_UPLOAD_TOOLTIP} />
              </Typography>
              <Typography variant="body1">
                {t("account_total_pieces_large_uploads")}{" "}
                <strong>{largeUploadPieces}</strong>
              </Typography>
            </>
          )}

          {recentPhotos.length > 0 && (
            <>
              <ListSubheader disableGutters disableSticky>
                Recently approved litter picks
              </ListSubheader>

              {_.map(recentPhotos.slice(0, 3), (feature: Feature) => {
                const photo: Photo = feature.properties as Photo;
                const moderated: Date | undefined = photo.moderated;
                return (
                  <Typography variant="body1" key={photo.id}>
                    {photo.pieces && (
                      <span>
                        <Trans
                          i18nKey="account_approved_piece_count"
                          count={photo.pieces}
                        >
                          <strong>{{ count: photo.pieces }}</strong> piece
                        </Trans>
                      </span>
                    )}
                    {` `}
                    <Link
                      to={calcUrl(feature)}
                      onClick={() => handlePhotoClick(feature)}
                    >
                      {moderated && moderated.toDateString
                        ? moderated.toDateString()
                        : moderated?.toString()}
                    </Link>
                  </Typography>
                );
              })}
            </>
          )}

          <br />

          {userReadingPhotos.length > 0 && (
            <>
              <ListSubheader disableGutters disableSticky>
                Recent water quality readings
              </ListSubheader>

              {_.map(userReadingPhotos.slice(0, 3), (feature: Feature) => {
                const photo = feature.properties as MapReadingData;
                const isIncident =
                  (feature.properties as IncidentData)?.description?.length > 0;
                return (
                  <Typography variant="body1" key={photo.id}>
                    {photo.readingType && (
                      <span>
                        <strong>{getFullReadingName(photo.readingType)}</strong>
                      </span>
                    )}
                    {isIncident && (
                      <span>
                        <strong>Incident</strong>
                      </span>
                    )}
                    {` `}
                    <Link
                      to={calcUrl(feature, photo.readingType, isIncident)}
                      onClick={() => handlePhotoClick(feature)}
                    >
                      {new Date(photo.dateTime).toDateString()}
                    </Link>
                  </Typography>
                );
              })}
            </>
          )}
        </Container>
      )}

      {tabValue === 1 && (
        <Container>
          <ListSubheader disableSticky disableGutters>
            Recent activity
          </ListSubheader>
          <ReadingActivityChart
            userId={null}
            readingTypes={["litter", "water"]}
            postcode={null}
          />
          <ListSubheader disableSticky disableGutters>
            All time
          </ListSubheader>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberLitter}>
                  {stats.totalUploaded.toLocaleString()}
                </span>
                <br />
                {t("account_num_of_uploads")}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberLitter}>
                  {stats.pieces.toLocaleString()}
                </span>
                <br />
                {t("account_total_pieces")}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {readingPhotos.length.toLocaleString()}
                </span>
                <br />
                water quality readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {allNitrateReadings.length.toLocaleString()}
                </span>
                <br />
                nitrate and nitrite readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {allPhosphateReadings.length.toLocaleString()}
                </span>
                <br />
                phosphate readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {allPhReadings.length.toLocaleString()}
                </span>
                <br />
                pH readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {allColiformsReadings.length.toLocaleString()}
                </span>
                <br />
                coliforms readings
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.stat}>
                <span className={classes.statNumberWater}>
                  {allTemperatureReadings.length.toLocaleString()}
                </span>
                <br />
                temperature readings
              </div>
            </Grid>
          </Grid>
          {ALL_READING_TYPES.map((readingType: string) => (
            <>
              <ListSubheader disableSticky disableGutters>
                {getFullReadingName(readingType)}
              </ListSubheader>
              <ReadingValuesChart
                reading={null}
                readingType={readingType}
                postcode={null}
              />
            </>
          ))}
        </Container>
      )}

      {tabValue === 2 && (
        <Container>
          <TextField
            label={"Postcode"}
            variant="outlined"
            value={tempPostcode}
            onChange={(e) => setTempPostcode(e.target.value.toUpperCase())}
            onClick={(e) => e.stopPropagation()}
            onSelect={(e) => e.stopPropagation()}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setPostcode(tempPostcode);
                  }}
                >
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Container>
      )}

      {tabValue === 2 && postcode.length > 0 && (
        <Container>
          {postcodeReadingPhotos.length > 0 ? (
            <>
              <ListSubheader disableSticky disableGutters>
                Recent activity
              </ListSubheader>
              <ReadingActivityChart
                userId={null}
                postcode={postcode}
                readingTypes={["water"]}
              />
              <ListSubheader disableSticky disableGutters>
                All time
              </ListSubheader>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className={classes.stat}>
                    <span className={classes.statNumberWater}>
                      {postcodeReadingPhotos.length.toLocaleString()}
                    </span>
                    <br />
                    water readings
                  </div>
                </Grid>
              </Grid>
              {ALL_READING_TYPES.map((readingType: string) => (
                <>
                  <ListSubheader disableSticky disableGutters>
                    {getFullReadingName(readingType)}
                  </ListSubheader>
                  <ReadingValuesChart
                    reading={null}
                    readingType={readingType}
                    postcode={postcode}
                  />
                </>
              ))}
            </>
          ) : (
            <Typography variant="body1">
              <br/>
              We don’t have any results in your area yet, help us by{" "}
              <a
                href="https://planetpatrol.co/what-lies-beneath/"
                target="_blank"
                rel="noreferrer"
              >
                signing up to be a water tester
              </a>
              .
            </Typography>
          )}
        </Container>
      )}
    </PageWrapper>
  );
}
