import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import { UploadPage } from "../IncidentUpload";

import { IncidentData } from "types/Incident";
import { photoIsMetaData } from "../../../types/Photo";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  heading: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(2)}px`
  },
  editButton: {
    float: "right"
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  photoWrapper: {
    width: "100%",
    textAlign: "center",
    paddingBottom: `${theme.spacing(2)}px`
  },
  photoPreview: {
    maxWidth: "100%",
    maxHeight: "180px"
  },
  uploadError: {
    color: "red"
  },
  button: {
    width: "100%",
    marginTop: `${theme.spacing(1)}px`
  }
}));

type Props = {
  incident: IncidentData;
  onEdit: (page: UploadPage) => void;
  onSubmit: () => void;
  uploadError?: string;
  isUploading: boolean;
};

const UploadSummary = ({
  incident,
  onEdit,
  onSubmit,
  uploadError,
  isUploading
}: Props) => {
  const classes = useStyles();
  const date = new Date(incident.dateTime);

  const EditButton = (uploadPage: UploadPage) => {
    return (
      <div className={classes.editButton} onClick={() => onEdit(uploadPage)}>
        Edit
      </div>
    );
  };

  return (
    <>
      <div className={classes.text}>
        Thanks for uploading your results. You can review them below before submitting.
      </div>
      {incident.photo && (
        <>
          <div className={classes.heading}>
            Photo
            {EditButton(UploadPage.PhotoUpload)}
          </div>
          <div className={classes.photoWrapper}>
            <img
              src={
                incident.photo &&
                (photoIsMetaData(incident.photo)
                  ? incident.photo.imgSrc
                  : incident.photo)
              }
              className={classes.photoPreview}
              alt={""}
            />
          </div>
        </>
      )}
      <div className={classes.heading}>
        Location and time
        {EditButton(UploadPage.PhotoGeotag)}
      </div>
      <div className={classes.text}>
        Location: ({incident.location.latitude}, {incident.location.longitude})
        <br />
        Time: {date.toLocaleTimeString()}
        <br />
        Date: {date.toLocaleDateString()}
        <br />
      </div>
      <div className={classes.heading}>
        Description
        {EditButton(UploadPage.Description)}
      </div>
      <div className={classes.text}>{incident.description}</div>
      <div className={classes.heading}>
        Waterway
        {EditButton(UploadPage.WaterwayName)}
      </div>
      <div className={classes.text}>Body of water: {incident.waterwayName}</div>
      {uploadError !== "" && (
        <div className={classes.uploadError}>{uploadError}</div>
      )}
      <Button
        onClick={onSubmit}
        color="primary"
        className={classes.button}
        variant="contained"
        disabled={isUploading}
        size="large"
        disableElevation
      >
        Submit
      </Button>
    </>
  );
};

export default UploadSummary;
