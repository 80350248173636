import AccountCircleIcon from "@material-ui/icons/AccountCircleRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircleRounded";
import HomeIcon from "@material-ui/icons/HomeRounded";
import GroupIcon from "@material-ui/icons/GroupRounded";
import SchoolIcon from "@material-ui/icons/SchoolRounded";
import DashboardIcon from "@material-ui/icons/DashboardRounded";
import HelpIcon from "@material-ui/icons/HelpRounded";
import EventIcon from "@material-ui/icons/EventRounded";
import FeedbackIcon from "@material-ui/icons/FeedbackRounded";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooksRounded";
import MissionIconImage from "assets/images/mission.png";
import GraphIcon from "@material-ui/icons/EqualizerRounded";

import styles from "standard.module.scss";

import User from "types/User";
import Page from "types/Page";

import { linkToFeedbackReports } from "routes/feedback-reports/links";
import { linkToTutorialPage } from "routes/tutorial/links";
import { linkToAboutPage } from "routes/about/links";
import { LONDON_LAT_LON } from "../types/GPSLocation";

const primaryMain = styles.primaryMain;
const primaryContrastText = styles.primaryContrastText;
const secondaryMain = styles.secondaryMain;
const secondaryContrastText = styles.primaryContrastText;

export const linkToHome = () => "/";
export const linkToMap = () => "/map";

// NOTE: the `label` is the text of drawer item, and also the title of the app bar.
// Need to match the translations key in locales json so it is correctly displayed.
const PAGES: { [pageName: string]: Page } = {
  home: {
    path: linkToHome(),
    label: "Home",
    icon: <HomeIcon />
  },
  map: {
    path: linkToMap(),
    label: "Map"
  },
  embeddable: {
    path: "/embeddable",
    label: "Map"
  },
  photo: {
    path: "/photo",
    label: "photo"
  },
  moderator: {
    path: "/moderator",
    label: "photo_approval",
    icon: <CheckCircleIcon />,
    visible: (user: User | undefined, online: boolean) =>
      !!(user && user.isModerator)
  },
  account: {
    path: "/account",
    label: "account",
    icon: <AccountCircleIcon />,
    visible: (user: User | undefined, online: boolean) => !!user
  },
  about: {
    path: linkToAboutPage(),
    label: "about",
    visible: (user: User | undefined, online: boolean) => true,
    icon: <HelpIcon />
  },
  tutorial: {
    path: linkToTutorialPage(),
    label: "tutorial",
    visible: (user: User | undefined, online: boolean) => true,
    icon: <SchoolIcon />
  },
  writeFeedback: {
    path: "/write-feedback",
    label: "feedback",
    visible: (user: User | undefined, online: boolean) => true,
    icon: <FeedbackIcon />
  },
  leaderboard: {
    path: "/leaderboard",
    label: "leaderboard",
    visible: (user: User | undefined, online: boolean) => true,
    icon: <DashboardIcon />
  },
  feedbackReports: {
    path: linkToFeedbackReports(),
    label: "feedback_reports",
    icon: <LibraryBooksIcon />,
    visible: (user: User | undefined, online: boolean) =>
      !!(user && user.isModerator)
  },
  displayFeature: {
    path: "/features",
    label: "Pollution data"
  },
  cleanUps: {
    path: "https://planetpatrol.co/clean-ups/",
    visible: (user: User | undefined, online: boolean) => true,
    icon: <EventIcon />,
    label: "clean_ups"
  },
  groups: {
    path: "/groups",
    label: "Groups",
    visible: (user, online) => true,
    icon: <GroupIcon />
  },
  grouplist: {
    path: "/grouplist",
    label: "List Groups",
    visible: (user, online) => true
  },
  groupadd: {
    path: "/groupadd",
    label: "Create a Group",
    visible: (user, online) => true
  },
  missions: {
    path: "/missions",
    label: "missions",
    visible: (user, online) => true,
    icon: (
      <img
        src={MissionIconImage}
        alt="Missions icon"
        style={{ width: 20, height: 20, paddingLeft: 2 }}
      />
    )
  },
  dashboard: {
    path: "/dashboard",
    label: "Stats",
    visible: (user, online) => true,
    icon: <GraphIcon />
  }
};

export interface Metadata {
  metadataServerUrl: string;
  serverUrl: string;
  twSite: string;
  twCreator: string;
  twDomain: string;
  _twDescriptionField: string;
  twDescription: string;
  twTitle: string;
}

export interface Config {
  PAGES: { [pageName: string]: Page };
  ENABLE_GROUPS: boolean;
  metadata: Metadata;
  MAX_IMAGE_SIZE: number;
  THEME: any;
  MAP_SOURCE: string;
  MAPBOX_TOKEN: string;
  MAPBOX_DEV_TOKEN: string;
  GA_TRACKING_ID: string;
  GA_PROPERTY_ID: string;
  PHOTO_ZOOMED_FIELDS: any;
  ZOOM: number;
  ZOOM_FLYTO: number;
  CENTER: [number, number];
  ENABLE_GRAVATAR_PROFILES: boolean;
  SECURITY: {
    UPLOAD_REQUIRES_LOGIN: boolean;
  };
  MODERATING_PHOTOS: number;
  LEADERBOARD_FIELD: any;
}

const config: Config = {
  metadata: {
    metadataServerUrl: "https://md.plasticpatrol.co.uk",
    serverUrl: "https://app.plasticpatrol.co.uk",
    twSite: "@onplanetpatrol",
    twCreator: "@LizzieOutside",
    twDomain: "planetpatrol.co",
    _twDescriptionField: "pieces",
    twDescription:
      "The global movement that is crowdsource cleaning the planet. Download the Planet Patrol app to join the movement!",
    twTitle: "Planet Patrol"
  },
  MAX_IMAGE_SIZE: 2048,
  THEME: {
    palette: {
      primary: { main: primaryMain, contrastText: primaryContrastText },
      secondary: { main: secondaryMain, contrastText: secondaryContrastText }
    },
    typography: {
      fontFamily: ["BrownSTD"],
      button: {
        textTransform: "none",
        fontWeight: 700
      }
    },
    spacing: 10,
    overrides: {
      MuiBottomNavigation: {
        root: {
          backgroundColor: primaryMain
        }
      },
      MuiBottomNavigationAction: {
        wrapper: {
          color: "#fff"
        }
      },
      MuiListSubheader: {
        root: {
          fontWeight: 600
        }
      }
    }
  },
  MAP_SOURCE: "mapbox://styles/mapbox/streets-v11",
  // MAP_SOURCE: "https://s3-eu-west-1.amazonaws.com/tiles.os.uk/styles/open-zoomstack-outdoor/style.json",
  // MAP_ATTRIBUTION: "Contains OS data &copy; Crown copyright and database rights 2018",
  MAPBOX_TOKEN:
    "pk.eyJ1IjoicGxhbmV0cGF0cm9sIiwiYSI6ImNsNXNyaWhwOTA3M2MzZHBzMGc4dWZ5NzQifQ.0ha3LJ1C1HItBdvUwayemw",
  MAPBOX_DEV_TOKEN:
    "pk.eyJ1IjoibmVpbGZ1bHdpbGVyIiwiYSI6ImNrOGN1dWV2bzBxaHIzZnRxaDhtOWJ0ODYifQ.D56TN1nXxU0vGfmYb21T2w",
  GA_TRACKING_ID: "UA-126516084-1",
  GA_PROPERTY_ID: "189010506",
  PHOTO_ZOOMED_FIELDS: {
    updated: (s: string) => new Date(s).toDateString(),
    pieces: (s: string) => s
  },
  ZOOM: 5,
  ZOOM_FLYTO: 10,
  CENTER: [LONDON_LAT_LON.longitude, LONDON_LAT_LON.latitude],
  PAGES,
  ENABLE_GRAVATAR_PROFILES: false, // To update user-profile from Gravatar, value: true or false.
  ENABLE_GROUPS: false,
  SECURITY: {
    UPLOAD_REQUIRES_LOGIN: true
  },
  MODERATING_PHOTOS: 15,
  LEADERBOARD_FIELD: {
    label: "Pieces",
    field: "pieces"
  }
};

export default config;
