import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { getEmbeddedVideoId } from "../../../../types/Readings/Shared";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(2)}px`
  },
  instructions: {
    // Shade in grey each other list item
    "& ol": {
      "& li": {
        "&:nth-child(odd)": {
          backgroundColor: theme.palette.grey[200],
          padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
        }
      }
    }
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  youtubeIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0
  },
  youtubeIframeWrapper: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%"
  }
}));

type Props = {
  readingName: string;
  showVideoDialog: boolean;
  setShowVideoDialog: (show: boolean) => void;
};

const ReadingVideoDialog = ({
  readingName,
  showVideoDialog,
  setShowVideoDialog
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Dialog fullWidth={true} className="instructions-video-dialog" open={showVideoDialog}>
        <DialogContent>
          <div className={classes.youtubeIframeWrapper}>
            <iframe
              className={classes.youtubeIframe}
              src={getEmbeddedVideoId(readingName)}
              frameBorder="0"
              height="500"
              allowFullScreen={true}
              title="Planet Patrol test walkthrough"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowVideoDialog(false)}
            color="primary"
            disableElevation
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReadingVideoDialog;
