import { useState } from "react";

import _ from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import { isIphoneWithNotchAndCordova, isIphoneAndCordova } from "utils";
import { tweetMessage } from "static/info";

import BackIcon from "@material-ui/icons/ArrowBackRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";

import CardComponent from "../CardComponent";
import Feature, {
  featurePropertiesIsIncident,
  featurePropertiesIsReading
} from "types/Feature";
import User from "types/User";
import { dbFirebase } from "features/firebase";
import ConfirmationDialog, {
  Confirmation
} from "components/common/ConfirmationDialog";
import { MapReadingData } from "../../types/Readings/Base";
import Photo from "../../types/Photo";
import { MapIncidentData } from "types/Incident";

import { COLIFORMS_READING_TYPE } from "../../types/Readings/Coliforms";

import { getReadingResultContextFromFeature } from "../../utils/waterReadingAnalytics";

const tweetLogo = process.env.PUBLIC_URL + "/images/twitter.svg";

const useStyles = makeStyles((theme) => ({
  notchTop: {
    paddingTop: isIphoneWithNotchAndCordova()
      ? "env(safe-area-inset-top)"
      : isIphoneAndCordova
      ? theme.spacing(1.5)
      : undefined
  },
  iconButton: {
    marginRight: theme.spacing(2)
  },
  main: {
    marginTop: theme.spacing(2)
  },
  tweetLogo: {
    padding: theme.spacing(1.6)
  },
  notchBottom: {
    paddingBottom: isIphoneWithNotchAndCordova()
      ? "env(safe-area-inset-bottom)"
      : 0
  }
}));

interface Props {
  user: User;
  config: any;
  feature?: Feature;
  handleClose: () => void;
  location: any;
}

export default function DisplayFeature({
  user,
  config,
  feature,
  handleClose,
  location
}: Props) {
  const formatField = (value: string, fieldName: string): string => {
    const formatter = config.PHOTO_ZOOMED_FIELDS[fieldName];
    if (value) {
      return formatter(value);
    }

    return "-";
  };

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState<Confirmation | undefined>();
  const photoID = _.get(feature, "properties.id", "");
  const coords = location.pathname.split("@")[1];
  const photoUrl = `${config.metadata.metadataServerUrl}/${photoID}@${coords}`;
  const photoTweetLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    tweetMessage
  )}&url=${encodeURIComponent(photoUrl)}`;

  const isReading =
    feature !== undefined && featurePropertiesIsReading(feature?.properties);
  const isIncident =
    feature !== undefined && featurePropertiesIsIncident(feature?.properties);

  const getReadingDisplayText = (feature: Feature) => {
    const result = getReadingResultContextFromFeature(feature);
    return (
      <div>
        {Array.from((feature.properties as MapReadingData).data).map(
          (value: [string, string]) => {
            const fieldName = value[0];
            const fieldValue = value[1];
            return (
              <Typography gutterBottom key={fieldName}>
                <b>{_.capitalize(fieldName)}: </b>
                {fieldValue}
              </Typography>
            );
          }
        )}
        {result !== "" && (
          <>
            <hr />
            <Typography gutterBottom key={`Result`}>
              <b>Result: </b>
              {getReadingResultContextFromFeature(feature)}
            </Typography>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      {typeof feature === "undefined" ? (
        <Dialog
          open
          PaperProps={{
            style: { backgroundColor: "transparent", boxShadow: "none" }
          }}
        >
          <CircularProgress color="primary" />
        </Dialog>
      ) : (
        <Dialog
          fullScreen={fullScreen}
          open
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static" className={classes.notchTop} elevation={0}>
            <Toolbar>
              <BackIcon className={classes.iconButton} onClick={handleClose} />
              <Typography variant="h6" color="inherit">
                {t(config.PAGES.displayFeature.label)}
              </Typography>
            </Toolbar>
          </AppBar>

          <DialogContent>
            <div style={{ textAlign: "center" }}>
              <img
                onError={(e) => {
                  // @ts-ignore
                  e.target.src = "data:,";
                }}
                className={"main-image"}
                alt={""}
                src={(feature && feature.properties.main) || "data:,"}
              />
              {(feature.properties as MapReadingData).readingType ===
              COLIFORMS_READING_TYPE ? (
                <img
                  onError={(e) => {
                    // @ts-ignore
                    e.target.src = "data:,";
                  }}
                  className={"main-image"}
                  alt={""}
                  src={
                    (feature &&
                      (feature.properties as MapReadingData).second) ||
                    "data:,"
                  }
                />
              ) : null}
            </div>
            {feature === null ? (
              <h3>{t("display_photo_error_text")}</h3>
            ) : (
              <Card>
                <div style={{ display: "flex" }}>
                  <CardActionArea>
                    <CardContent>
                      {isReading
                        ? getReadingDisplayText(feature)
                        : isIncident
                        ? Array.from(
                            (feature.properties as MapIncidentData).data
                          ).map((value: [string, string]) => {
                            const fieldName = value[0];
                            const fieldValue = value[1];
                            return (
                              <Typography gutterBottom key={fieldName}>
                                <b>{_.capitalize(fieldName)}: </b>
                                {fieldValue}
                              </Typography>
                            );
                          })
                        : Object.keys(config.PHOTO_ZOOMED_FIELDS).map(
                            (fieldName) => (
                              <Typography gutterBottom key={fieldName}>
                                <b>{_.capitalize(fieldName)}: </b>
                                {formatField(
                                  // @ts-ignore
                                  feature.properties[fieldName],
                                  fieldName
                                )}
                              </Typography>
                            )
                          )}
                    </CardContent>
                  </CardActionArea>
                  {!isReading && !isIncident && (
                    <a
                      className={classes.tweetLogo}
                      href={photoTweetLink}
                      target="blank"
                    >
                      <img src={tweetLogo} alt="tweet" />
                    </a>
                  )}
                </div>
                {user && user.isModerator && !isReading && !isIncident && (
                  <div>
                    <Divider />
                    <div>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            {t("display_photo_moderator_details")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CardComponent
                            photoSelected={feature.properties as Photo}
                            handleReject={() => {
                              setConfirmation({
                                message: t(
                                  "photo_approval_unpublish_photo_message"
                                ),
                                onConfirmation: () =>
                                  dbFirebase.writeModeration(
                                    feature.properties.id,
                                    user.id,
                                    false
                                  )
                              });
                            }}
                            handleApprove={() => {
                              setConfirmation({
                                message: t(
                                  "photo_approval_publish_photo_message"
                                ),
                                onConfirmation: () =>
                                  dbFirebase.writeModeration(
                                    feature.properties.id,
                                    user.id,
                                    true
                                  )
                              });
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                )}
                <ConfirmationDialog
                  confirmation={confirmation}
                  setConfirmation={setConfirmation}
                />
              </Card>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
