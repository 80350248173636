import { useState } from "react";

import firebase from "firebase/compat/app";

import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";

import { useUser } from "providers/UserProvider";

import PageWrapper from "components/PageWrapper";

import { INVALID_LAT_LON, LatLong } from "types/GPSLocation";
import { IncidentData, IncidentUploadHandle } from "types/Incident";
import { INVALID_USER_ID } from "types/User";
import { ImageMetaData, photoIsMetaData } from "../../types/Photo";

import { uploadIncident } from "features/firebase/incidents/api";

import IncidentDateTimeEntry from "./common/IncidentDateTimeEntry";
import IncidentWaterwayNameEntry from "./common/IncidentWaterwayNameEntry";
import IncidentPhotoUpload from "./common/IncidentPhotoUpload";
import IncidentGeoTag from "./common/IncidentGeoTag";
import IncidentDescription from "./common/IncidentDescription";
import UploadSummary from "./common/UploadSummary";

import IncidentUploadDialog from "./IncidentUploadDialog";

import { linkToMap } from "custom/config";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: theme.spacing(2)
  }
}));

export enum UploadPage {
  PhotoUpload,
  PhotoGeotag,
  PhotoDateTime,
  Description,
  WaterwayName,
  Summary
}

const IncidentUpload = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUser();

  const userId = user?.id || INVALID_USER_ID;
  if (userId === INVALID_USER_ID) {
    console.error(`Unauthenticated user in incident flow. Upload will fail.`);
  }

  const [currentPageView, setCurrentPageView] = useState<UploadPage>(
    UploadPage.PhotoUpload
  );
  const [previousPages, setPreviousPages] = useState<UploadPage[]>([]);
  const [photo, setPhoto] = useState<ImageMetaData | string | undefined>();
  const [editingInput, setEditingInput] = useState(false);
  const [description, setDescription] = useState("");
  const [waterwayName, setWaterwayName] = useState("");
  const [location, setLocation] = useState(INVALID_LAT_LON);
  const [dateTime, setDateTime] = useState(Date.now());
  const [uploadHandle, setUploadHandle] = useState<
    IncidentUploadHandle | undefined
  >();
  const [uploadError, setUploadError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const setNewPage = (newPage: UploadPage) => {
    setPreviousPages([...previousPages, currentPageView]);
    setCurrentPageView(newPage);
  };

  const onEditReading = (page: UploadPage) => {
    setEditingInput(true);
    setNewPage(page);
  };

  const onSubmitReading = async () => {
    const incidentData: IncidentData = {
      photo,
      dateTime,
      location,
      description,
      waterwayName,
      userId
    };

    setIsUploading(true);
    try {
      const result = await uploadIncident(
        firebase.firestore(),
        firebase.storage(),
        incidentData
      );
      setUploadHandle(result);
    } catch (err) {
      const errorMessage = `Error trying to upload reading. ${err}`;
      console.error(errorMessage);
      setUploadError(errorMessage);
    }
    setIsUploading(false);
  };

  const onCancelUpload = () => {
    setUploadHandle(undefined);
  };

  const onFinishUpload = () => {
    history.push(linkToMap());
  };

  let activePage = <div />;
  switch (currentPageView) {
    case UploadPage.PhotoUpload:
      const onPhotoSelect = (photo: ImageMetaData | string | undefined) => {
        setPhoto(photo);

        // Check to see if photo had location data on it.
        let photoHadLocation = false;
        if (
          photo !== undefined &&
          photoIsMetaData(photo) &&
          photo.imgLocation !== null
        ) {
          photoHadLocation = true;
          setLocation(photo.imgLocation);
        }

        // Let user geotag photo if no location data was attached.
        setNewPage(
          !photoHadLocation ? UploadPage.PhotoGeotag : UploadPage.Description
        );
      };
      activePage = (
        <IncidentPhotoUpload currentPhoto={photo} onSubmit={onPhotoSelect} />
      );
      break;
    case UploadPage.PhotoGeotag:
      const onGeoTag = (location: LatLong) => {
        setLocation(location);
        setNewPage(UploadPage.PhotoDateTime);
      };
      activePage = (
        <IncidentGeoTag currentLocation={location} onSubmit={onGeoTag} />
      );
      break;
    case UploadPage.PhotoDateTime:
      const onDateTimeSelect = (dateTime: Date) => {
        setDateTime(dateTime.getTime());
        setNewPage(!editingInput ? UploadPage.Description : UploadPage.Summary);
      };
      activePage = (
        <IncidentDateTimeEntry
          currentDateTime={dateTime}
          onSubmit={onDateTimeSelect}
        />
      );
      break;
    case UploadPage.Description:
      const onDescriptionInput = (description: string) => {
        setDescription(description);
        setNewPage(
          !editingInput ? UploadPage.WaterwayName : UploadPage.Summary
        );
      };
      activePage = (
        <IncidentDescription
          description={description}
          onSubmit={onDescriptionInput}
        />
      );
      break;
    case UploadPage.WaterwayName:
      const onWaterwayInput = (waterway: string) => {
        setWaterwayName(waterway);
        setNewPage(UploadPage.Summary);
      };
      activePage = (
        <IncidentWaterwayNameEntry
          waterwayName={waterwayName}
          onSubmit={onWaterwayInput}
        />
      );
      break;
    case UploadPage.Summary:
      activePage = (
        <UploadSummary
          onSubmit={onSubmitReading}
          onEdit={onEditReading}
          incident={{
            userId,
            photo,
            dateTime,
            location,
            description,
            waterwayName
          }}
          uploadError={uploadError}
          isUploading={isUploading}
        />
      );
      break;
  }

  const backAction = () => {
    if (currentPageView === UploadPage.PhotoUpload) {
      history.goBack();
      return;
    }

    // Pop previous pages stack
    setCurrentPageView(previousPages[previousPages.length - 1]);
    setPreviousPages(previousPages.slice(0, -1));
  };

  return (
    <PageWrapper
      label={`Uploading water pollution`}
      navigationHandler={{ handleBack: backAction }}
    >
      <div className={classes.wrapper}>
        {activePage}
        {uploadHandle !== undefined && (
          <IncidentUploadDialog
            uploadHandle={uploadHandle}
            closeModal={onCancelUpload}
            onSuccessfulContinue={onFinishUpload}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default IncidentUpload;
