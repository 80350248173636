import { createRef, useState } from "react";

import { Capacitor } from "@capacitor/core";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import { DesktopPhotoFallback } from "components/common/DesktopPhotoFallback";

import { useGPSLocation } from "providers/LocationProvider";

import AddPhotoDialog from "pages/photo/components/AddPhotoDialog";
import loadPhoto from "pages/photo/pages/CategorisePhotoPage/utils";

import IncidentExampleDialog from "../IncidentExampleDialog";

import {
  CordovaCameraImage,
  isCordovaCameraImageFile
} from "../../photo/state/types";

import { ImageMetaData, photoIsMetaData } from "../../../types/Photo";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  button: {
    marginBottom: `${theme.spacing(2)}px`
  },
  testPhotoDescription: {
    marginBottom: "10px"
  },
  testPhotoWrapper: {
    width: "100%",
    textAlign: "center",
    padding: `${theme.spacing(2)}px 0px`
  },
  testPhotoPreview: {
    maxWidth: "100%",
    maxHeight: "180px"
  },
  linkText: {
    cursor: "pointer",
    textDecoration: "underline"
  }
}));

type Props = {
  currentPhoto: ImageMetaData | string | undefined;
  onSubmit: (image: ImageMetaData | string | undefined) => void;
};

const IncidentPhotoUpload = ({ currentPhoto, onSubmit }: Props) => {
  const classes = useStyles();
  const gpsLocation = useGPSLocation();

  const [testPhoto, setTestPhoto] = useState<
    ImageMetaData | string | undefined
  >(currentPhoto);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [openExampleDialog, setOpenExampleDialog] = useState(false);

  // Magic for handling photo uploads.
  const desktopPhotoRef = createRef<HTMLInputElement>();
  const handlePhotoSelect = (
    image: File | CordovaCameraImage,
    fromCamera: boolean
  ) => {
    const fileState = isCordovaCameraImageFile(image)
      ? {
          fileOrFileName: Capacitor.convertFileSrc(image.filename),
          cordovaMetadata: JSON.parse(image.json_metadata),
          fromCamera: fromCamera
        }
      : { fileOrFileName: image, fromCamera: fromCamera };
    loadPhoto({
      ...fileState,
      fromCamera,
      gpsLocation,
      callback: setTestPhoto
    });

    if (Capacitor.platform !== "web") {
      setOpenPhotoDialog(false);
    }
  };

  return (
    <>
      <div className={classes.text}>
        Make sure the whole pollution incident is clearly visible.
      </div>
      <div className={classes.text}>
        See an example{" "}
        <span
          className={classes.linkText}
          onClick={() => setOpenExampleDialog(true)}
        >
          here
        </span>
        .
      </div>
      {testPhoto && (
        <div className={classes.testPhotoWrapper}>
          <img
            src={
              testPhoto &&
              (photoIsMetaData(testPhoto) ? testPhoto.imgSrc : testPhoto)
            }
            className={classes.testPhotoPreview}
            alt={""}
          />
        </div>
      )}
      <Button
        className={classes.button}
        onClick={() =>
          !!window.cordova
            ? setOpenPhotoDialog(true)
            : desktopPhotoRef.current && desktopPhotoRef.current.click()
        }
        color={testPhoto === undefined ? "primary" : "secondary"}
        variant="contained"
        disableElevation
        size="large"
      >
        {testPhoto === undefined ? "Upload or take a photo" : "Change photo"}
      </Button>
      <DesktopPhotoFallback
        ref={desktopPhotoRef}
        handlePhotoSelect={handlePhotoSelect}
      />
      {openPhotoDialog && (
        <AddPhotoDialog
          onClose={() => setOpenPhotoDialog(false)}
          handlePhotoSelect={handlePhotoSelect}
        />
      )}
      {testPhoto && (
        <Button
          className={classes.button}
          onClick={() => onSubmit(testPhoto)}
          color="primary"
          variant="contained"
          size="large"
          disableElevation
        >
          Continue
        </Button>
      )}
      {openExampleDialog && (
        <IncidentExampleDialog
          open={openExampleDialog}
          onClose={() => setOpenExampleDialog(false)}
        />
      )}
    </>
  );
};

export default IncidentPhotoUpload;
