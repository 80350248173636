import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import styles from "../../../../standard.module.scss";
import { PLANET_PATROL_TEST_KIT_NAME } from "../ReadingUpload";
import { getExampleTestKit } from "../../../../types/Readings/Shared";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  heading: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(2)}px`
  },
  radioButtonSection: {
    fontSize: "1em",
    display: "flex",
    marginBottom: `${theme.spacing(2)}px`
  },
  radioButton: {
    flex: "0 0 auto",
    height: `${theme.spacing(2)}px`,
    width: `${theme.spacing(2)}px`,
    marginTop: `${theme.spacing(0.5)}px`,
    marginRight: `${theme.spacing(2)}px`
  },
  radioButtonText: {
    marginTop: `${theme.spacing(0.3)}px`,
    flexGrow: 1
  },
  customTestKitSection: {},
  customTestKitInput: {
    border: "none",
    borderRadius: "5px",
    padding: `${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
    background: styles.lightGrey,
    fontSize: 16,
    boxSizing: "border-box",
    width: "100%",
    textOverflow: "ellipsis",
    "&:focus": {
      outline: "none"
    }
  },
  button: {}
}));

type Props = {
  readingType: string;
  testKitName: string;
  onSubmit: (testKitName: string) => void;
};

const ReadingTestKitEntry = ({ readingType, testKitName, onSubmit }: Props) => {
  const classes = useStyles();

  const [usingPlanetPatrolTestKit, setUsingPlanetPatrolTestKit] = useState(
    testKitName === PLANET_PATROL_TEST_KIT_NAME
  );
  const [customTestKitName, setCustomTestKitName] = useState(
    testKitName !== PLANET_PATROL_TEST_KIT_NAME ? testKitName : ""
  );

  const onPressContinue = () => {
    const testKitName = usingPlanetPatrolTestKit
      ? PLANET_PATROL_TEST_KIT_NAME
      : customTestKitName;
    onSubmit(testKitName);
  };

  return (
    <>
      <div className={classes.heading}>What test kit did you use?</div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setUsingPlanetPatrolTestKit(true)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={usingPlanetPatrolTestKit}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>
          I used a test kit I received from Planet Patrol.
        </div>
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setUsingPlanetPatrolTestKit(false)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={!usingPlanetPatrolTestKit}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>
          I used a test kit I acquired myself.
          {!usingPlanetPatrolTestKit && (
            <div className={classes.customTestKitSection}>
              <input
                className={classes.customTestKitInput}
                value={customTestKitName}
                placeholder={`e.g. ${getExampleTestKit(readingType)}`}
                onChange={(e) => setCustomTestKitName(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      <Button
        className={classes.button}
        onClick={onPressContinue}
        disabled={!usingPlanetPatrolTestKit && customTestKitName === ""}
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

export default ReadingTestKitEntry;
