import {
  getReadingMapDataEntries,
  MapReadingData,
  Reading,
  ReadingFormData
} from "./Base";

export const PH_READING_TYPE = "ph";
export const PH_VIDEO_ID = "Kruc35UlhZg";

export type PhInputs = {
  ph: number;
};

export type PhFormData = ReadingFormData & PhInputs;
export type Ph = Reading & PhInputs;

export const PhReadingMapLabel = "pH reading";
export const phToMapData = (ph: Ph): MapReadingData => {
  return {
    ...ph,
    data: new Map<string, string>([
      ...getReadingMapDataEntries(ph),
      [PhReadingMapLabel, ph.ph.toString()]
    ])
  };
};
