import firebase from "firebase/compat";

import { LatLong } from "./GPSLocation";
import { ImageMetaData } from "./Photo";

export type Survey = {
  id: string;
  name: string;
  description: string;
  fromDate: string;
  toDate: string;
  onlineUrl: string;
  completed: number;
  sections: Section[];
};

export type Section = {
  id: string;
  name: string;
  title: string;
  subtitle: string;
  description: string;
  questions: Question[];
};

export type Question = {
  id: string;
  name: string;
  label: string;
  description: string;
  type: QuestionType;
  required: boolean;
  default: boolean | string | number | string[] | undefined;
  options: Option[];
};

export type Option = {
  id: string;
  name: string;
  description: string;
  value: boolean | string | number | string[] | undefined;
  images: string[];
};

export enum QuestionType {
  radio = "radio",
  checkbox = "checkbox",
  text = "text",
  number = "number",
  imageselect = "imageselect",
  range = "range",
  photo = "photo",
  multiplephotos = "multiplephotos",
  location = "location",
  timestamp = "timestamp",
}

export type Answer = {
  questionId: string;
  value:
    | boolean
    | string
    | number
    | string[]
    | LatLong
    | ImageMetaData
    | undefined;
  valid: boolean;
};

export type SectionAnswers = {
  sectionId: string;
  answers: Answer[];
  valid: boolean;
};

export type SurveyResponse = {
  userId: string;
  surveyId: string;
  dateTime: number;
  [key: string]:
    | boolean
    | string
    | number
    | string[]
    | LatLong
    | ImageMetaData
    | undefined;
};

export type SurveyUploadHandle = {
  firestoreId: string;
  uploadTasks: firebase.storage.UploadTask[];
};
