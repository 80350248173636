import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import ReadingInstructions from "./ReadingInstructions";

type Props = {
  readingName: string;
  showInstructionsDialog: boolean;
  setShowInstructionsDialog: (show: boolean) => void;
};

const ReadingInstructionsDialog = ({
  readingName,
  showInstructionsDialog,
  setShowInstructionsDialog
}: Props) => {
  return (
    <>
      <Dialog className="map-filters-dialog" open={showInstructionsDialog}>
        <DialogContent>
          <ReadingInstructions readingName={readingName} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowInstructionsDialog(false)}
            color="primary"
            disableElevation
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReadingInstructionsDialog;
