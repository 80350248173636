import {
  getReadingMapDataEntries,
  MapReadingData,
  Reading,
  ReadingFormData
} from "./Base";

export const NITRATE_READING_TYPE = "nitrate";
export const NITRATE_PLANET_PATROL_UNITS = "mg/l";
export const NITRATE_VIDEO_ID = "_pvzdo87y1M";

export type NitrateInputs = {
  nitrateReading?: number;
  nitrateUnits?: string;
  nitriteReading?: number;
  nitriteUnits?: string;
};

export type NitrateFormData = ReadingFormData & NitrateInputs;

export type Nitrate = Reading & NitrateInputs;

export const NitrateReadingMapLabel = "Nitrate reading";
export const nitrateToMapData = (nitrate: Nitrate): MapReadingData => {
  let entries: [string, string][] = [];
  if (nitrate?.nitrateReading !== undefined) {
    entries.push([
      NitrateReadingMapLabel,
      nitrate?.nitrateReading?.toString() + nitrate?.nitrateUnits
    ]);
  }
  if (nitrate?.nitriteReading !== undefined) {
    entries.push([
      "Nitrite reading",
      nitrate?.nitriteReading?.toString() + nitrate?.nitriteUnits
    ]);
  }

  return {
    ...nitrate,
    data: new Map<string, string>([
      ...getReadingMapDataEntries(nitrate),
      ...entries
    ])
  };
};
