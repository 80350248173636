//to get round window.cordova being undefined
declare var window: any;

export const device = () => {
  const agent = navigator.userAgent;
  if (agent.match(/Android/i)) {
    return "Android";
  } else if (agent.match(/BlackBerry/i)) {
    return "BlackBerry";
  } else if (agent.match(/iPhone|iPad|iPod/i)) {
    return "iOS";
  } else if (agent.match(/Opera Mini/i)) {
    return "Opera Mini";
  } else if (agent.match(/IEMobile/i)) {
    return "IEMobile";
  } else {
    return "Web";
  }
};

export const isIphoneAndCordova = !!(device() === "iOS" && window.cordova);

// Add any iphones with notches as an object and to the array below
export const isIphoneWithNotchAndCordova = () => {
  const IPHONE_X = {
    width: 1125,
    height: 2436
  };

  const IPHONE_XR = {
    width: 828,
    height: 1792
  };

  const IPHONE_XSMAX = {
    width: 1242,
    height: 2688
  };

  const IPHONE_12_13_14 = {
    width: 1170,
    height: 2532
  };

  const IPHONE_12_13_14_MINI = {
    width: 1080,
    height: 2340
  };

  const IPHONE_12_13_PRO = {
    width: 1170,
    height: 2532
  };

  const IPHONE_12_13_PROMAX = {
    width: 1284,
    height: 2778
  };

  const IPHONE_14_PRO = {
    width: 1179,
    height: 2556
  };

  const IPHONE_14_PRO_MAX = {
    width: 1290,
    height: 2796
  };

  const iphonesWithNotchArr = [
    IPHONE_X,
    IPHONE_XR,
    IPHONE_XSMAX,
    IPHONE_12_13_14,
    IPHONE_12_13_14_MINI,
    IPHONE_12_13_PRO,
    IPHONE_12_13_PROMAX,
    IPHONE_14_PRO,
    IPHONE_14_PRO_MAX
  ];

  // Really basic check for the ios platform
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Get the device pixel ratio
  const ratio = window.devicePixelRatio || 1;

  // Define the users device screen dimensions
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio
  };

  // check if device is iOS and cordova exists
  const cordovaIOS = !!(iOS && window.cordova);

  // Go through each iphone in the array and check if the screen dimensions match
  for (let i = 0; i < iphonesWithNotchArr.length; i++) {
    if (
      cordovaIOS &&
      // Portrait
      ((iphonesWithNotchArr[i].width === screen.width &&
        iphonesWithNotchArr[i].height === screen.height) ||
        // Landscape
        (iphonesWithNotchArr[i].width === screen.height &&
          iphonesWithNotchArr[i].height === screen.width))
    ) {
      return true;
    }
  }
  return false;
};

export function getValueFromTree(tree: Object, value: string) {
  let foundedNode;

  function searchTree(tree: Object, key_to_find: string) {
    Object.entries(tree).forEach(([key, value]) => {
      if (key_to_find === key) {
        foundedNode = value.label;
      }
      if (value.children) {
        searchTree(value.children, key_to_find);
      }
    });
  }

  searchTree(tree, value);
  return foundedNode;
}

export function getValueAndAncestorsFromTree(
  tree: Object,
  key_to_find: string
) {
  const stack: string[] = [];
  let listWithNodes: string[] = [];

  function findPathOfFoundedNode(tree: Object, key_to_find: string) {
    Object.entries(tree).forEach(([key, value]) => {
      if (key_to_find === key) {
        const foundedNode = value.label;
        listWithNodes = [...stack, foundedNode];
      }
      if (value.children) {
        stack.push(value.label);
        findPathOfFoundedNode(value.children, key_to_find);
        stack.pop();
      }
    });
  }
  findPathOfFoundedNode(tree, key_to_find);
  return listWithNodes;
}

export function sortArrayByObjectKey<T extends Object, K extends keyof T>(
  array: T[],
  keyName: K
) {
  return array.sort((a, b) => {
    if (a[keyName] < b[keyName]) {
      return -1;
    }
    if (a[keyName] > b[keyName]) {
      return 1;
    }
    return 0;
  });
}

export const forceNumericInput = (
  input: string,
  setter: (value: number) => void
) => {
  if (!isNaN(+input)) {
    setter(+input);
  }
};

export const getLocalDateTimeString = (date: Date) => {
  if (date instanceof Date && !isNaN(date.getTime())) {
    // Handle one hour difference from datetime-local input
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().slice(0, 16);
  } else {
    // Handle invalid date by setting current datetime
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0, 16);
  }
};

export const getStoreRatingURL = () => {
  if (device() === "iOS") {
    return "https://apps.apple.com/gb/app/plastic-patrol/id1235072883";
  } else {
    return "https://play.google.com/store/apps/details?id=uk.co.plasticpatrol";
  }
};
