import * as turf from "@turf/turf";

import Feature from "types/Feature";

export const clusterFeatures = (features: Feature[]) => {
  // convert our features to a turf feature collection
  const turfFeatures = features.map((feature) => {
    return turf.point(
      [feature.geometry.coordinates[0], feature.geometry.coordinates[1]],
      feature
    );
  });

  const turfFeatureCollection = turf.featureCollection(turfFeatures);

  // Using turf clustersDbscan get the number of clusters using 10 mile clusters
  const clusters = turf.clustersDbscan(turfFeatureCollection, 10, {
    units: "miles",
    minPoints: 1
  });

  return clusters;
};

export const getClusterCount = (features: Feature[]) => {
  const clusters = clusterFeatures(features);
  const clusterIds = clusters.features.map(
    (feature) => feature.properties.cluster || 0
  );
  // get unique cluster ids without using Set
  const uniqueClusterIds = clusterIds.filter((v, i, a) => a.indexOf(v) === i);
  return uniqueClusterIds.length;
};

export const getMaximumClusterSize = (features: Feature[]) => {
  const clusters = clusterFeatures(features);
  const clusterIds = clusters.features.map(
    (feature) => feature.properties.cluster || 0
  );
  const uniqueClusterIds = clusterIds.filter((v, i, a) => a.indexOf(v) === i);
  const clusterSizes = uniqueClusterIds.map(
    (clusterId) => clusterIds.filter((id) => id === clusterId).length
  );
  return Math.max(...clusterSizes);
};
