import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend
} from "chart.js";

import { Line } from "react-chartjs-2";

import { useReadings } from "../../../../providers/ReadingsProvider";
import { getReadingGraphData } from "utils/waterReadingAnalytics";

import { AnyReadingFormData } from "types/Readings/Shared";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Legend);

type Props = {
  reading: AnyReadingFormData | null;
  readingType: string | null;
  postcode: string | undefined | null;
};

const ReadingValuesChart = ({ reading, readingType, postcode }: Props) => {
  const readings = useReadings();
  const chartData = getReadingGraphData(reading, readings, readingType || "", postcode || "");
  return <Line options={chartData.chartOptions} data={chartData.chartData} />;
};

export default ReadingValuesChart;
