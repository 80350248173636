import {
  LocalNotificationDescriptor,
  LocalNotifications,
  LocalNotificationSchema,
  PendingLocalNotificationSchema
} from "@capacitor/local-notifications";

export const scheduleNotifications = async (
  notifications: LocalNotificationSchema[]
) => {
  await LocalNotifications.schedule({ notifications });
  return;
};

export const getPendingNotifications = async (): Promise<
  PendingLocalNotificationSchema[]
> => {
  const pending = await LocalNotifications.getPending();
  return pending.notifications;
};

export const syncNotifications = async (
  notifications: LocalNotificationSchema[]
) => {
  const pending = await getPendingNotifications();
  const pendingTitles = pending.map((n) => n.title);
  const notificationTitles = notifications.map((n) => n.title);

  const notificationsToSchedule = notifications.filter(
    (n) => !pendingTitles.includes(n.title)
  );
  const notificationsToCancel = pending.filter(
    (n) => !notificationTitles.includes(n.title)
  );

  const cancelIds: LocalNotificationDescriptor[] = notificationsToCancel.map(
    (n) => ({ id: n.id })
  );

  if (cancelIds.length > 0) {
    await LocalNotifications.cancel({ notifications: cancelIds });
  }

  if (notificationsToSchedule.length > 0) {
    let permissionState = (await LocalNotifications.checkPermissions()).display;
    if (permissionState !== "granted") {
      permissionState = (await LocalNotifications.requestPermissions()).display;
    }

    if (permissionState === "granted") {
      await scheduleNotifications(notificationsToSchedule);
    }
  }

  return;
};

export const getNextNotification = (notification: LocalNotificationSchema) => {
  const now = new Date();
  const scheduleDate = notification?.schedule?.at;

  if (!scheduleDate || !scheduleDate.getTime) return now;

  while (scheduleDate.getTime() < now.getTime()) {
    if (notification?.schedule?.every === "day") {
      scheduleDate.setDate(scheduleDate.getDate() + 1);
    } else if (notification?.schedule?.every === "week") {
      scheduleDate.setDate(scheduleDate.getDate() + 7);
    } else if (notification?.schedule?.every === "two-weeks") {
      scheduleDate.setMonth(scheduleDate.getDate() + 14);
    } else if (notification?.schedule?.every === "month") {
      scheduleDate.setMonth(scheduleDate.getMonth() + 1);
    } else if (notification?.schedule?.every === "hour") {
      scheduleDate.setHours(scheduleDate.getHours() + 1);
    }
  }

  return scheduleDate;
};

export const getDaysUntilNextNotification = (
  notification: LocalNotificationSchema
) => {
  const scheduleDate = getNextNotification(notification);
  const now = new Date();
  const diff = scheduleDate.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 3600 * 24));
  return days;
};
