import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import ReadingInstructions from "./ReadingInstructions";

const useStyles = makeStyles((theme) => ({
  startUploadButton: {
    marginTop: `${theme.spacing(2)}px`
  }
}));

type Props = {
  readingName: string;
  onContinue: () => void;
};

const ReadingUploadIntro = ({ readingName, onContinue }: Props) => {
  const classes = useStyles();

  return (
    <>
      <ReadingInstructions readingName={readingName} />
      <Button
        className={classes.startUploadButton}
        onClick={onContinue}
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        Start
      </Button>
    </>
  );
};

export default ReadingUploadIntro;
