import firebase from "firebase/compat";

import {
  COLIFORMS_READING_TYPE,
  COLIFORMS_VIDEO_ID,
  ColiformsFormData,
  ColiformsInputs
} from "./Coliforms";
import { PH_READING_TYPE, PH_VIDEO_ID, PhFormData, PhInputs } from "./Ph";
import {
  TEMPERATURE_READING_TYPE,
  TemperatureFormData,
  TemperatureInputs
} from "./Temperature";
import {
  NITRATE_READING_TYPE,
  NITRATE_VIDEO_ID,
  NitrateFormData,
  NitrateInputs
} from "./Nitrate";
import {
  PHOSPHATE_READING_TYPE,
  PHOSPHATE_VIDEO_ID,
  PhosphateFormData,
  PhosphateInputs
} from "./Phosphate";

export const ALL_READING_TYPES = [
  NITRATE_READING_TYPE,
  PHOSPHATE_READING_TYPE,
  PH_READING_TYPE,
  TEMPERATURE_READING_TYPE,
  COLIFORMS_READING_TYPE
];

export const CONTACT_WEB_LINK = "https://planetpatrol.co/contact-us/";
export const WHAT_LIES_BENEATH_WEB_LINK =
  "https://planetpatrol.co/what-lies-beneath/";
export const INTERPRET_RESULTS_WEB_LINK =
  "https://linktr.ee/planetpatroltutorialvideos";

const KYORITSU_TEST_KIT = "Kyoritsu PackTest";
const CBT_TEST_KIT = "CBT E.Coli Water Quality Test Kit";
const CAMLAB_PH_TEST_KIT = "Camlab pH indicator stick";

export const READINGS_STORAGE_FOLDER = "readings";
export const READING_PHOTO_UPLOAD_NAME = "original.jpg";

export const PLANET_PATROL_READINGS_KIT_NAME = "Planet Patrol";

export type ReadingUploadHandle = {
  firestoreId: string;
  uploadTasks: firebase.storage.UploadTask[];
};

export type ReadingSpecificFields =
  | NitrateInputs
  | PhosphateInputs
  | ColiformsInputs
  | PhInputs
  | TemperatureInputs;

export type ReadingSpecificInput = {
  readingType: string;
  data?: ReadingSpecificFields;
};

export type AnyReadingFormData =
  | NitrateFormData
  | PhosphateFormData
  | ColiformsFormData
  | PhFormData
  | TemperatureFormData;

export const getFullReadingName = (readingType: string) => {
  if (readingType === COLIFORMS_READING_TYPE) {
    return "total coliforms";
  } else if (readingType === NITRATE_READING_TYPE) {
    return "nitrate and nitrite";
  } else if (readingType === PH_READING_TYPE) {
    return "pH";
  } else if (readingType === TEMPERATURE_READING_TYPE) {
    return "temperature";
  }
  return readingType;
};

export const getExampleTestKit = (readingType: string) => {
  switch (readingType) {
    case NITRATE_READING_TYPE:
      return KYORITSU_TEST_KIT;
    case PHOSPHATE_READING_TYPE:
      return KYORITSU_TEST_KIT;
    case COLIFORMS_READING_TYPE:
      return CBT_TEST_KIT;
    case PH_READING_TYPE:
      return CAMLAB_PH_TEST_KIT;
    default:
      return KYORITSU_TEST_KIT;
  }
};

export const getEmbeddedVideoId = (readingType: string) => {
  const videoUrlPrefix = "https://www.youtube.com/embed/";
  const videoUrlSuffix = "?autohide=1&playsinline=1&showinfo=0";

  let videoId = "";
  switch (readingType) {
    case NITRATE_READING_TYPE:
      videoId += NITRATE_VIDEO_ID;
      break;
    case PHOSPHATE_READING_TYPE:
      videoId += PHOSPHATE_VIDEO_ID;
      break;
    case COLIFORMS_READING_TYPE:
      videoId += COLIFORMS_VIDEO_ID;
      break;
    case PH_READING_TYPE:
      videoId += PH_VIDEO_ID;
      break;
    default:
      console.error(
        `Tried to get video ID for unknown reading type: ${readingType}`
      );
      return "";
  }

  return videoUrlPrefix + videoId + videoUrlSuffix;
};
