import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import _ from "lodash";

import mapboxgl from "mapbox-gl/dist/mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";

import GpsFixed from "@material-ui/icons/GpsFixedRounded";
import GpsOff from "@material-ui/icons/GpsOffRounded";

import { withStyles } from "@material-ui/core/styles";

import { gtagEvent } from "../../gtag.js";
import { isIphoneWithNotchAndCordova } from "../../utils";

import MapLocation from "../../utils/MapLocation";

import "./Map.scss";

import SurveyAlerts from "./../SurveyAlerts";

const styles = theme => ({
  location: {
    boxShadow: "none",
    position: "absolute",
    backgroundColor: "white",
    top: isIphoneWithNotchAndCordova()
      ? `calc(env(safe-area-inset-top) + ${theme.spacing(2)}px)`
      : theme.spacing(2),
    right: theme.spacing(2)
  },
  mapIconFilters: {
    position: "absolute",
    top: isIphoneWithNotchAndCordova()
      ? `calc(env(safe-area-inset-top) + ${theme.spacing(2)}px)`
      : theme.spacing(2),
    left: theme.spacing(2)
  }
});

const UPDATE_URL_COORDS_DELAY = 1000;

class Map extends Component {
  constructor (props) {
    super(props);
    this.map = {
      getCenter: () => ({ lat: 0, lng: 0 }),
      getZoom: () => 0,
      loaded: () => false,
      flyTo: () => false
    };
    this.renderedThumbnails = {};
    this.navControl = null;
    this.updatingCoordinates = {};
    this.state = {
      filterOption: "all",
      displayTypes: ["litter", "water"]
    };
  }

  async componentDidMount () {
    mapboxgl.accessToken =
      process.env.REACT_APP_USE_PROD_DATA === "true"
        ? this.props.config.MAPBOX_TOKEN
        : this.props.config.MAPBOX_DEV_TOKEN;

    const mapLocation = this.props.mapLocation;
    const zoom = mapLocation.zoom;
    const center = mapLocation.getCenter();

    this.map = new mapboxgl.Map({
      container: "map", // container id
      style: this.props.config.MAP_SOURCE,
      center: center, // starting position [lng, lat]
      zoom: zoom, // starting zoom
      attributionControl: false
    });

    const navigationControlOptions = {
      showCompass: false
    };
    this.map.addControl(
      new mapboxgl.NavigationControl(navigationControlOptions),
      "bottom-right"
    );

    this.map.addControl(
      new mapboxgl.AttributionControl({
        compact: true,
        customAttribution: this.props.config.MAP_ATTRIBUTION
      }),
      "bottom-left"
    );

    this.map.on("load", () => {
      this.addFeaturesToMap();
    });

    this.map.on("moveend", e => {
      this.callHandlerCoordinates();
    });

    this.map.on("render", e => {
      if (this.map.getLayer("unclustered-point")) {
        const features = this.map.queryRenderedFeatures({
          layers: ["unclustered-point"]
        });
        this.updateRenderedThumbails(features);
      }
    });

    this.map.on("mouseenter", "clusters", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    this.map.on("mouseleave", "clusters", () => {
      this.map.getCanvas().style.cursor = "";
    });

    this.map.on("click", "clusters", e => {
      gtagEvent("Cluster Clicked", "Map");
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["clusters"]
      });
      const clusterId = features[0].properties.cluster_id;
      this.map
        .getSource("data")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          } else {
            this.flyTo(
              new MapLocation(
                features[0].geometry.coordinates[1],
                features[0].geometry.coordinates[0],
                zoom
              )
            );
          }
        });
    });
  }

  flyTo = mapLocation => {
    this.map.flyTo({
      center: [mapLocation.longitude, mapLocation.latitude],
      zoom: mapLocation.zoom
    });
  };

  calcMapLocation = () =>
    new MapLocation(
      this.map.getCenter().lat,
      this.map.getCenter().lng,
      this.map.getZoom()
    );

  componentDidUpdate (prevProps) {
    // If the geofeatures have changed
    if (
      this.map.loaded() &&
      !_.isEqual(this.props.geojson, prevProps.geojson)
    ) {
      this.addFeaturesToMap();
    }

    // Ignore from now if map is not visible
    if (!this.props.visible) return;

    const mapLocation = this.props.mapLocation;
    const prevMapLocation = prevProps.mapLocation;

    if (mapLocation && !mapLocation.isEqual(prevMapLocation)) {
      this.flyTo(mapLocation);
    }

    if (this.props.embeddable !== prevProps.embeddable) {
      if (this.props.embeddable) {
        this.map.addControl(this.navControl, "top-left");
      } else {
        this.map.removeControl(this.navControl);
      }
    }
  }

  filterData = displayTypes => {
    let geojson = JSON.parse(JSON.stringify(this.props.geojson));
    if (geojson && geojson.features) {
      geojson.features = geojson.features.map(feature => {
        feature.properties.type = feature.properties.readingType
          ? "water"
          : "litter";
        return feature;
      });
      geojson.features = geojson.features.filter(
        feature => displayTypes.indexOf(feature.properties.type) !== -1
      );
    }

    return geojson;
  };

  addFeaturesToMap = () => {
    const geojson = this.filterData(this.state.displayTypes);

    if (this.map.getLayer("clusters")) {
      this.map.removeLayer("clusters");
    }
    if (this.map.getLayer("cluster-count")) {
      this.map.removeLayer("cluster-count");
    }
    if (this.map.getLayer("unclustered-point")) {
      this.map.removeLayer("unclustered-point");
    }
    if (this.map.getSource("data")) {
      this.map.removeSource("data");
    }

    this.map.addSource("data", {
      type: "geojson",
      data: geojson,
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 48 // Radius of each cluster when clustering points (defaults to 50)
    });

    this.map.addLayer({
      id: "clusters",
      type: "circle",
      source: "data",
      filter: ["has", "point_count"],
      paint: {
        // Use step expressions (https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with six steps to implement six types of circles:
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#89b685",
          50,
          "#E8DB52",
          100,
          "#FEB460",
          300,
          "#FF928B",
          1000,
          "#E084B4",
          5000,
          "#8097BF"
        ],
        "circle-radius": [
          "step",
          ["get", "point_count"],
          17,
          50,
          18,
          100,
          19,
          300,
          20,
          1000,
          21,
          5000,
          22
        ]
      }
    });

    this.map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "data",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["Source Sans Pro Bold"],
        "text-size": 15
      }
    });

    this.map.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "data",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-radius": 0
      }
    });
  };

  callLocationChangeHandler = () => {
    clearTimeout(this.updatingCoordinates);
    delete this.updatingCoordinates;

    this.props.handleMapLocationChange(this.calcMapLocation());
  };

  callHandlerCoordinates = () => {
    clearTimeout(this.updatingCoordinates);

    this.updatingCoordinates = setTimeout(() => {
      this.callLocationChangeHandler();
    }, UPDATE_URL_COORDS_DELAY);
  };

  updateRenderedThumbails = visibleFeatures => {
    _.forEach(this.renderedThumbnails, (thumbnailUrl, id) => {
      const exists = !!_.find(
        visibleFeatures,
        feature => feature.properties.id === id
      );
      // if it !exist => remove marker object - delete key from dictionary
      if (!exists) {
        this.renderedThumbnails[id].remove();
        delete this.renderedThumbnails[id];
      }
    });

    visibleFeatures.forEach(feature => {
      if (!this.renderedThumbnails[feature.properties.id]) {
        //create a div element - give attributes
        const el = document.createElement("div");
        el.className = "marker marker-" + feature.properties.type;
        el.id = feature.properties.id;
        el.style.backgroundImage = `url(${feature.properties.thumbnail}), url(${"data:,"}) `;
        el.addEventListener("click", () => {
          gtagEvent("Photo Clicked", "Map", feature.properties.id);
          // this.callLocationChangeHandler();
          this.props.handleFeatureClick(feature);
        });
        //create marker
        const marker = new mapboxgl.Marker(el)
          .setLngLat(feature.geometry.coordinates)
          .addTo(this.map);
        //save the marker object to the renderedThumbnails dictionary
        this.renderedThumbnails[feature.properties.id] = marker;
      }
    });
  };

  componentWillUnmount () {
    if (this.map.remove) {
      this.map.remove();
    }
  }

  handlerLocation () {
    clearTimeout(this.updatingCoordinates);
    delete this.updatingCoordinates;
    this.props.handleLocationClick();
  }

  updateFilter = filter => {
    let newDisplayTypes = this.state.displayTypes;
    this.setState({ filterOption: filter });
    if (filter === "all") {
      newDisplayTypes = ["water", "litter"];
    } else {
      newDisplayTypes = [filter];
    }

    this.setState({ displayTypes: newDisplayTypes });
    const geojson = this.filterData(newDisplayTypes);
    this.map.getSource("data").setData(geojson);
  };

  render () {
    const { gpsOffline, gpsDisabled, classes } = this.props;

    return (
      <div
        className={"geovation-map"}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0
        }}
      >
        <SurveyAlerts />
        <div id='map' className='map'></div>

        <div className={classes.mapIconFilters}>
          <Chip
            className={
              this.state.filterOption === "all"
                ? "filter-all-selected"
                : "filter-all-unselected"
            }
            label={"All"}
            onClick={() => this.updateFilter("all")}
          />
          <Chip
            className={
              this.state.filterOption === "litter"
                ? "filter-litter-selected"
                : "filter-litter-unselected"
            }
            label={this.props.t("litter_text")}
            onClick={() => this.updateFilter("litter")}
          />
          <Chip
            className={
              this.state.filterOption === "water"
                ? "filter-water-selected"
                : "filter-water-unselected"
            }
            label={this.props.t("water_text")}
            onClick={() => this.updateFilter("water")}
          />
        </div>
        <Fab
          color='inherit'
          elevation={0}
          className={classes.location}
          size='medium'
          onClick={() => this.handlerLocation()}
          disabled={gpsDisabled}
          aria-label='Current location'
        >
          {gpsOffline ? <GpsOff /> : <GpsFixed />}
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(Map));
