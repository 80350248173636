import { useState } from "react";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import InfoIcon from "@material-ui/icons/Info";

import { makeStyles } from "@material-ui/core/styles";

import PageWrapper from "../../../components/PageWrapper";
import { useHistory, useParams } from "react-router-dom";
import ReadingUploadIntro from "./common/ReadingUploadIntro";

import ReadingPhotoUpload from "./common/ReadingPhotoUpload";
import ReadingDateTimeEntry from "./common/ReadingDateTimeEntry";
import ReadingWaterwayNameEntry from "./common/ReadingWaterwayNameEntry";
import ReadingTestKitEntry from "./common/ReadingTestKitEntry";
import ReadingGeoTag from "./common/ReadingGeoTag";
import ReadingExplicitDataEntry from "./common/ReadingExplicitDataEntry";
import UploadSummary from "./common/UploadSummary";
import ReadingUploadDialog from "../ReadingUploadDialog";
import { linkToMap } from "../../../custom/config";
import { uploadReading } from "../../../features/firebase/readings/api";

import firebase from "firebase/compat/app";
import {
  AnyReadingFormData,
  getFullReadingName,
  ReadingSpecificFields,
  ReadingSpecificInput,
  ReadingUploadHandle
} from "../../../types/Readings/Shared";
import { useUser } from "../../../providers/UserProvider";

import ReadingInstructionsDialog from "./common/ReadingInstructionsDialog";

import { INVALID_LAT_LON, LatLong } from "../../../types/GPSLocation";
import { ImageMetaData, photoIsMetaData } from "../../../types/Photo";
import { INVALID_USER_ID } from "../../../types/User";

const useStyles = makeStyles((theme) => ({
  instructionsButton: {
    marginBottom: `${theme.spacing(1)}px`
  },
  progress: {
    width: "100%",
    marginBottom: `${theme.spacing(2)}px`
  },
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: theme.spacing(2)
  }
}));

export enum UploadPage {
  Intro,
  PhotoUpload,
  PhotoGeotag,
  PhotoDateTime,
  WaterwayName,
  TestKit,
  Reading,
  Summary
}

export const PLANET_PATROL_TEST_KIT_NAME = "Planet Patrol";

const ReadingUpload = () => {
  const { readingType } = useParams<{ readingType: string }>();

  const classes = useStyles();
  const history = useHistory();
  const { user } = useUser();

  const userId = user?.id || INVALID_USER_ID;
  if (userId === INVALID_USER_ID) {
    console.error(`Unauthenticated user in readings flow. Upload will fail.`);
  }

  const [currentPageView, setCurrentPageView] = useState<UploadPage>(
    UploadPage.Intro
  );
  const [previousPages, setPreviousPages] = useState<UploadPage[]>([]);
  const [photo, setPhoto] = useState<ImageMetaData | string | undefined>();
  const [waterwayName, setWaterwayName] = useState("");
  const [location, setLocation] = useState(INVALID_LAT_LON);
  const [testKitName, setTestKitName] = useState(PLANET_PATROL_TEST_KIT_NAME);
  const [dateTime, setDateTime] = useState(Date.now());
  const [readingSpecificInput, setReadingSpecificInput] =
    useState<ReadingSpecificInput>({ readingType, data: undefined });
  const [editingInput, setEditingInput] = useState(false);
  const [uploadHandle, setUploadHandle] = useState<
    ReadingUploadHandle | undefined
  >();
  const [uploadError, setUploadError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [storedReadingData, setStoredReadingData] =
    useState<AnyReadingFormData | null>(null);

  const [showInstructions, setShowInstructions] = useState(false);

  const setNewPage = (newPage: UploadPage) => {
    setPreviousPages([...previousPages, currentPageView]);
    setCurrentPageView(newPage);
  };

  const onEditReading = (page: UploadPage) => {
    setEditingInput(true);
    setNewPage(page);
  };

  const getProgress = () => {
    switch (currentPageView) {
      case UploadPage.Intro:
        return 0;
      case UploadPage.PhotoUpload:
        return 10;
      case UploadPage.PhotoGeotag:
        return 20;
      case UploadPage.PhotoDateTime:
        return 30;
      case UploadPage.WaterwayName:
        return 40;
      case UploadPage.TestKit:
        return 50;
      case UploadPage.Reading:
        return 70;
      case UploadPage.Summary:
        return 90;
    }
  };

  const onSubmitReading = async () => {
    const inputs = readingSpecificInput?.data;
    if (inputs === undefined) {
      console.error(`Failed to submit readings. Reading data was undefined.`);
      return;
    }

    const readingData: AnyReadingFormData = {
      ...inputs,
      readingType,
      photo,
      location,
      testKitName,
      waterwayName,
      dateTime,
      userId
    };

    setStoredReadingData(readingData);

    setIsUploading(true);
    try {
      const result = await uploadReading(
        firebase.firestore(),
        firebase.storage(),
        readingData
      );
      setUploadHandle(result);
    } catch (err) {
      const errorMessage = `Error trying to upload reading. ${err}`;
      console.error(errorMessage);
      setUploadError(errorMessage);
    }
    setIsUploading(false);
  };

  const onCancelUpload = () => {
    setUploadHandle(undefined);
  };

  const onFinishUpload = () => {
    history.push(linkToMap());
  };

  let activePage = <div />;
  switch (currentPageView) {
    case UploadPage.Intro:
      activePage = (
        <ReadingUploadIntro
          readingName={readingType}
          onContinue={() => setNewPage(UploadPage.PhotoUpload)}
        />
      );
      break;
    case UploadPage.PhotoUpload:
      const onPhotoSelect = (photo: ImageMetaData | string | undefined) => {
        setPhoto(photo);

        // Check to see if photo had location data on it.
        let photoHadLocation = false;
        if (
          photo !== undefined &&
          photoIsMetaData(photo) &&
          photo.imgLocation !== null
        ) {
          photoHadLocation = true;
          setLocation(photo.imgLocation);
        }

        // Let user geotag photo if no location data was attached.
        setNewPage(
          editingInput
            ? UploadPage.Summary
            : !photoHadLocation
            ? UploadPage.PhotoGeotag
            : UploadPage.PhotoDateTime
        );
      };
      activePage = (
        <ReadingPhotoUpload currentPhoto={photo} onSubmit={onPhotoSelect} />
      );
      break;
    case UploadPage.PhotoGeotag:
      const onGeoTag = (location: LatLong) => {
        setLocation(location);
        setNewPage(UploadPage.PhotoDateTime);
      };
      activePage = (
        <ReadingGeoTag currentLocation={location} onSubmit={onGeoTag} />
      );
      break;
    case UploadPage.PhotoDateTime:
      const onDateTimeSelect = (dateTime: Date) => {
        setDateTime(dateTime.getTime());
        setNewPage(
          !editingInput ? UploadPage.WaterwayName : UploadPage.Summary
        );
      };
      activePage = (
        <ReadingDateTimeEntry
          currentDateTime={dateTime}
          onSubmit={onDateTimeSelect}
        />
      );
      break;
    case UploadPage.WaterwayName:
      const onWaterwayInput = (waterway: string) => {
        setWaterwayName(waterway);
        setNewPage(!editingInput ? UploadPage.TestKit : UploadPage.Summary);
      };
      activePage = (
        <ReadingWaterwayNameEntry
          waterwayName={waterwayName}
          onSubmit={onWaterwayInput}
        />
      );
      break;
    case UploadPage.TestKit:
      const onTestKitEntry = (testkit: string) => {
        setTestKitName(testkit);
        setNewPage(UploadPage.Reading);
      };
      activePage = (
        <ReadingTestKitEntry
          readingType={readingType}
          testKitName={testKitName}
          onSubmit={onTestKitEntry}
        />
      );
      break;
    case UploadPage.Reading:
      const onReadingData = (reading: ReadingSpecificInput) => {
        setReadingSpecificInput(reading);
        setNewPage(UploadPage.Summary);
      };
      activePage = (
        <ReadingExplicitDataEntry
          readingData={readingSpecificInput}
          usingPlanetPatrolKit={testKitName === PLANET_PATROL_TEST_KIT_NAME}
          onSubmit={onReadingData}
        />
      );
      break;
    case UploadPage.Summary:
      activePage = (
        <UploadSummary
          onSubmit={onSubmitReading}
          onEdit={onEditReading}
          reading={{
            ...(readingSpecificInput?.data as ReadingSpecificFields),
            readingType,
            userId,
            testKitName,
            photo,
            waterwayName,
            location,
            dateTime
          }}
          uploadError={uploadError}
          isUploading={isUploading}
        />
      );
      break;
  }

  const backAction = () => {
    if (currentPageView === UploadPage.Intro) {
      history.goBack();
      return;
    }

    // Pop previous pages stack
    setCurrentPageView(previousPages[previousPages.length - 1]);
    setPreviousPages(previousPages.slice(0, -1));
  };

  return (
    <PageWrapper
      label={`Uploading ${getFullReadingName(readingType)}`}
      navigationHandler={{ handleBack: backAction }}
    >
      <div className={classes.wrapper}>
        {currentPageView !== UploadPage.Intro && (
          <>
            <Button
              className={classes.instructionsButton}
              variant="outlined"
              onClick={() => setShowInstructions(true)}
              disableElevation
              startIcon={<InfoIcon />}
            >
              View instructions
            </Button>
            <div className={classes.progress}>
              <LinearProgress variant="determinate" value={getProgress()} />
            </div>
          </>
        )}

        {activePage}
        {uploadHandle !== undefined && (
          <ReadingUploadDialog
            readingData={storedReadingData}
            uploadHandle={uploadHandle}
            closeModal={onCancelUpload}
            user={user}
            onSuccessfulContinue={onFinishUpload}
          />
        )}
        <ReadingInstructionsDialog
          readingName={readingType}
          showInstructionsDialog={showInstructions}
          setShowInstructionsDialog={setShowInstructions}
        />
      </div>
    </PageWrapper>
  );
};

export default ReadingUpload;
