import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {gtagPageView} from "gtag";

import {dbFirebase} from "features/firebase";
import Feature from "types/Feature";

import useAsyncEffect from "hooks/useAsyncEffect";
import {extractPathnameParams} from "../utils/utils";
import firebase from 'firebase/compat/app'
import {readingToFeature} from "./ReadingsProvider";
import {getReading} from "../features/firebase/readings/utils";
import {ALL_READING_TYPES} from "../types/Readings/Shared";
import { getIncident } from "features/firebase/incidents/utils";
import { incidentToFeature } from "./IncidentsProvider";

const SelectedFeatureContext = React.createContext<Feature | undefined>(
    undefined
);

type Props = { children: React.ElementType };

export default function SelectedFeatureProvider({children}: Props) {
  const [selectedFeature, setSelectedFeature] = useState<Feature | undefined>();
  const location = useLocation();
  useEffect(() => {
    gtagPageView(location.pathname);
  }, [location]);
  const {featureType, featureId} = extractPathnameParams(location);
  useAsyncEffect(async () => {
    if (featureId !== undefined) {
      if (featureType === 'photos') {
        const photo = await dbFirebase.getPhotoByID(featureId);
        setSelectedFeature(photo);
      } else if (featureType === 'incidents') {
        const incident = await getIncident(firebase.firestore(), firebase.storage(), featureId);
        const feature = incidentToFeature(incident);
        setSelectedFeature(feature);
      } else if (featureType !== undefined && ALL_READING_TYPES.includes(featureType)) {
        const reading = await getReading(firebase.firestore(), firebase.storage(), featureType, featureId);
        const feature = readingToFeature(reading);
        setSelectedFeature(feature);
      } else {
        console.error(`Selected unknown map feature. Feature: ${featureType}`)
      }
    }
  }, [featureId]);

  return (
      <SelectedFeatureContext.Provider value={selectedFeature}>
        {children}
      </SelectedFeatureContext.Provider>
  );
}

export const useSelectedFeature = () => useContext(SelectedFeatureContext);
