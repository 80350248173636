import { Feature } from "types/Feature";

import { getLastSixMonths } from "./utils";
import { FeaturesProviderData } from "providers/FeaturesProvider";

import { getClusterCount, getMaximumClusterSize } from "./geoAnalytics";

export const getLitterDate = (litter: Feature) => {
  if (!litter?.properties) return 0;
  const litterData = litter?.properties as any;
  if (litterData && litterData.created) return litterData.created;
  return 0;
};

export const getLitterByLastSixMonths = (
  photos: FeaturesProviderData,
  userId: string | null = null
): any[] => {
  let months = [...getLastSixMonths()];
  const litters = photos?.features?.geojson?.features;
  if (litters && litters.length > 0) {
    months.forEach((month) => {
      month.picks = 0;
      month.pieces = 0;
      litters.forEach((litter) => {
        const litterDate = getLitterDate(litter);
        let litterUserId = "";
        if (litter.properties && (litter.properties as any).userId) {
          litterUserId = (litter.properties as any).userId;
        }
        if (litterDate !== 0 && (!userId || userId === litterUserId)) {
          if (
            litterDate.getFullYear() === month.year &&
            litterDate.getMonth() === month.monthNumber
          ) {
            let pieces = 1;
            if ((litter.properties as any).pieces) {
              pieces = (litter.properties as any).pieces;
            }
            month.picks++;
            month.pieces = month.pieces + pieces;
          }
        }
      });
    });
  }
  return months;
};

export const getLitterClusterCount = (litter: Feature[]) => {
  if (litter && litter.length > 0) {
    return getClusterCount(litter);
  }
  return 0;
};

export const getMaxLitterClusterSize = (litter: Feature[]) => {
  if (litter && litter.length > 0) {
    return getMaximumClusterSize(litter);
  }
  return 0;
};
