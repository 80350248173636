import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";

import exampleImage1 from "assets/images/incident/example-1.png";
import exampleImage2 from "assets/images/incident/example-2.png";

interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 8,
    top: 16
  },
  dialogTitle: {
    marginRight: "25px"
  },
  image: {
    marginBottom: `${theme.spacing(2)}px`,
    width: "100%"
  }
}));

const IncidentExampleDialog = ({ open, onClose }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-incident-example"
    >
      <IconButton
        size="small"
        className={classes.closeButton}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.dialogTitle}>
        Example photo of water pollution incident
      </DialogTitle>
      <DialogContent>
        <img
          src={exampleImage1}
          alt="First example of the water pollution incident"
          className={classes.image}
        />
        <img
          src={exampleImage2}
          alt="Second example of the water pollution incident"
          className={classes.image}
        />
      </DialogContent>
    </Dialog>
  );
};

export default IncidentExampleDialog;
