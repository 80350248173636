import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PageWrapper from "components/PageWrapper";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import MissionForm from "../common/MissionForm";
import {
  ConfigurableMissionData,
  equal,
  isMissionDataValid,
  isDuplicatingExistingMissionName
} from "../../../types/Missions";
import { editMission } from "../../../features/firebase/missions";
import { useMissions } from "../../../providers/MissionsProvider";
import firebase from "firebase/compat/app";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column"
  },
  missionFormWrapper: {
    flex: "1",
    clear: "both",
    overflow: "scroll"
  },
  buttons: {
    display: "flex",
    marginTop: `${theme.spacing(1)}px`,
    flexDirection: "row"
  },
  button: {
    marginLeft: 5,
    marginRight: 5
  },
  formErrorWarning: {
    color: "#f00",
    margin: "5px 0"
  }
}));

export default function EditMission() {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const handleBack = { handleBack: () => history.goBack(), confirm: true };

  const missionData = useMissions();
  const missions = missionData?.missions || [];

  const { missionId } = useParams<{ missionId: string }>();
  const originalMission = missions.find((ch) => ch.id.toString() === missionId);
  if (originalMission === undefined) {
    console.warn(
      `Trying to edit mission ${missionId} but couldn't find mission data in list.`
    );
  }

  const [formRefreshCounter, setFormRefreshCounter] = useState(0);
  const [missionFormData, setMissionFormData] = useState<
    ConfigurableMissionData | undefined
  >(originalMission);

  const duplicatingExistingMissionName = isDuplicatingExistingMissionName(
    missionFormData,
    missions,
    missionId
  );
  const missionReady: boolean = isMissionDataValid(missionFormData);
  const missionChanged: boolean =
    originalMission === undefined ||
    missionFormData === undefined ||
    originalMission.coverPhotoUrl !== missionFormData.coverPhoto ||
    !equal(originalMission, missionFormData);

  const applyEdits = async () => {
    if (missionFormData === undefined) {
      return;
    }
    await editMission(
      firebase.firestore(),
      firebase.storage(),
      missionId,
      missionFormData
    );
    await missionData?.refresh();
    await history.goBack();
  };

  const discardEdits = () => {
    setMissionFormData(originalMission);
    setFormRefreshCounter(formRefreshCounter + 1);
  };

  return (
    <PageWrapper
      label={t("missions_edit_label")}
      navigationHandler={handleBack}
    >
      <div className={styles.wrapper}>
        <div className={styles.missionFormWrapper}>
          <MissionForm
            initialData={originalMission}
            refreshCounter={formRefreshCounter}
            onMissionDataUpdated={setMissionFormData}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            onClick={applyEdits}
            color="primary"
            variant="contained"
            disabled={!missionReady || !missionChanged}
            disableElevation
          >
            {t("missions_edit_mission_apply_button_text")}
          </Button>
          <Button
            className={styles.button}
            onClick={discardEdits}
            color="primary"
            variant="contained"
            disabled={!missionChanged}
            disableElevation
          >
            {t("missions_edit_mission_discard_button_text")}
          </Button>
        </div>
        {duplicatingExistingMissionName && (
          <div className={styles.formErrorWarning}>
            {t("missions_duplicate_name_warning")}
          </div>
        )}
      </div>
    </PageWrapper>
  );
}
