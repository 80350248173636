import { Switch, Route, useHistory } from "react-router-dom";

import config, { linkToMap } from "custom/config";

import User from "types/User";

import Groups from "components/Groups/GroupMain";
import GroupList from "components/Groups/GroupList";
import GroupAdd from "components/Groups/GroupAdd";
import ModeratorPage from "components/ModeratorPage";
import { Leaderboard } from "components/Leaderboard";
import WriteFeedbackPage from "components/WriteFeedbackPage";

import DisplayFeature from "components/MapPage/DisplayFeature";

import ModeratorRoute from "./components/ModeratorRoute";
import SignedInRoute from "./components/SignedInRoute";

import PhotoRoute from "./photo/Route";
import { linkToPhotoPage } from "./photo/links";

import UploadPhotoRoute from "./upload-success/Route";
import { linkToUploadSuccess } from "./upload-success/links";

import AboutPageRoute from "./about/Route";
import { linkToAboutPage } from "./about/links";

import FeedbackRoute from "./feedback-reports/Route";
import { linkToFeedbackReports } from "./feedback-reports/links";

import { linkToLogin } from "./login/links";
import LoginRoute from "./login/Route";

import { linkToRegister } from "./register/links";
import RegisterRoute from "./register/Route";

import { linkToResetPassword } from "./reset-password/links";
import PasswordResetRoute from "./reset-password/Route";

import TutorialPageRoute from "./tutorial/Route";
import { linkToTutorialPage } from "./tutorial/links";

import { linkToAccountPage } from "./account/links";
import AccountPageRoute from "./account/Route";

import { useStats } from "providers/StatsProvider";

import { linkToMissionsPage } from "./missions/links";
import MissionsRoute from "./missions/Route";

import linkToAdminPages from "./admin/links";
import AdminRouter from "./admin/Router";

import { useTranslation } from "react-i18next";
import RecordPollutionRoute from "./record/Route";
import { linkToRecordPollutionPage } from "./record/links";

import { linkToHomePage } from "./home/links";
import HomeRoute from "./home/Route";

import { linkToSurveys } from "./surveys/links";
import SurveysRoute from "./surveys/Route";

import { linkToDashboardPage } from "./dashboard/links";
import DashboardPageRoute from "./dashboard/Route";

type Props = {
  user: User;
  setUser : (user: User) => void;
  reloadFeatures: () => void;
  gpsLocation: any;
  online: boolean;
  geojson: any;
  handleFeatureClick: () => void;
  selectedFeature: any;
  sponsorImage?: string;
};

export function Routes({
  user,
  setUser,
  reloadFeatures,
  gpsLocation,
  online,
  geojson,
  handleFeatureClick,
  selectedFeature,
  sponsorImage
}: Props) {
  const history = useHistory();
  const stats = useStats();
  const { t } = useTranslation();

  return (
    <Switch>
      <Route path={linkToUploadSuccess()}>
        <UploadPhotoRoute sponsorImage={sponsorImage} />
      </Route>
      <Route path={linkToLogin()}>
        <LoginRoute />
      </Route>
      <Route path={linkToRegister()}>
        <RegisterRoute />
      </Route>
      <Route path={linkToResetPassword()}>
        <PasswordResetRoute />
      </Route>
      <Route path={linkToAboutPage()}>
        <AboutPageRoute
          label={config.PAGES.about.label}
          handleClose={history.goBack}
          reloadFeatures={reloadFeatures}
          sponsorImage={sponsorImage}
        />
      </Route>
      <Route path={linkToTutorialPage()}>
        <TutorialPageRoute handleClose={history.goBack} />
      </Route>
      <Route path={config.PAGES.leaderboard.path}>
        <Leaderboard
          label={config.PAGES.leaderboard.label}
          usersLeaderboard={stats.users}
          handleClose={history.goBack}
          user={user}
        />
      </Route>
      <Route
        path={config.PAGES.groups.path}
        render={(props) => (
          <Groups
            {...props}
            config={config}
            label={config.PAGES.groups.label}
            handleClose={history.goBack}
          />
        )}
      />
      <Route
        path={config.PAGES.grouplist.path}
        render={(props) => (
          <GroupList
            {...props}
            config={config}
            label={config.PAGES.grouplist.label}
            groupsArray={["group1", "group2"]}
            handleClose={history.goBack}
          />
        )}
      />
      <Route
        path={config.PAGES.groupadd.path}
        render={(props) => (
          <GroupAdd
            {...props}
            config={config}
            label={config.PAGES.groupadd.label}
            handleClose={history.goBack}
          />
        )}
      />
      <Route path={linkToMissionsPage()}>
        <MissionsRoute label={config.PAGES.missions.label} />
      </Route>
      <Route path={linkToRecordPollutionPage()}>
        <RecordPollutionRoute user={user} geojson={geojson} setUser={setUser} />
      </Route>
      <ModeratorRoute path={config.PAGES.moderator.path} user={user}>
        <ModeratorPage
          user={user as User}
          label={config.PAGES.moderator.label}
          handleClose={history.goBack}
        />
      </ModeratorRoute>
      <ModeratorRoute path={linkToFeedbackReports()} user={user}>
        <FeedbackRoute user={user} />
      </ModeratorRoute>
      <Route path={linkToAdminPages()}>
        <AdminRouter />
      </Route>
      <Route path={linkToPhotoPage()}>
        <PhotoRoute />
      </Route>
      <SignedInRoute path={linkToAccountPage()} user={user}>
        <AccountPageRoute
          config={config}
          user={user}
          geojson={geojson}
          handleClose={history.goBack}
          handlePhotoClick={handleFeatureClick}
        />
      </SignedInRoute>

      <Route path={config.PAGES.writeFeedback.path}>
        <WriteFeedbackPage
          label={t(config.PAGES.writeFeedback.label)}
          user={user}
          location={gpsLocation}
          online={online}
          handleClose={history.goBack}
        />
      </Route>
      <Route
        path={[
          `${config.PAGES.displayFeature.path}/:id`,
          `${config.PAGES.embeddable.path}${config.PAGES.displayFeature.path}/:id`
        ]}
        render={({ location }) => (
          <DisplayFeature
            user={user}
            config={config}
            handleClose={history.goBack}
            feature={selectedFeature}
            location={location}
          />
        )}
      />
      <Route path={linkToSurveys()}>
        <SurveysRoute />
      </Route>
      <Route path={linkToMap()}></Route>
      <Route path={linkToDashboardPage()}>
        <DashboardPageRoute
          config={config}
          user={user}
          geojson={geojson}
          handleClose={history.goBack}
          handlePhotoClick={handleFeatureClick}
        />
      </Route>
      <Route path={linkToHomePage()}>
        <HomeRoute />
      </Route>
    </Switch>
  );
}
