import AboutPage from "pages/about";

type Props = {
  label: string;
  reloadFeatures: () => void;
  handleClose: () => void;
  sponsorImage?: string;
};

export default function AboutPageRoute(props: Props) {
  return <AboutPage {...props} />;
}
