import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import styles from "../../../../standard.module.scss";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  heading: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(2)}px`
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  waterwayInput: {
    border: "none",
    borderRadius: "5px",
    padding: `12px`,
    marginBottom: `${theme.spacing(2)}px`,
    background: styles.lightGrey,
    fontSize: 16,
    boxSizing: "border-box",
    width: "100%",
    textOverflow: "ellipsis",
    "&:focus": {
      outline: "none"
    }
  },
  button: {
    width: "100%"
  }
}));

type Props = {
  waterwayName: string;
  onSubmit: (waterwayName: string) => void;
};

const ReadingWaterwayNameEntry = ({ waterwayName, onSubmit }: Props) => {
  const classes = useStyles();

  const [waterwayNameCurrent, setWaterwayNameCurrent] = useState(waterwayName);

  return (
    <>
      <div className={classes.heading}>
        What body of water was the sample taken from?
      </div>
      <div className={classes.text}>
        Enter the name of a river, stream, lake, canal, or pond.
      </div>
      <input
        className={classes.waterwayInput}
        value={waterwayNameCurrent}
        placeholder={"e.g. River Thames"}
        onChange={(e) => setWaterwayNameCurrent(e.target.value)}
      />
      <Button
        className={classes.button}
        onClick={() => onSubmit(waterwayNameCurrent)}
        disabled={waterwayNameCurrent === ""}
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

export default ReadingWaterwayNameEntry;
