import { Map } from "immutable";

import { MapReadingData } from "./Readings/Base";
import { Incident, IncidentData } from "./Incident";

import Photo from "./Photo";
import Geojson from "./Geojson";

type Coordinates = [number, number];

type Geometry = {
  type: "Point";
  coordinates: Coordinates;
};

export type FeaturePropertiesType = Photo | MapReadingData | Incident;

export const featurePropertiesIsPhoto = (
  featureProperties: FeaturePropertiesType
): boolean => {
  return (featureProperties as Photo).pieces !== undefined;
};

export const featurePropertiesIsReading = (
  featureProperties: FeaturePropertiesType
): boolean => {
  return (featureProperties as MapReadingData).readingType !== undefined;
};

export const featurePropertiesIsIncident = (
  featureProperties: FeaturePropertiesType
): boolean => {
  return (featureProperties as IncidentData).description !== undefined;
};

export type Feature = {
  feature: "Feature";
  geometry: Geometry;
  properties: FeaturePropertiesType;
};

export type FeaturesContainer = {
  geojson: Geojson;
  featuresDict: Map<string, Feature>;
};

export const merge = (
  left: FeaturesContainer,
  right: FeaturesContainer
): FeaturesContainer => {
  const featuresDict = left.featuresDict.merge(right.featuresDict);
  return {
    featuresDict,
    geojson: {
      type: "FeatureCollection",
      features: Array.from(featuresDict.values())
    }
  };
};

export const EMPTY_FEATURES_CONTAINER: FeaturesContainer = {
  featuresDict: Map<string, Feature>(),
  geojson: {
    type: "FeatureCollection",
    features: []
  }
};

export default Feature;
