import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import GeoTagMap from "pages/photo/components/GeoTagMap";
import { LatLong } from "types/GPSLocation";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  button: {
    marginTop: `${theme.spacing(2)}px`
  }
}));

type Props = {
  currentLocation: LatLong;
  onSubmit: (location: LatLong) => void;
};

const IncidentGeoTag = ({ currentLocation, onSubmit }: Props) => {
  const classes = useStyles();

  const [location, setLocation] = useState(currentLocation);

  return (
    <>
      <div className={classes.text}>
        Please select the location where you took the photo:
      </div>
      <GeoTagMap onLocationUpdate={setLocation} />
      <Button
        className={classes.button}
        onClick={() => onSubmit(location)}
        color="primary"
        variant="contained"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

export default IncidentGeoTag;
