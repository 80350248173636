import React, { useContext, useEffect, useState } from "react";

import { Survey } from "../types/Survey";

import hardcodedSurveys from "custom/surveys.json";

import firebase from "firebase/compat/app";

const firestore = firebase.firestore();

const surveysPath = () => "surveyDefs";

export const fetchSurveys = async () => {
  try {
    const snap = await firestore.collection(surveysPath()).get();

    return snap.docs.map((surveyDef) => ({
      ...surveyDef.data()
    })) as Survey[];
  } catch (e) {
    return null;
  }
};

export type SurveysProviderData = {
  surveys?: Survey[];
  refresh: () => Promise<void>;
};

export const SurveysContext = React.createContext<
  SurveysProviderData | undefined
>(undefined);

type Props = {
  children?: React.ReactChild[];
};

const surveyStore = () => {
  return hardcodedSurveys;
};

export const SurveysProvider = ({ children }: Props) => {
  const updateSurveys = async () => {
    const fetchedSurveys = await fetchSurveys();
    const storedSurveys = surveyStore();

    setData({
      ...data,
      surveys:
        fetchedSurveys && fetchedSurveys.length > 0
          ? fetchedSurveys
          : JSON.parse(JSON.stringify(storedSurveys))
    });
  };

  const [data, setData] = useState<SurveysProviderData>({
    surveys: undefined,
    refresh: updateSurveys
  });

  useEffect(() => {
    updateSurveys();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SurveysContext.Provider value={data}>{children}</SurveysContext.Provider>
  );
};

export const useSurveys = () => useContext(SurveysContext);
