import { useState } from "react";

import { makeStyles } from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

import { Option, Question } from "types/Survey";
import useEffectOnMount from "hooks/useEffectOnMount";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 8,
    top: 16
  },
  dialogTitle: {
    marginRight: "25px"
  },
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(2),
    width: "100%"
  },
  image: {
    marginBottom: `${theme.spacing(2)}px`,
    width: "100%"
  },
  legend: {
    marginBottom: theme.spacing(2)
  }
}));

type Props = {
  question: Question;
  onChangeQuestionValue: (
    questionId: string,
    value: string[],
    valid: boolean
  ) => void;
};

export default function QuestionImageSelect({
  question,
  onChangeQuestionValue
}: Props) {
  const classes = useStyles();

  const [value, setValue] = useState((question.default as string[]) || []);
  const [valid, setValid] = useState(
    question.required ? (question.default ? true : false) : true
  );
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [other, setOther] = useState("");
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [currentInfoOption, setCurrentInfoOption] = useState({} as Option);

  useEffectOnMount(() => {
    onChangeQuestionValue(question.id, value, valid);
  });

  const handleChangeValue = (option: string) => {
    let currentValue = Object.assign([], value);

    // Handle the 'None' and 'No time' options
    if (option === "None" || option === "No time") {
      currentValue = [];
      // If the option is already selected, unselect it
      if (!value.includes(option)) {
        currentValue.push(option);
      }
      setValue(currentValue);
    } else {
      if (value.includes("None"))
        currentValue = currentValue.filter((v) => v !== "None");
      if (value.includes("No time"))
        currentValue = currentValue.filter((v) => v !== "No time");

      if (!currentValue.includes(option)) {
        currentValue.push(option);
        if (option === "Other" && other !== "") currentValue.push(`Other - ${other}`);
      } else {
        currentValue = currentValue.filter((v) => v !== option);
        if (option === "Other") currentValue = currentValue.filter((v: string) => !v.includes("Other - "));
      }
      setValue(currentValue);
    }

    onChangeQuestionValue(question.id, currentValue, validate(currentValue));

    setValidationTriggered(true);
  };

  const validate = (currentValue: string[]) => {
    if (question.required && currentValue.length === 0) {
      setValid(false);
      return false;
    } else {
      setValid(true);
      return true;
    }
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  const handleInfoDialogOpen = (event: any, option: Option) => {
    event.stopPropagation();
    setCurrentInfoOption(option);
    setInfoDialogOpen(true);
  };

  const handleChangeOther = (event: React.ChangeEvent<HTMLInputElement>) => {
    let otherValue = event.target.value;
    setOther(otherValue);
    let currentValue = Object.assign([], value);
    currentValue = currentValue.filter((v: string) => !v.includes("Other - "));
    if (otherValue !== "") currentValue.push(`Other - ${otherValue}`);
    setValue(currentValue);
    onChangeQuestionValue(question.id, currentValue, valid);
  };

  const error = !valid && validationTriggered;

  return (
    <div className={classes.root}>
      <FormControl
        required={question.required}
        error={error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend" className={classes.legend}>
          {question.name}
        </FormLabel>
        <FormHelperText>
          <span dangerouslySetInnerHTML={{ __html: question.description }} />
        </FormHelperText>
        <FormGroup>
          <List>
            {question.options.map((option: Option) => (
              <ListItem
                key={`opt_${option.id}`}
                button
                onClick={() => handleChangeValue(option.value as string)}
              >
                {option.images && option.images.length > 0 && (
                  <ListItemAvatar>
                    <Avatar
                      onClick={(e) => handleInfoDialogOpen(e, option)}
                      alt={`Example image of ${option.name}`}
                      src={`https://storage.googleapis.com/planet-patrol-survey-images/Thumbnail%20${option.images[0]}`}
                    />
                  </ListItemAvatar>
                )}
                <ListItemText primary={option.name} />
                {option.value === "Other" &&
                value.indexOf(option.value as string) !== -1 ? (
                  <TextField
                    label={"Specify other"}
                    variant="outlined"
                    value={other}
                    onChange={handleChangeOther}
                    onClick={(e) => e.stopPropagation()}
                    onSelect={(e) => e.stopPropagation()}
                  />
                ) : null}
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    value={option.value}
                    checked={value.indexOf(option.value as string) !== -1}
                  />
                </ListItemIcon>
                {((option.description && option.description !== "") ||
                  (option.images && option.images.length > 0)) && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={(e) => handleInfoDialogOpen(e, option)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </FormGroup>
      </FormControl>
      <Dialog open={infoDialogOpen} onClose={handleInfoDialogClose}>
        <IconButton
          size="small"
          className={classes.closeButton}
          aria-label="close"
          onClick={handleInfoDialogClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle className={classes.dialogTitle}>
          {currentInfoOption.name}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <div
              dangerouslySetInnerHTML={{
                __html: currentInfoOption.description
              }}
            />
          </Typography>
          <br />
          {currentInfoOption.images
            ? currentInfoOption.images.map((image, idx) => (
                <img
                  key={`img_dialogexamples_${idx}`}
                  alt={"Example image of " + currentInfoOption.name}
                  src={`https://storage.googleapis.com/planet-patrol-survey-images/${image}`}
                  className={classes.image}
                />
              ))
            : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}
