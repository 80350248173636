import { useState } from "react";

import { makeStyles, TextField } from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";

import { Question } from "types/Survey";
import useEffectOnMount from "hooks/useEffectOnMount";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(2),
    width: "100%"
  },
  legend: {
    marginBottom: theme.spacing(2)
  }
}));

type Props = {
  question: Question;
  onChangeQuestionValue: (
    questionId: string,
    value: number | undefined,
    valid: boolean
  ) => void;
};

export default function QuestionNumber({
  question,
  onChangeQuestionValue
}: Props) {
  const classes = useStyles();

  const [value, setValue] = useState(
    (question.default as number | string) || null
  );
  const [valid, setValid] = useState(question.required ? (question.default ? true : false) : true);
  const [validationTriggered, setValidationTriggered] = useState(false);

  useEffectOnMount(() => {
    onChangeQuestionValue(question.id, (value as number) || undefined, valid);
  });

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationTriggered(true);
    const newValue = event.target.value;

    if (newValue === "") {
      setValue("");
      setValid(false);
      onChangeQuestionValue(question.id, undefined, false);
      return;
    }

    const numberValue = Number(newValue);
    if (isNaN(numberValue)) return;

    setValue(numberValue);
    onChangeQuestionValue(question.id, numberValue, validate(numberValue));
  };

  const validate = (currentValue: number) => {
    if (question.required && currentValue === null) {
      setValid(false);
      return false;
    } else {
      setValid(true);
      return true;
    }
  };

  const error = !valid && validationTriggered;

  return (
    <div className={classes.root}>
      <FormControl
        required={question.required}
        error={error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend" className={classes.legend}>
          {question.name}
        </FormLabel>
        <FormGroup>
          <TextField
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            label={question.label}
            variant="outlined"
            value={value || ""}
            onChange={handleChangeValue}
          />
        </FormGroup>
        <FormHelperText>
          <span dangerouslySetInnerHTML={{ __html: question.description }} />
        </FormHelperText>
      </FormControl>
    </div>
  );
}
