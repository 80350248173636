import { FeaturesProviderData } from "providers/FeaturesProvider";
import { PhotosProviderData } from "providers/PhotosProvider";

import { Feature } from "types/Feature";
import User from "types/User";

import {
  getLitterClusterCount,
  getMaxLitterClusterSize
} from "./litterAnalytics";

export type Badge = {
  name: string;
  title: string;
  description: string;
  icon: string;
  actionType: ActionType;
  count: number;
};

type ActionType = "litter" | "water" | "social";

const badges = [
  {
    name: "1 Water Reading",
    title: "1 Water Reading",
    description: "1 water reading recorded",
    icon: "water",
    actionType: "water",
    count: 1
  },
  {
    name: "10 Water Readings",
    title: "10 Water Readings",
    description: "10 water readings recorded",
    icon: "water",
    actionType: "water",
    count: 10
  },
  {
    name: "52 Water Readings",
    title: "52 Water Readings",
    description: "52 water readings recorded",
    icon: "water",
    actionType: "water",
    count: 52
  },
  {
    name: "1 Piece of Litter",
    title: "1 Piece of Litter",
    description: "1 piece of litter uploaded",
    icon: "trash",
    actionType: "litter",
    count: 1
  },
  {
    name: "20 Pieces of Litter",
    title: "20 Pieces of Litter",
    description: "20 pieces of litter uploaded",
    icon: "trash",
    actionType: "litter",
    count: 20
  },
  {
    name: "100 Pieces of Litter",
    title: "100 Pieces of Litter",
    description: "100 pieces of litter uploaded",
    icon: "trash",
    actionType: "litter",
    count: 100
  },
  {
    name: "1000 Pieces of Litter",
    title: "1000 Pieces of Litter",
    description: "1000 pieces of litter uploaded",
    icon: "trash",
    actionType: "litter",
    count: 1000
  },
  {
    name: "Local litter hero",
    title: "Local litter hero",
    description: "10 litter picks in a local area",
    icon: "trash",
    actionType: "litter",
    count: 10
  },
  {
    name: "Litter explorer",
    title: "Litter explorer",
    description: "A litter pick in 10 different areas",
    icon: "trash",
    actionType: "litter",
    count: 10
  }
] as Badge[];

export const getAllBadges = () => {
  return badges;
};

const getUserWaterReadings = (
  userId: string,
  allReadings: FeaturesProviderData
) => {
  const readings = allReadings?.features?.geojson?.features;
  if (readings) {
    return readings.filter((reading) => {
      if (reading.properties && (reading.properties as any).userId) {
        return (reading.properties as any).userId === userId;
      }
      return false;
    });
  } else {
    return [];
  }
};

const getUserLitterReadings = (
  userId: string,
  allReadings: PhotosProviderData
) => {
  const readings = allReadings?.features?.geojson?.features;
  if (readings) {
    return readings.filter((reading) => {
      if (reading.properties && (reading.properties as any).owner_id) {
        return (reading.properties as any).owner_id === userId;
      }
      return false;
    });
  } else {
    return [];
  }
};

const getLitterPieceCount = (litterReadings: Feature[]) => {
  let count = 0;
  litterReadings.forEach((reading) => {
    if (reading.properties && (reading.properties as any).pieces) {
      count += (reading.properties as any).pieces;
    }
  });
  return count;
};

const assessBadge = (
  badge: any,
  litterReadings: Feature[],
  waterReadings: Feature[],
  actionType: ActionType
) => {
  switch (badge.name) {
    case "1 Piece of Litter":
      if (!litterReadings || litterReadings.length === 0) return false;
      return litterReadings.length > 0 || actionType === "litter";
    case "20 Pieces of Litter":
      if (!litterReadings || litterReadings.length === 0) return false;
      return getLitterPieceCount(litterReadings) >= 20;
    case "100 Pieces of Litter":
      if (!litterReadings || litterReadings.length === 0) return false;
      return getLitterPieceCount(litterReadings) >= 100;
    case "1000 Pieces of Litter":
      if (!litterReadings || litterReadings.length === 0) return false;
      return getLitterPieceCount(litterReadings) >= 1000;
    case "1 Water Reading":
      return waterReadings.length > 0 || actionType === "water";
    case "10 Water Readings":
      return waterReadings.length >= 9 && actionType === "water";
    case "52 Water Readings":
      return waterReadings.length >= 51 && actionType === "water";
    case "Local litter hero":
      return (
        getMaxLitterClusterSize(litterReadings) > 9 && actionType === "water"
      );
    case "Litter explorer":
      return (
        getLitterClusterCount(litterReadings) > 9 && actionType === "water"
      );
    default:
      return false;
  }
};

export const reassessUserBadges = (
  user: User,
  actionType: ActionType,
  litterReadings: PhotosProviderData,
  waterReadings: FeaturesProviderData
) => {
  const userLitterReadings = getUserLitterReadings(user.id, litterReadings);
  const userWaterReadings = getUserWaterReadings(user.id, waterReadings);

  let userBadges = user.badges || [];

  badges.forEach((badge) => {
    // Check if the user already has the badge
    if (
      userBadges.indexOf(badge.name) === -1 &&
      badge.actionType === actionType
    ) {
      // Run badge assessment
      const achieved = assessBadge(
        badge,
        userLitterReadings,
        userWaterReadings,
        actionType
      );
      if (achieved) {
        userBadges.push(badge.name);
      }
    }
  });

  return userBadges;
};
