import { useState } from "react";
import { useHistory } from "react-router";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import PageWrapper from "components/PageWrapper";

import { resetPassword } from "../../features/firebase/authFirebase";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing()
  },
  center: {
    textAlign: "center"
  },
  formControl: {
    marginTop: theme.spacing(2)
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing()
  },
  page: {
    margin: theme.spacing(2)
  },
  socialButton: {
    boxShadow: "none !important",
    border: "1px solid #dcdcdc !important"
  }
}));

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();
  const handleClose = () => history.push("/");

  const classes = useStyles();

  const validEmail = (value = email) => {
    const valid = value && value.length > 0;
    if (valid) {
      setEmailError("");
    } else {
      setEmailError("Email is required");
    }
    setEmailValid(valid === true);
    return valid;
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    validEmail(event.target.value);
  };

  const handleResetClick = async () => {
    if (!validEmail()) {
      setEmailValid(false);
      return;
    }

    const passwordReset = await resetPassword(email);

    if (passwordReset) {
      setSubmitted(true);
    } else {
      setSubmitted(false);
    }
  };

  return (
    <PageWrapper
      label="Reset password"
      navigationHandler={{ handleClose: handleClose }}
      fillIPhoneScreen={true}
    >
      <div className={classes.page}>
        {!submitted && (
          <>
            <FormControl
              required={true}
              error={emailValid ? false : true}
              component="fieldset"
              className={classes.formControl}
              fullWidth
            >
              <FormLabel component="legend" className={classes.legend}>
                Email
              </FormLabel>
              <FormGroup>
                <TextField
                  fullWidth
                  label={"Email"}
                  variant="outlined"
                  value={email}
                  onChange={handleChangeEmail}
                  helperText={emailError}
                  error={emailValid ? false : true}
                />
              </FormGroup>
            </FormControl>
            <Button
              size="large"
              variant="contained"
              className={classes.button}
              disableElevation
              fullWidth
              onClick={handleResetClick}
              color="primary"
              disabled={!emailValid}
            >
              Reset password
            </Button>
          </>
        )}
        {submitted && (
          <>
            <Typography>
              Your password reset request has been submitted. You will receive
              an email with instructions on what to do next.
            </Typography>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default RegisterPage;
