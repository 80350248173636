import { useEffect } from "react";
import { useHistory } from "react-router";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { linkToLogin } from "routes/login/links";
import { linkToLoginWithRedirectOnSuccess } from "routes/login/links";
import { linkToMap } from "custom/config";
import { linkToRegister } from "routes/register/links";
import { linkToSurveyPage } from "routes/surveys/links";

import PageWrapper from "components/PageWrapper";

import { useUser } from "../../providers/UserProvider";
import { useSurveys } from "../../providers/SurveysProvider";

import { isIphoneWithNotchAndCordova } from "../../utils";

import { Survey } from "types/Survey";

import config from "../../custom/config";

import PaddlingImage from "assets/images/paddling.png";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing()
  },
  buttonContainer: {
    marginTop: -theme.spacing(2),
    backgroundColor: "#fff",
    borderRadius: "15px 0 0 0",
    padding: theme.spacing(2)
  },
  center: {
    textAlign: "center"
  },
  fill: {
    flexGrow: 1
  },
  header: {
    fontFamily: "PermanentMarker"
  },
  loading: {
    fontWeight: 500,
    textAlign: "center",
    padding: theme.spacing(2)
  },
  logo: {
    height: "210px",
    objectFit: "cover"
  },
  page: {
    flexDirection: "column",
    display: "flex",
    height: "100%"
  },
  progress: {
    marginLeft: theme.spacing(1)
  },
  survey: {
    color: "#fff",
    padding: theme.spacing(2)
  },
  surveyContainer: {
    marginTop: theme.spacing(1),
    width: "100%",
    textAlign: "center",
    backgroundColor: "#06668C",
    borderRadius: 0,
    paddingBottom: isIphoneWithNotchAndCordova()
      ? "env(safe-area-inset-bottom)"
      : "0px"
  },
  surveyName: {
    color: "#F2AEAF"
  }
}));

const SurveyButton = withStyles((theme) => ({
  root: {
    color: "#004F72",
    backgroundColor: "#fff"
  }
}))(Button);

const HomePage = () => {
  const history = useHistory();
  const handleClose = () => history.push("/");

  const surveysData = useSurveys();
  const surveys = surveysData?.surveys || undefined;

  const { user } = useUser();

  const classes = useStyles();

  const handleExploreClick = () => {
    history.push(linkToMap());
  };

  const handleLaunchSurvey = (surveyId: string) => {
    if (config.SECURITY.UPLOAD_REQUIRES_LOGIN && !user) {
      history.push(
        linkToLoginWithRedirectOnSuccess(linkToSurveyPage(surveyId))
      );
    } else {
      history.push(linkToSurveyPage(surveyId));
    }
  };

  const handleLoginClick = () => {
    history.push(linkToLogin());
  };

  const handleRegisterClick = () => {
    history.push(linkToRegister());
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        history.push(linkToMap());
      }, 1000);
    }
  }, [user, history]);

  return (
    <PageWrapper
      label=""
      navigationHandler={{ handleClose: handleClose }}
      navigationIcon={false}
      fillIPhoneScreen={true}
      hideTopNavigation={true}
    >
      <div className={classes.page}>
        <img
          className={classes.logo}
          src={PaddlingImage}
          alt="Paddle boards with boxes displaying the Planet Patrol logo"
        />
        <div className={classes.buttonContainer}>
          <Typography
            component="h1"
            variant="h4"
            className={classes.header}
            gutterBottom
          >
            Welcome to Planet Patrol
          </Typography>
          {user === null && (
            <>
              <Button
                size="large"
                variant="contained"
                className={classes.button}
                disableElevation
                fullWidth
                onClick={handleLoginClick}
                color="primary"
              >
                Sign in
              </Button>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                className={classes.button}
                disableElevation
                fullWidth
                onClick={handleRegisterClick}
              >
                Register
              </Button>
              <Typography className={classes.center} component="p" variant="h6">
                or
              </Typography>
              <Button
                size="large"
                variant="contained"
                className={classes.button}
                disableElevation
                fullWidth
                onClick={handleExploreClick}
                color="primary"
              >
                Explore map
              </Button>
            </>
          )}
          {user === undefined && (
            <Typography
              className={classes.loading}
              component="p"
              variant="body1"
            >
              Trying to log you in...
            </Typography>
          )}
          {user && (
            <Typography className={classes.center} component="p" variant="h6">
              Hi {user.displayName}
            </Typography>
          )}
        </div>
        <div className={classes.fill}></div>
        {surveys
          ? surveys.map((survey: Survey) => {
              const startDateTime = new Date(survey.fromDate);
              const endDateTime = new Date(survey.toDate);
              const now = new Date();
              const isSurveyActive = now >= startDateTime && now <= endDateTime;
              const milliSecondsToFinish = Math.floor(
                endDateTime.getTime() - now.getTime()
              );

              const days = Math.floor(milliSecondsToFinish / 86400000);
              const hours = Math.floor(
                (milliSecondsToFinish - days * 86400000) / 3600000
              );

              return (
                isSurveyActive && (
                  <Paper
                    key={`ppr_${survey.id}`}
                    elevation={0}
                    className={classes.surveyContainer}
                  >
                    <div className={classes.survey}>
                      <Typography variant="body1">
                        <span className={classes.surveyName}>
                          {survey.name}
                        </span>
                        {` ends in ${days} days and ${hours} hours. `}
                        {survey.completed && survey.completed > 99
                          ? ` Over ${survey.completed} surveys completed!`
                          : ``}
                      </Typography>
                      <SurveyButton
                        key={`btn_survey_${survey.id}`}
                        fullWidth
                        className={classes.button}
                        disableElevation
                        onClick={() => handleLaunchSurvey(survey.id)}
                        variant="contained"
                      >
                        {`Join ${survey.name}`}
                      </SurveyButton>
                    </div>
                  </Paper>
                )
              );
            })
          : null}
      </div>
    </PageWrapper>
  );
};

export default HomePage;
