import { useState } from "react";
import { useHistory } from "react-router";

import { Capacitor } from "@capacitor/core";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/core/styles";

import PageWrapper from "components/PageWrapper";

import { tAndCLink, privacyPolicyLink } from "static/info";

import {
  signInMobileProvider,
  registerEmailPassword,
  checkUserExists
} from "../../features/firebase/authFirebase";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLoginButton } from "react-social-login-buttons";
import { AppleLoginButton } from "react-social-login-buttons";

import { useUser } from "../../providers/UserProvider";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing()
  },
  center: {
    textAlign: "center"
  },
  formControl: {
    marginTop: theme.spacing(2)
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing()
  },
  page: {
    margin: theme.spacing(2)
  },
  socialButton: {
    boxShadow: "none !important",
    border: "1px solid #dcdcdc !important"
  }
}));

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");

  const [displayName, setDisplayName] = useState("");
  const [displayNameValid, setDisplayNameValid] = useState(true);
  const [displayNameError, setDisplayNameError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [agreementChecked, setAgreementChecked] = useState(false);
  const [stage, setStage] = useState("email");

  const history = useHistory();
  const handleClose = () => history.push("/");

  const classes = useStyles();

  const { setUser } = useUser();

  const validEmail = (value = email) => {
    const valid = value && value.length > 0;
    if (valid) {
      setEmailError("");
    } else {
      setEmailError("Email is required");
    }
    setEmailValid(valid === true);
    return valid;
  };

  const validDisplayName = (value = displayName) => {
    const valid = value && value.length > 0;
    if (valid) {
      setDisplayNameError("");
    } else {
      setDisplayNameError("Display name is required");
    }
    setDisplayNameValid(valid === true);
    return valid;
  };

  const validPassword = (value = password) => {
    const valid = value && value.length > 5;
    if (valid) {
      setPasswordError("");
    } else {
      setPasswordError("Password should be at least 6 characters");
    }
    setPasswordValid(valid === true);
    return valid;
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    validEmail(event.target.value);
  };

  const handleChangeDisplayName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDisplayName(event.target.value);
    validDisplayName(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    validPassword(event.target.value);
  };

  const handleChangeAgreementChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAgreementChecked(event.target.checked);
  };

  const handleEmailNextClick = async () => {
    if (!validEmail()) {
      setEmailValid(false);
      return;
    }

    const userExists = await checkUserExists(email);

    if (userExists == null) {
      setEmailValid(false);
      setEmailError("Invalid email address");
      return;
    }

    if (userExists === true) {
      setEmailValid(false);
      setEmailError("Email already exists, please try signing in");
    } else {
      setStage("displayName");
    }
  };

  const handleDisplayNameNextClick = () => {
    if (validEmail() && validDisplayName()) {
      setStage("confirm");
    }
  };

  const handleDisplayNameBackClick = () => setStage("email");

  const handleConfirmClick = async () => {
    if (
      validEmail() &&
      validPassword() &&
      validDisplayName() &&
      agreementChecked
    ) {
      await registerEmailPassword(email, password, displayName, setUser);
      handleClose();
    }
  };

  const handleConfirmBackClick = () => setStage("displayName");

  const handleFacebookLoginClick = async () => {
    await signInMobileProvider("facebook.com");
    handleClose();
  };

  const handleGoogleLoginClick = async () => {
    await signInMobileProvider("google.com");
    handleClose();
  };

  const handleAppleLoginClick = async () => {
    await signInMobileProvider("apple.com");
    handleClose();
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <PageWrapper
      label="Register"
      navigationHandler={{ handleClose: handleClose }}
      fillIPhoneScreen={true}
    >
      <div className={classes.page}>
        {stage === "email" && (
          <>
            <FormControl
              required={true}
              error={emailValid ? false : true}
              component="fieldset"
              className={classes.formControl}
              fullWidth
            >
              <FormLabel component="legend" className={classes.legend}>
                Email
              </FormLabel>
              <FormGroup>
                <TextField
                  fullWidth
                  label={"Email"}
                  variant="outlined"
                  value={email}
                  onChange={handleChangeEmail}
                  helperText={emailError}
                  error={emailValid ? false : true}
                />
              </FormGroup>
            </FormControl>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.padding}
            >
              <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                View our privacy policy
              </a>
            </Typography>
            <Button
              size="large"
              variant="contained"
              className={classes.button}
              disableElevation
              fullWidth
              onClick={handleEmailNextClick}
              disabled={!emailValid}
              color="primary"
            >
              Next
            </Button>
            <Typography className={classes.center} component="h1" variant="h5">
              or
            </Typography>
            <FacebookLoginButton
              onClick={handleFacebookLoginClick}
              className={classes.socialButton}
            >
              Register with Facebook
            </FacebookLoginButton>
            <GoogleLoginButton
              onClick={handleGoogleLoginClick}
              className={classes.socialButton}
            >
              Register with Google
            </GoogleLoginButton>
            {Capacitor.getPlatform() === "ios" && (
              <AppleLoginButton
                onClick={handleAppleLoginClick}
                className={classes.socialButton}
              >
                Register with Apple
              </AppleLoginButton>
            )}
          </>
        )}
        {stage === "displayName" && (
          <>
            <FormControl
              required={true}
              error={false}
              component="fieldset"
              className={classes.formControl}
              fullWidth
            >
              <FormLabel component="legend" className={classes.legend}>
                Display name
              </FormLabel>
              <FormGroup>
                <TextField
                  fullWidth
                  label={"What should everyone call you?"}
                  variant="outlined"
                  value={displayName}
                  onChange={handleChangeDisplayName}
                  error={displayNameValid ? false : true}
                  helperText={displayNameError}
                />
              </FormGroup>
              <FormHelperText></FormHelperText>
            </FormControl>
            <Button
              size="large"
              variant="contained"
              className={classes.button}
              disableElevation
              fullWidth
              onClick={handleDisplayNameNextClick}
              color="primary"
            >
              Next
            </Button>
            <Button
              size="large"
              variant="contained"
              className={classes.button}
              disableElevation
              fullWidth
              onClick={handleDisplayNameBackClick}
            >
              Back
            </Button>
          </>
        )}
        {stage === "confirm" && (
          <>
            <FormControl
              required={true}
              error={false}
              component="fieldset"
              className={classes.formControl}
              fullWidth
            >
              <FormLabel component="legend" className={classes.legend}>
                Password
              </FormLabel>
              <FormGroup>
                <TextField
                  fullWidth
                  label={"Password"}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handleChangePassword}
                  error={passwordValid ? false : true}
                  helperText={passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormGroup>
              <FormHelperText></FormHelperText>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreementChecked}
                  onChange={handleChangeAgreementChecked}
                  name="agreementChecked"
                />
              }
              label={
                <Typography>
                  I have read and agree to Planet Patrol's Terms of Service and
                  Privacy Policy
                </Typography>
              }
            />
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.padding}
            >
              <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                View our Privacy Policy
              </a>
              <br />
              <a href={tAndCLink} target="_blank" rel="noreferrer">
                View our Terms of Service
              </a>
            </Typography>
            <Button
              size="large"
              variant="contained"
              className={classes.button}
              disableElevation
              fullWidth
              onClick={handleConfirmClick}
              disabled={!agreementChecked || !passwordValid}
              color="primary"
            >
              Create an account
            </Button>
            <Button
              size="large"
              variant="contained"
              className={classes.button}
              disableElevation
              fullWidth
              onClick={handleConfirmBackClick}
            >
              Back
            </Button>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default RegisterPage;
