import React, { useCallback, useContext, useState } from "react";

import Feature, {
  EMPTY_FEATURES_CONTAINER,
  FeaturesContainer,
  merge
} from "types/Feature";

import useAsyncEffect from "hooks/useAsyncEffect";

import {
  Nitrate,
  NITRATE_READING_TYPE,
  nitrateToMapData
} from "../types/Readings/Nitrate";
import { Map as ImmutableMap } from "immutable";
import firebase from "firebase/compat/app";

import {
  Phosphate,
  PHOSPHATE_READING_TYPE,
  phosphateToMapData
} from "../types/Readings/Phosphate";
import {
  Coliforms,
  COLIFORMS_READING_TYPE,
  coliformsToMapData
} from "../types/Readings/Coliforms";
import { Ph, PH_READING_TYPE, phToMapData } from "../types/Readings/Ph";
import {
  Temperature,
  TEMPERATURE_READING_TYPE,
  temperatureToMapData
} from "../types/Readings/Temperature";
import { INVALID_LAT_LON } from "../types/GPSLocation";
import { MapReadingData, Reading } from "../types/Readings/Base";

import { getGeoDataURL } from "../features/firebase/firebaseInit";
import { fetchReadings } from "../features/firebase/readings/api";

const toMapReadingData = (reading: Reading): MapReadingData => {
  switch (reading.readingType) {
    case NITRATE_READING_TYPE:
      return nitrateToMapData(reading as Nitrate);
    case PHOSPHATE_READING_TYPE:
      return phosphateToMapData(reading as Phosphate);
    case COLIFORMS_READING_TYPE:
      return coliformsToMapData(reading as Coliforms);
    case PH_READING_TYPE:
      return phToMapData(reading as Ph);
    case TEMPERATURE_READING_TYPE:
      return temperatureToMapData(reading as Temperature);
    default:
      console.error(
        `Failed extracting map reading data. Unknown reading type.`
      );
      return {
        ...reading,
        data: new Map()
      };
  }
};

export const readingToFeature = (reading: Reading): Feature => {
  return {
    feature: "Feature",
    geometry: {
      type: "Point",
      coordinates:
        reading.location !== undefined && reading.location !== null
          ? [reading.location.longitude, reading.location.latitude]
          : [INVALID_LAT_LON.longitude, INVALID_LAT_LON.latitude]
    },
    properties: toMapReadingData(reading)
  };
};

const readingsToFeaturesContainer = (
  readings: Reading[]
): FeaturesContainer => {
  const features = readings.map((reading) => readingToFeature(reading));
  return {
    featuresDict: ImmutableMap(
      features.map((feature) => [feature.properties.id, feature])
    ),
    geojson: {
      type: "FeatureCollection",
      features
    }
  };
};

type FeaturesProviderData = {
  features: FeaturesContainer;
  reload: () => Promise<void>;
};

const EMPTY_READINGS_CONTEXT: FeaturesProviderData = {
  features: EMPTY_FEATURES_CONTAINER,
  reload: async () => {}
};

export const ReadingsContext = React.createContext<FeaturesProviderData>(
  EMPTY_READINGS_CONTEXT
);

type Props = {
  children?: React.ReactChild[];
};

export const ReadingsProvider = ({ children }: Props) => {
  const updateReadings = async () => {
    try {
      const newReadings = await fetchReadings(
        firebase.storage(),
        getGeoDataURL()
      );
      const newFeatures = readingsToFeaturesContainer(newReadings);
      setReadings((current: FeaturesProviderData) => ({
        ...current,
        features: merge(current.features, newFeatures)
      }));
    } catch (error) {
      console.error(
        `Error fetching readings from Firestore functions. ${error}`
      );
    }
  };

  const [readings, setReadings] = useState<FeaturesProviderData>({
    features: EMPTY_FEATURES_CONTAINER,
    reload: useCallback(async () => {
      setReadings(EMPTY_READINGS_CONTEXT);
      await updateReadings();
    }, [])
  });

  useAsyncEffect(async () => {
    await updateReadings();
  }, []);

  // TODO - Investigate whether we want to add realtime subscriptions like photos have?

  return (
    <ReadingsContext.Provider value={readings}>
      {children}
    </ReadingsContext.Provider>
  );
};

export const useReadings = () => useContext(ReadingsContext);
