import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import _ from "lodash";
import { getReadingStorageBucket, uploadReadingPhoto } from "./utils";
import {
  AnyReadingFormData,
  ReadingUploadHandle
} from "../../../types/Readings/Shared";
import {
  COLIFORMS_POST_INCUBATION_PHOTO_FOLDER,
  COLIFORMS_READING_TYPE,
  ColiformsFormData
} from "../../../types/Readings/Coliforms";
import { photoIsMetaData } from "../../../types/Photo";
import { Reading } from "../../../types/Readings/Base";
import {
  HIGH_RES_PHOTO_NAME,
  THUMBNAIL_PHOTO_NAME
} from "../../../shared/utils";

export const uploadReading = async (
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  reading: AnyReadingFormData
): Promise<ReadingUploadHandle> => {
  // We get rid of photo from the Firestore data because it's kept in Storage instead.
  let firestoreData: Omit<AnyReadingFormData, "photo"> = _.omit(
    reading,
    "photo"
  );

  // Coliforms readings have an extra photo we need to get rid of.
  if (reading.readingType === COLIFORMS_READING_TYPE) {
    firestoreData = _.omit(firestoreData, "postIncubationPhoto");
  }

  // Create the Firestore entry.
  let firestoreId;
  try {
    const doc = await firestore
      .collection(reading.readingType)
      .add(firestoreData);
    firestoreId = doc.id;
  } catch (err) {
    throw new Error(
      `Failed creating ${reading.readingType} reading firestore document. ${err}`
    );
  }

  let uploadTasks = [];

  // Upload reading photo (if exists).
  if (reading.photo !== undefined && photoIsMetaData(reading.photo)) {
    const readingPhotoUploadTask = uploadReadingPhoto(
      storage,
      reading.readingType,
      firestoreId,
      reading.photo
    );
    if (readingPhotoUploadTask !== undefined) {
      uploadTasks.push(readingPhotoUploadTask);
    }

    // Here I considered binding a callback to delete the Firestore entry
    // if the photo upload is cancelled or fails. I decided not to. I think
    // the better option is that we allow users to reattempt to upload photos
    // at a later time (to the existing Firestore data).
  }

  // Coliforms readings require an extra photo upload.
  if (reading.readingType === COLIFORMS_READING_TYPE) {
    const incubationPhoto = (reading as ColiformsFormData).postIncubationPhoto;
    if (incubationPhoto !== undefined && photoIsMetaData(incubationPhoto)) {
      const coliformsIncubationPhotoUploadTask = uploadReadingPhoto(
        storage,
        reading.readingType,
        firestoreId,
        incubationPhoto,
        COLIFORMS_POST_INCUBATION_PHOTO_FOLDER
      );
      if (coliformsIncubationPhotoUploadTask !== undefined) {
        uploadTasks.push(coliformsIncubationPhotoUploadTask);
      }
    }
  }

  return { firestoreId, uploadTasks };
};

export const fetchReadings = async (
  storage: firebase.storage.Storage,
  geoDataURL: string
): Promise<Reading[]> => {
  const readingsResponse = await fetch(`${geoDataURL}/readings_geo.json`, {
    mode: "cors"
  });
  const readingsJson = await readingsResponse.json();
  return readingsJson.readings.map((reading: Reading) => {
    const readingStorage = getReadingStorageBucket(
      storage,
      reading.readingType,
      reading.id
    );
    return {
      ...reading,
      main: `${readingStorage}/${HIGH_RES_PHOTO_NAME}`,
      thumbnail: `${readingStorage}/${THUMBNAIL_PHOTO_NAME}`
    };
  });
};
