import { FunctionComponent, useState } from "react";
import classnames from "classnames";

import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import standardStyles from "standard.module.scss";

import CloseIcon from "@material-ui/icons/CloseRounded";
import AddIcon from "@material-ui/icons/AddRounded";
import BackIcon from "@material-ui/icons/ArrowBackRounded";

import { isIphoneWithNotchAndCordova, isIphoneAndCordova } from "../utils";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

declare global {
  interface Window {
    StatusBar: {
      styleDefault: () => void;
      styleLightContent: () => void;
    };
  }
}

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: "block",
    backgroundColor: "white",
    position: "relative",
    height: "calc(100% - 50px)",
    borderRadius: "5px"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: isIphoneWithNotchAndCordova()
      ? "calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 25px - 76px)"
      : "calc(100% - 25px - 76px)",
    position: "fixed",
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: isIphoneWithNotchAndCordova()
      ? "calc(env(safe-area-inset-top) + 25px)"
      : "25px",
    paddingBottom: isIphoneWithNotchAndCordova()
      ? "calc(env(safe-area-inset-bottom) + 76px)"
      : "76px",
    zIndex: 1300
  },
  main: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "-webkit-overflow-scrolling": "touch",
    height: "calc(100% - 56px)",
    overflowY: "auto"
  },
  mainWithoutHeader: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "-webkit-overflow-scrolling": "touch",
    height: "100%",
    overflowY: "auto"
  },
  iconButton: {
    marginRight: 20
  },
  closeButton: {
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: isIphoneWithNotchAndCordova()
      ? "calc(env(safe-area-inset-bottom) + 41px)"
      : "41px",
    zIndex: 1250,
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  label: {
    flexDirection: "column"
  },
  grow: {
    flexGrow: 1
  },
  notchTop: {
    paddingTop: isIphoneWithNotchAndCordova()
      ? "env(safe-area-inset-top)"
      : isIphoneAndCordova
      ? theme.spacing(1.5)
      : undefined
  },
  notchBottom: {
    paddingBottom: isIphoneWithNotchAndCordova()
      ? "env(safe-area-inset-bottom)"
      : "0px"
  },
  button: {
    color: theme.palette.primary.contrastText
  },
  buttonDisabled: {
    color: `${standardStyles.primaryTextDisabled} !important`
  }
}));

type CloseNavigationHandler = { handleClose: () => void };
type BackNavigationHandler = {
  handleBack: () => void;
  confirm?: boolean;
  closeIcon?: boolean;
};
type NavigationHandler = CloseNavigationHandler | BackNavigationHandler;

type AddAction = () => void;

interface Props {
  label: string;
  className?: string;
  navigationHandler: NavigationHandler;
  addAction?: AddAction;
  navigationIcon?: boolean;
  fillIPhoneScreen?: boolean;
  hideTopNavigation?: boolean;
}

interface PhotoPageProps {
  handlePrev: () => void;
  handleNext?: () => void;
  enableNext: boolean;
  nextClicked: boolean;
  error: boolean;
  sendFile: () => void;
  label: string;
  handleClose: () => void;
}

export const PhotoPageWrapper: FunctionComponent<PhotoPageProps> = ({
  handlePrev,
  handleNext,
  enableNext,
  nextClicked,
  error,
  sendFile,
  label,
  children,
  handleClose
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          {nextClicked ? (
            <BackIcon className={classes.iconButton} onClick={handlePrev} />
          ) : (
            <CloseIcon
              className={classes.iconButton}
              onClick={handleClose}
              data-test="Close"
            />
          )}
          <Typography className={classes.grow} variant="h6" color="inherit">
            {label}
          </Typography>
          {!nextClicked && (
            <Button
              disabled={!enableNext}
              className={classes.button}
              onClick={handleNext}
              classes={{ disabled: classes.buttonDisabled }}
              disableElevation
            >
              Next
            </Button>
          )}
          {nextClicked && (
            <Button
              disabled={error}
              className={classes.button}
              onClick={sendFile}
              classes={{ disabled: classes.buttonDisabled }}
              disableElevation
            >
              Upload
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.main}>{children}</div>
      <div className={classes.notchBottom} />
    </div>
  );
};

function isCloseNavigationHandler(
  navigationHandler: NavigationHandler
): navigationHandler is CloseNavigationHandler {
  if ((navigationHandler as CloseNavigationHandler).handleClose !== undefined) {
    return true;
  } else {
    return false;
  }
}

interface ConfirmBackProps {
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
}

const ConfirmBack = ({
  open,
  handleConfirm,
  handleCancel
}: ConfirmBackProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("go_back_warning_text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            handleCancel();
          }}
          color="primary"
          disableElevation
        >
          {t("cancel_button_text")}
        </Button>
        <Button
          onClick={(e) => {
            handleConfirm();
          }}
          color="primary"
          disableElevation
        >
          {t("go_back_button_text")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PageWrapper: FunctionComponent<Props> = ({
  children,
  label,
  navigationHandler,
  className,
  addAction,
  navigationIcon = true,
  fillIPhoneScreen = false,
  hideTopNavigation = false
}) => {
  const classes = useStyles();
  const [confirmBack, setConfirmBack] = useState(false);

  var navIcon;
  if (isCloseNavigationHandler(navigationHandler)) {
    const { handleClose } = navigationHandler;
    navIcon = (
      <Button
        classes={{ root: classes.closeButton, label: classes.label }}
        onClick={handleClose}
      >
        <CloseIcon />
        Close
      </Button>
    );
  } else {
    const { handleBack, confirm, closeIcon } = navigationHandler;
    const onClickBack = () => {
      if (confirm) {
        setConfirmBack(true);
      } else {
        handleBack();
      }
    };
    navIcon = closeIcon ? (
      <Button
        onClick={onClickBack}
        classes={{ root: classes.closeButton, label: classes.label }}
      >
        <CloseIcon />
        Close
      </Button>
    ) : (
      <Button
        onClick={onClickBack}
        classes={{ root: classes.closeButton, label: classes.label }}
      >
        <BackIcon />
        Back
      </Button>
    );
  }

  return (
    <div className={classes.container}>
      <Grow in={true}>
        <div className={classes.innerContainer}>
          {!hideTopNavigation ? (
            <AppBar color="primary" position="sticky" elevation={0}>
              <Toolbar>
                <Typography className={classes.grow} variant="h6">
                  {label}
                </Typography>
                {addAction !== undefined && <AddIcon onClick={addAction} />}
              </Toolbar>
            </AppBar>
          ) : fillIPhoneScreen ? null : (
            <div className={classes.notchTop} />
          )}
          <div
            className={classnames(
              !hideTopNavigation ? classes.main : classes.mainWithoutHeader,
              className
            )}
          >
            {children}
          </div>
        </div>
      </Grow>
      {navigationIcon ? navIcon : null}
      <ConfirmBack
        open={confirmBack}
        handleCancel={() => setConfirmBack(false)}
        handleConfirm={() => {
          setConfirmBack(false);
          // @ts-ignore
          navigationHandler.handleBack();
        }}
      />
    </div>
  );
};

export default PageWrapper;
