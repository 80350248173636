import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import styles from "standard.module.scss";

import { SurveyUploadHandle } from "types/Survey";

import { getStoreRatingURL } from "utils";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  errorMessage: {
    color: styles.red
  }
}));

export type UploadTask = firebase.storage.UploadTask;

type Props = {
  uploadHandle: SurveyUploadHandle;
  closeModal: () => void;
  onSuccessfulContinue: () => void;
};

const SurveyUploadDialog = ({
  uploadHandle,
  closeModal,
  onSuccessfulContinue
}: Props) => {
  const totalProgressTarget = 100 * uploadHandle.uploadTasks.length;

  const classes = useStyles();

  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const onUploadError = (error: firebase.storage.FirebaseStorageError) => {
    setError(error.message);
  };

  useEffect(() => {
    const onUploadProgress = () => {
      let newProgress = 0;
      uploadHandle.uploadTasks.forEach((uploadTask) => {
        newProgress += Math.round(
          (uploadTask.snapshot.bytesTransferred /
            uploadTask.snapshot.totalBytes) *
            100
        );
      });
      setProgress(newProgress);
    };

    uploadHandle.uploadTasks.forEach((uploadTask) => {
      if (uploadTask.snapshot.state === firebase.storage.TaskState.CANCELED) {
        setError(
          `There was an unexpected upload cancel. Please close and try again.`
        );
        return;
      } else if (
        uploadTask.snapshot.state === firebase.storage.TaskState.ERROR
      ) {
        setError(
          `There was an unexpected error with your upload. Please close and try again.`
        );
        return;
      }
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        onUploadProgress,
        onUploadError
      );
    });
  }, [uploadHandle]);

  const photosBeingUploaded = uploadHandle.uploadTasks.length !== 0;

  const finished = !photosBeingUploaded || progress === totalProgressTarget;

  let title = `The survey is being uploaded`;
  if (finished) {
    title = `Your results have been submitted`;
  } else if (error !== "") {
    title = `Your survey upload encountered an error`;
  }

  let description;
  if (finished) {
    description = (
      <>
        Thank you, we really appreciate your support and time in completing this
        survey.
        <br />
        <br />
        We’re now analysing all the information you’ve submitted and we will
        share the results very soon.
        <br />
        <br />
        You can find more ways to help below.
        <br />
        <br />
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            href="https://planetpatrol.co/clean-ups/"
            target="_blank"
            disableElevation
          >
            I’m interested in taking part
          </Button>
        </Box>
        <br />
        <Typography variant="h6">Enjoying Planet Patrol?</Typography>
        Your app store review helps spread the word and grow the Planet Patrol
        community.
        <br />
        <br />
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            href={getStoreRatingURL()}
            target="_blank"
            disableElevation
          >
            Rate us
          </Button>
        </Box>
      </>
    );
  } else if (error !== "") {
    description = <>Please click cancel and attempt to submit again.</>;
  } else {
    description = (
      <>
        Please wait while we upload your survey data.
        <br />
        <br />
        You can press cancel to return to the form.
      </>
    );
  }

  const cancelUpload = () => {
    uploadHandle.uploadTasks.forEach((uploadTask) => uploadTask.cancel());
    closeModal();
  };

  let buttons;
  if (error !== "") {
    buttons = (
      <Button onClick={cancelUpload} color="secondary" disabled={finished}>
        Cancel
      </Button>
    );
  } else if (finished) {
    buttons = (
      <Button onClick={onSuccessfulContinue} color="primary" disableElevation>
        Close
      </Button>
    );
  } else {
    buttons = (
      <Button onClick={cancelUpload} color="secondary" disabled={finished} disableElevation>
        Cancel
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {error !== "" && (
          <DialogContentText className={classes.errorMessage}>
            {error}
          </DialogContentText>
        )}
      </DialogContent>
      {uploadHandle.uploadTasks.length === 0 && (
        <LinearProgress
          variant="determinate"
          color="primary"
          value={progress}
        />
      )}
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
};

export default SurveyUploadDialog;
