import { useHistory } from "react-router";

import BetaControlPage from "pages/admin/beta-control/BetaControl";
import PageWrapper from "components/PageWrapper";

export default function BetaControlRoute() {
  const history = useHistory();
  return (
    <PageWrapper
      label="Beta Control"
      navigationHandler={{ handleBack: () => history.push("/") }}
    >
      <BetaControlPage />
    </PageWrapper>
  );
}
