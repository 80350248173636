import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { UploadPage } from "../ReadingUpload";
import {
  NITRATE_READING_TYPE,
  NitrateFormData
} from "../../../../types/Readings/Nitrate";
import {
  PHOSPHATE_READING_TYPE,
  PhosphateFormData
} from "../../../../types/Readings/Phosphate";
import {
  COLIFORMS_READING_TYPE,
  ColiformsFormData
} from "../../../../types/Readings/Coliforms";
import { PH_READING_TYPE, PhFormData } from "../../../../types/Readings/Ph";
import {
  TEMPERATURE_READING_TYPE,
  TemperatureFormData
} from "../../../../types/Readings/Temperature";
import { AnyReadingFormData } from "../../../../types/Readings/Shared";
import { photoIsMetaData } from "../../../../types/Photo";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  heading: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(2)}px`
  },
  editButton: {
    float: "right"
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  photoWrapper: {
    width: "100%",
    textAlign: "center",
    paddingBottom: `${theme.spacing(2)}px`
  },
  photoPreview: {
    maxWidth: "100%",
    maxHeight: "180px"
  },
  uploadError: {
    color: "red"
  },
  button: {
    width: "100%",
    marginTop: `${theme.spacing(1)}px`
  }
}));

type Props = {
  reading: AnyReadingFormData;
  onEdit: (page: UploadPage) => void;
  onSubmit: () => void;
  uploadError?: string;
  isUploading: boolean;
};

const UploadSummary = ({
  reading,
  onEdit,
  onSubmit,
  uploadError,
  isUploading
}: Props) => {
  const classes = useStyles();

  const date = new Date(reading.dateTime);
  const dateString = date.toLocaleDateString();
  const timeString = date.toLocaleTimeString();

  let readingDataFormat;
  switch (reading.readingType) {
    case NITRATE_READING_TYPE:
      const nitrateData = reading as NitrateFormData;
      readingDataFormat = (
        <div className={classes.text}>
          {nitrateData.nitrateReading !== undefined && (
            <>
              Nitrate reading:{" "}
              {`${nitrateData?.nitrateReading}${nitrateData?.nitrateUnits}`}
            </>
          )}
          {nitrateData.nitriteReading !== undefined && (
            <>
              <br />
              Nitrite reading:{" "}
              {`${nitrateData?.nitriteReading}${nitrateData?.nitriteUnits}`}
            </>
          )}
        </div>
      );
      break;
    case PHOSPHATE_READING_TYPE:
      const phosphateData = reading as PhosphateFormData;
      readingDataFormat = (
        <div className={classes.text}>
          Phosphate reading:{" "}
          {phosphateData.phosphateReading.toString() +
            phosphateData.phosphateUnits}
        </div>
      );
      break;
    case COLIFORMS_READING_TYPE:
      const coliformsData = reading as ColiformsFormData;
      readingDataFormat = (
        <>
          <div className={classes.text}>
            {coliformsData.coliforms !== undefined && (
              <>
                Total coliforms:{" "}
                {coliformsData.coliforms ? "Positive" : "Negative"}
                <br />
              </>
            )}
            {coliformsData.ecoli !== undefined && (
              <>
                E. coli: {coliformsData.ecoli ? "Positive" : "Negative"}
                <br />
              </>
            )}
            {coliformsData.additionalReadings !== undefined &&
              coliformsData?.additionalReadings !== "" && (
                <>Additional readings: {coliformsData.additionalReadings}</>
              )}
          </div>
          {coliformsData.postIncubationPhoto && (
            <div className={classes.photoWrapper}>
              <img
                src={
                  photoIsMetaData(coliformsData.postIncubationPhoto)
                    ? coliformsData.postIncubationPhoto.imgSrc
                    : coliformsData.postIncubationPhoto
                }
                className={classes.photoPreview}
                alt={""}
              />
            </div>
          )}
        </>
      );
      break;
    case PH_READING_TYPE:
      const phData = reading as PhFormData;
      readingDataFormat = (
        <div className={classes.text}>pH reading: {phData.ph}</div>
      );
      break;
    case TEMPERATURE_READING_TYPE:
      const temperatureData = reading as TemperatureFormData;
      readingDataFormat = (
        <div className={classes.text}>
          Temperature reading: {temperatureData.temperature}
        </div>
      );
      break;
    default:
      readingDataFormat = <div>{reading.toString()}</div>;
      break;
  }

  const EditButton = (uploadPage: UploadPage) => {
    return (
      <div className={classes.editButton} onClick={() => onEdit(uploadPage)}>
        Edit
      </div>
    );
  };

  return (
    <div>
      <div className={classes.text}>
        Thanks for uploading your results. You can review them below before
        submitting.
      </div>
      {reading.photo && (
        <>
          <div className={classes.heading}>
            Photo
            {EditButton(UploadPage.PhotoUpload)}
          </div>
          <div className={classes.photoWrapper}>
            <img
              src={
                reading.photo &&
                (photoIsMetaData(reading.photo)
                  ? reading.photo.imgSrc
                  : reading.photo)
              }
              className={classes.photoPreview}
              alt={""}
            />
          </div>
        </>
      )}
      <div className={classes.heading}>
        Waterway
        {EditButton(UploadPage.WaterwayName)}
      </div>
      <div className={classes.text}>Body of water: {reading.waterwayName}</div>
      <div className={classes.heading}>
        Location and time
        {EditButton(UploadPage.PhotoGeotag)}
      </div>
      <div className={classes.text}>
        Location: ({reading.location.latitude},{reading.location.longitude}){" "}
        <br />
        Time: {timeString}
        <br />
        Date: {dateString}
        <br />
      </div>
      <div className={classes.heading}>
        Test results
        {EditButton(UploadPage.TestKit)}
      </div>
      <div className={classes.text}>
        Test kit: {reading.testKitName}
        {readingDataFormat}
      </div>
      {uploadError !== "" && (
        <div className={classes.uploadError}>{uploadError}</div>
      )}
      <Button
        onClick={onSubmit}
        color="primary"
        className={classes.button}
        variant="contained"
        disabled={isUploading}
        size="large"
        disableElevation
      >
        Submit results
      </Button>
    </div>
  );
};

export default UploadSummary;
