import React, { useContext, useEffect, useState } from "react";

import "firebase/compat/firestore";

import {
  EMPTY_FEATURES_CONTAINER,
  FeaturesContainer,
  merge
} from "../types/Feature";

import { useIncidents } from "./IncidentsProvider";
import { usePhotos } from "./PhotosProvider";
import { useReadings } from "./ReadingsProvider";

export type FeaturesProviderData = {
  features: FeaturesContainer;
  reload: () => void;
};

export const FeaturesContext = React.createContext<FeaturesProviderData>({
  features: EMPTY_FEATURES_CONTAINER,
  reload: () => {}
});

type Props = {
  children?: React.ReactChild[];
};

export const FeaturesProvider = ({ children }: Props) => {
  const photos = usePhotos();
  const readings = useReadings();
  const incidents = useIncidents();

  const [data, setData] = useState<FeaturesProviderData>({
    features: merge(
      merge(photos.features, readings.features),
      incidents.features
    ),
    reload: async () => {
      await photos.reload();
      await readings.reload();
      await incidents.reload();
    }
  });

  useEffect(() => {
    setData((prevData) => {
      return {
        ...prevData,
        features: merge(
          merge(photos.features, readings.features),
          incidents.features
        )
      };
    });
  }, [photos, readings, incidents]);

  return (
    <FeaturesContext.Provider value={data}>{children}</FeaturesContext.Provider>
  );
};

export const useFeatures = () => useContext(FeaturesContext);
