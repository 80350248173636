import React, { useState, useContext, useEffect } from "react";
import { GPSLocation } from "types/GPSLocation";
import config from "custom/config";

import { Geolocation } from "@capacitor/geolocation";

const LocationContext = React.createContext<GPSLocation | undefined>(undefined);

const LocationProvider: React.FC<{}> = ({ children }) => {
  const [location, setLocation] = useState<GPSLocation>({
    latitude: config.CENTER[1],
    longitude: config.CENTER[0],
    online: false,
    updated: undefined
  });

  useEffect(() => {
    // TODO: remove with cordova
    if (!Geolocation.watchPosition) {

      if (!navigator?.geolocation?.watchPosition) {
        return;
      }
      const subscription = navigator.geolocation.watchPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            online: true,
            updated: new Date(position.timestamp) // it indicate the freshness of the location.
          });
        },
        (error) => {
          console.log("Error: ", error.message);

          setLocation((currentLocation) => {
            return {
              ...currentLocation,
              online: false
            };
          });
        },
        {
          enableHighAccuracy: true
        }
      );

      return () => navigator.geolocation.clearWatch(subscription);
    } else {

      const syncWatchPosition = async () => {
        const watcherId = await Geolocation.watchPosition(
          { enableHighAccuracy: true },
          (position, error) => {
            if (!error) {
              setLocation({
                latitude: position?.coords.latitude || 0,
                longitude: position?.coords.longitude || 0,
                online: true,
                updated: new Date(position?.timestamp || 0) // it indicate the freshness of the location.
              });
            } else {
              console.log("Error: ", error.message);

              setLocation((currentLocation) => {
                return {
                  ...currentLocation,
                  online: false
                };
              });
            }
          }
        );
        return watcherId;
      };

      let syncWatcherId = "";
      syncWatchPosition().then((watcherId) => {
        syncWatcherId = watcherId;
      });

      return () => Geolocation.clearWatch({ id: syncWatcherId });
    }
  }, []);

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

export const useGPSLocation = () => useContext(LocationContext);

export default LocationProvider;
