import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import { IncidentUploadHandle } from "types/Incident";

import styles from "standard.module.scss";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  errorMessage: {
    color: styles.red
  }
}));

export type UploadTask = firebase.storage.UploadTask;

type Props = {
  uploadHandle: IncidentUploadHandle;
  closeModal: () => void;
  onSuccessfulContinue: () => void;
};

const IncidentUploadDialog = ({
  uploadHandle,
  closeModal,
  onSuccessfulContinue
}: Props) => {
  const totalProgressTarget = 100 * uploadHandle.uploadTasks.length;

  const classes = useStyles();

  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const onUploadError = (error: firebase.storage.FirebaseStorageError) => {
    console.error(error);
    setError(error.message);
  };

  useEffect(() => {
    const onUploadProgress = () => {
      let newProgress = 0;
      uploadHandle.uploadTasks.forEach((uploadTask) => {
        newProgress += Math.round(
          (uploadTask.snapshot.bytesTransferred /
            uploadTask.snapshot.totalBytes) *
            100
        );
      });
      setProgress(newProgress);
    };

    uploadHandle.uploadTasks.forEach((uploadTask) => {
      if (uploadTask.snapshot.state === firebase.storage.TaskState.CANCELED) {
        console.error(`Upload dialog opened for an already cancelled task.`);
        setError(
          `There was an unexpected upload cancel. Please close and try again.`
        );
        return;
      } else if (
        uploadTask.snapshot.state === firebase.storage.TaskState.ERROR
      ) {
        console.error(
          `Upload dialog opened for a task with an existing error.`
        );
        setError(
          `There was an unexpected error with your upload. Please close and try again.`
        );
        return;
      }
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        onUploadProgress,
        onUploadError
      );
    });
  }, [uploadHandle]);

  const photosBeingUploaded = uploadHandle.uploadTasks.length !== 0;
  const finished = !photosBeingUploaded || progress === totalProgressTarget;
  let title = `The pollution incident is being uploaded`;
  if (finished) {
    title = `Thanks for submitting your data`;
  } else if (error !== "") {
    title = `Your upload encountered an error`;
  }

  let description;
  if (finished) {
    description = (
      <>
        It's been added to Planet Patrol's national database and is available to
        view on the pollution map.
      </>
    );
  } else if (error !== "") {
    description = <>Please click cancel and attempt the upload again.</>;
  } else {
    description = (
      <>
        Please wait while we upload your incident.
        <br />
        <br />
        You can press cancel to return to the form.
      </>
    );
  }

  const cancelUpload = () => {
    uploadHandle.uploadTasks.forEach((uploadTask) => uploadTask.cancel());
    closeModal();
  };

  let buttons;
  if (error !== "") {
    buttons = (
      <Button
        onClick={cancelUpload}
        color="secondary"
        disabled={finished}
        disableElevation
      >
        Cancel
      </Button>
    );
  } else if (finished) {
    buttons = (
      <Button onClick={onSuccessfulContinue} color="primary" disableElevation>
        Close
      </Button>
    );
  } else {
    buttons = (
      <Button
        onClick={cancelUpload}
        color="secondary"
        disabled={finished}
        disableElevation
      >
        Cancel
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {error !== "" && (
          <DialogContentText className={classes.errorMessage}>
            {error}
          </DialogContentText>
        )}
      </DialogContent>
      {uploadHandle.uploadTasks.length === 0 && (
        <LinearProgress
          variant="determinate"
          color="primary"
          value={progress}
        />
      )}
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
};

export default IncidentUploadDialog;
