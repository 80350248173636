import { ImageMetaData } from "../Photo";
import {
  getReadingMapDataEntries,
  MapReadingData,
  Reading,
  ReadingFormData
} from "./Base";

export const COLIFORMS_READING_TYPE = "coliforms";
export const COLIFORMS_POST_INCUBATION_PHOTO_FOLDER = "post_incubation";
export const COLIFORMS_VIDEO_ID = "InfBH4M5BiA";

export type ColiformsInputs = {
  coliforms?: boolean;
  ecoli?: boolean;
  additionalReadings?: string;
  postIncubationPhoto?: ImageMetaData | string;
};

export type ColiformsFormData = ReadingFormData & ColiformsInputs;
export type Coliforms = Reading & ColiformsInputs;

export const ColiformsReadingMapLabel = "Coliforms reading";
export const coliformsToMapData = (coliforms: Coliforms): MapReadingData => {
  return {
    ...coliforms,
    data: new Map<string, string>([
      ...getReadingMapDataEntries(coliforms),
      [ColiformsReadingMapLabel, coliforms.coliforms ? "Positive" : "Negative"]
    ])
  };
};
