import { Switch, Route, Redirect } from "react-router-dom";

import { useUser } from "providers/UserProvider";

import linkToBetaControl from "./beta-control/link";
import BetaControlRoute from "./beta-control/Route";
import linkToBrands from "./brands/link";
import BrandsRoute from "./brands/Route";
import linkToCategories from "./categories/link";
import CategoriesRoute from "./categories/Route";
import linkToMissionControl from "./mission-control/link";
import MissionControlRoute from "./mission-control/Route";
import linkToReadingsDownload from "./readings/link";
import ReadingsDownloadRoute from "./readings/Route";

export default function AdminRouter() {
  const { user } = useUser();

  if (user === undefined || (user && !user.isAdmin)) {
    return <Redirect to={"/"} />;
  }

  return (
    <Switch>
      <Route path={linkToCategories()}>
        <CategoriesRoute />
      </Route>
      <Route path={linkToBrands()}>
        <BrandsRoute />
      </Route>
      <Route path={linkToBetaControl()}>
        <BetaControlRoute />
      </Route>
      <Route path={linkToMissionControl()}>
        <MissionControlRoute />
      </Route>
      <Route path={linkToReadingsDownload()}>
        <ReadingsDownloadRoute />
      </Route>
      <Redirect to={"/"} />
    </Switch>
  );
}
