import { useContext, useState } from "react";

import { Link } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation, Trans } from "react-i18next";

import PageWrapper from "../../components/PageWrapper";
import Feature from "types/Feature";
import Geojson from "types/Geojson";
import User from "types/User";
import { Config } from "custom/config";
import { isMissionEnabled } from "custom/featuresFlags";
import {
  MissionsContext,
  MissionsProviderData
} from "../../providers/MissionsProvider";
import MissionThumbnail from "../missions/MissionThumbnail";
import { linkToMissionsPage } from "../../routes/missions/links";

import styles from "standard.module.scss";
import {
  MissionFirestoreData,
  userOnMissionLeaderboard
} from "../../types/Missions";

import { dbFirebase } from "features/firebase";

import { signOut } from "./../../features/firebase/authFirebase";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
    height: 100,
    width: 100
  },
  row: {
    display: "flex",
    width: "100%"
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2)
  },
  missionsWrapper: {
    alignItems: "left",
    width: "100%",
    boxSizing: "border-box",
    padding: 20
  },
  missionsTitle: {
    paddingBottom: 10
  },
  missionJoinPrompt: {
    paddingTop: 20,
    color: styles.darkGrey,
    fontWeight: "bold"
  },
  link: {
    color: theme.palette.primary.main
  }
}));

interface Props {
  user: User;
  geojson: Geojson;
  handlePhotoClick: (feature: Feature) => void;
  handleClose: () => void;
  config: Config;
}

export default function AccountPage({ user, handleClose, config }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);

  const missionData = useContext<MissionsProviderData | undefined>(
    MissionsContext
  );

  const missions = missionData?.missions?.filter(
    (mission: MissionFirestoreData) =>
      userOnMissionLeaderboard(mission, user.id) && !mission.hidden
  );

  const handleDeleteAccountInitialRequest = () => {
    setDeleteAccountDialogOpen(true);
  };

  const handleDeleteAccountDialogClose = () => {
    setDeleteAccountDialogOpen(false);
  };

  const handleDeleteAccountConfirmation = () => {
    setDeleteAccountDialogOpen(false);
    let data = {} as any;
    data.feedback = "Request to delete account";
    data.resolved = false;
    data.email = user.email;

    dbFirebase
      .writeFeedback(data)
      .then((res) => {
        setDeleteAccountDialogOpen(false);
        signOut();
      })
      .catch((err) => {
        setDeleteAccountDialogOpen(false);
      });
  };

  return (
    <PageWrapper
      label={t(config.PAGES.account.label)}
      navigationHandler={{ handleClose }}
    >
      <div className={classes.wrapper}>
        <Avatar
          className={classes.avatar}
          alt="profile-image"
          src={user.photoURL}
        />
        <Typography gutterBottom variant="h5">
          {user.displayName}
        </Typography>
        <Typography component="p">{user.email}</Typography>
        <Typography>{user.location}</Typography>
        <Typography>{user.description}</Typography>

        <br />

        {isMissionEnabled() && (
          <div className={classes.missionsWrapper}>
            <Typography variant="h6" className={classes.missionsTitle}>
              {t("account_my_missions")}
            </Typography>

            {missions === undefined || missions?.length === 0 ? (
              <Typography className={classes.missionJoinPrompt}>
                <Trans i18nKey="account_mission_placeholder">
                  You haven't joined any missions yet!
                  <br />
                  Tap{" "}
                  <Link to={linkToMissionsPage()} className={classes.link}>
                    here
                  </Link>{" "}
                  to find a mission to join.
                </Trans>
              </Typography>
            ) : (
              missions?.map((mission) => (
                <MissionThumbnail key={mission.id} mission={mission} />
              ))
            )}
          </div>
        )}

        <div>
          <Button
            variant="outlined"
            disableElevation
            onClick={handleDeleteAccountInitialRequest}
            color="primary"
          >
            Delete account
          </Button>
          <Dialog
            open={deleteAccountDialogOpen}
            onClose={handleDeleteAccountDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete your account?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Requesting to delete your account will sign you out of this app
                and forward the deletion request to our team. This will be
                completed as soon as possible, and within 1 month.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteAccountDialogClose} disableElevation>
                Cancel
              </Button>
              <Button
                onClick={handleDeleteAccountConfirmation}
                disableElevation
              >
                Yes, delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </PageWrapper>
  );
}
