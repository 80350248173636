import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import styles from "../../standard.module.scss";

import {
  AnyReadingFormData,
  ReadingUploadHandle
} from "../../types/Readings/Shared";

import User from "types/User";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";

import ReadingActivityChart from "./upload/common/ReadingActivityChart";
import ReadingValuesChart from "./upload/common/ReadingValuesChart";

import { getDaysUntilNextNotification } from "utils/notifications";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import { scheduleWaterQualityNotificationForUser } from "utils/waterReadingNotifications";

import { useUser } from "../../providers/UserProvider";

import { ScheduleEvery } from "@capacitor/local-notifications";
import { removeUserNotification } from "features/firebase/userNotifications";
import { getReadingResultContextFromReadingData } from "utils/waterReadingAnalytics";
import { reassessUserBadges } from "utils/badges";
import { useReadings } from "providers/ReadingsProvider";
import { usePhotos } from "providers/PhotosProvider";
import UserBadges from "pages/dashboard/common/UserBadges";
import { updateUserBadges } from "features/firebase/userBadges";

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: styles.red
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export type UploadTask = firebase.storage.UploadTask;

type Props = {
  readingData: AnyReadingFormData | null;
  uploadHandle: ReadingUploadHandle;
  closeModal: () => void;
  user: User | undefined | null;
  onSuccessfulContinue: () => void;
};

const ReadingUploadDialog = ({
  readingData,
  uploadHandle,
  closeModal,
  user,
  onSuccessfulContinue
}: Props) => {
  const waterReadings = useReadings();
  const litterPhotos = usePhotos();
  const { setUser } = useUser();

  const totalProgressTarget = 100 * uploadHandle.uploadTasks.length;

  const waterNotification = user?.notifications?.find(
    (n) => n.title === "Water quality reading reminder"
  );
  let daysUntilNextTest = 0;
  if (waterNotification) {
    daysUntilNextTest = getDaysUntilNextNotification(waterNotification);
  }

  const classes = useStyles();

  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [scheduleReminder, setScheduleReminder] = useState<boolean | undefined>(
    false
  );
  const [reminderFrequency, setReminderFrequency] = useState<ScheduleEvery>(
    "two-week" as ScheduleEvery
  );

  const onUploadError = (error: firebase.storage.FirebaseStorageError) => {
    console.error(error);
    setError(error.message);
  };

  useEffect(() => {
    // Refresh the user's badges
    if (!user) return;
    const badges =
      reassessUserBadges(user, "water", litterPhotos, waterReadings) || [];
    updateUserBadges(user.id, badges);
    setUser({
      ...user,
      badges: badges
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetReminder = async () => {
    if (user) {
      const notifications = await scheduleWaterQualityNotificationForUser(
        user?.id,
        reminderFrequency
      );
      setUser({
        ...user,
        notifications: notifications
      });
    }
  };

  const handleCancelReminder = async () => {
    if (user) {
      const notifications = await removeUserNotification(
        user?.id,
        "Water quality reading reminder"
      );
      setUser({
        ...user,
        notifications: notifications
      });
    }
  };

  useEffect(() => {
    const onUploadProgress = () => {
      let newProgress = 0;
      uploadHandle.uploadTasks.forEach((uploadTask) => {
        newProgress += Math.round(
          (uploadTask.snapshot.bytesTransferred /
            uploadTask.snapshot.totalBytes) *
            100
        );
      });
      setProgress(newProgress);
    };

    uploadHandle.uploadTasks.forEach((uploadTask) => {
      if (uploadTask.snapshot.state === firebase.storage.TaskState.CANCELED) {
        console.error(`Upload dialog opened for an already cancelled task.`);
        setError(
          `There was an unexpected upload cancel. Please close and try again.`
        );
        return;
      } else if (
        uploadTask.snapshot.state === firebase.storage.TaskState.ERROR
      ) {
        console.error(
          `Upload dialog opened for a task with an existing error.`
        );
        setError(
          `There was an unexpected error with your upload. Please close and try again.`
        );
        return;
      }
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        onUploadProgress,
        onUploadError
      );
    });
  }, [uploadHandle]);

  const photosBeingUploaded = uploadHandle.uploadTasks.length !== 0;
  const finished = !photosBeingUploaded || progress === totalProgressTarget;
  let title = `Your reading is being uploaded`;
  if (finished) {
    title = `Thank you!`;
  } else if (error !== "") {
    title = `Your upload encountered an error`;
  }

  const readingDescription = getReadingResultContextFromReadingData(
    readingData as AnyReadingFormData
  );

  let description;
  if (finished) {
    description = (
      <>
        {page === 1 ? (
          <>
            <Typography variant="body1">
              Your reading has been submitted.
            </Typography>
            <br />
            <Typography variant="body1">{readingDescription}</Typography>
            <ReadingValuesChart
              reading={readingData}
              readingType={readingData?.readingType || null}
              postcode={null}
            />
            <ListSubheader disableSticky disableGutters>
              Scheduling
            </ListSubheader>
            <Typography>
              Do you want to schedule a reminder for your next test?
            </Typography>

            <Button
              variant="outlined"
              disableElevation
              onClick={onSuccessfulContinue}
              className={classes.button}
            >
              Skip
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => {
                setPage(2);
                setScheduleReminder(true);
              }}
            >
              Next
            </Button>
          </>
        ) : null}

        {page === 2 ? (
          <>
            {scheduleReminder &&
            user &&
            (!user.notifications || user.notifications.length === 0) ? (
              <>
                <ListSubheader disableGutters disableSticky>
                  Schedule your next tests
                </ListSubheader>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="schedule-radio-buttons-group-label"
                    defaultValue={reminderFrequency}
                    value={reminderFrequency}
                    name="radio-buttons-group"
                    onChange={(e) =>
                      setReminderFrequency(e.target.value as ScheduleEvery)
                    }
                  >
                    <FormControlLabel
                      value="two-weeks"
                      control={<Radio />}
                      label="Every 2 weeks"
                    />
                    <FormControlLabel
                      value="month"
                      control={<Radio />}
                      label="Every month"
                    />
                    {user && user?.isTester ? (
                      <FormControlLabel
                        value="hour"
                        control={<Radio />}
                        label="Every hour"
                      />
                    ) : null}
                  </RadioGroup>
                </FormControl>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleSetReminder}
                >
                  Schedule
                </Button>
              </>
            ) : (
              <>
                <ListSubheader disableGutters disableSticky>
                  Next test in
                </ListSubheader>
                <Typography
                  component="p"
                  variant="h5"
                >{`${daysUntilNextTest} days`}</Typography>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  disableElevation
                  className={classes.button}
                  onClick={handleCancelReminder}
                >
                  Cancel reminder
                </Button>
              </>
            )}
            <ListSubheader disableGutters disableSticky>
              Your activity
            </ListSubheader>
            <ReadingActivityChart
              userId={user?.id}
              readingTypes={["water"]}
              postcode={null}
            />

            {user && (
              <>
                <ListSubheader disableSticky disableGutters>
                  Your badges
                </ListSubheader>
                <UserBadges user={user} />
              </>
            )}
          </>
        ) : null}
      </>
    );
  } else if (error !== "") {
    description = (
      <Typography>Please click cancel and attempt the upload again.</Typography>
    );
  } else {
    description = (
      <>
        <Typography>Please wait while we upload your reading.</Typography>
        <Typography>You can press cancel to return to the form.</Typography>
      </>
    );
  }

  const cancelUpload = () => {
    uploadHandle.uploadTasks.forEach((uploadTask) => uploadTask.cancel());
    closeModal();
  };

  let buttons;
  if (error !== "") {
    buttons = (
      <Button
        onClick={cancelUpload}
        color="secondary"
        disabled={finished}
        disableElevation
      >
        Cancel
      </Button>
    );
  } else if (finished) {
    buttons = (
      <Button
        onClick={onSuccessfulContinue}
        color="primary"
        variant="outlined"
        disableElevation
      >
        Close
      </Button>
    );
  } else {
    buttons = (
      <Button
        onClick={cancelUpload}
        color="secondary"
        disabled={finished}
        disableElevation
      >
        Cancel
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description}
        {error !== "" && (
          <DialogContentText className={classes.errorMessage}>
            {error}
          </DialogContentText>
        )}
      </DialogContent>
      {uploadHandle.uploadTasks.length === 0 && (
        <LinearProgress
          variant="determinate"
          color="primary"
          value={progress}
        />
      )}
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
};

export default ReadingUploadDialog;
