import { useTranslation } from "react-i18next";

import { UserPieceRankTable } from "./index";

import User from "types/User";
import { StatsUser } from "types/Stats";

import PageWrapper from "../PageWrapper";

type Props = {
  usersLeaderboard: StatsUser[];
  user: User | undefined | null;
  handleClose: () => void;
  label: string;
};

export default function Leaderboard({
  user,
  usersLeaderboard,
  handleClose,
  label
}: Props) {
  const { t } = useTranslation();
  return (
    <PageWrapper navigationHandler={{ handleClose }} label={t(label)}>
      <UserPieceRankTable
        usersLeaderboard={usersLeaderboard}
        user={user}
        allowZeroPieces={true}
      />
    </PageWrapper>
  );
}
