export function linkToRegister() {
  return "/register";
}

export function linkToRegisterWithRedirectOnSuccess(
  redirectToOnSuccess: string
) {
  return {
    pathname: linkToRegister(),
    state: {
      redirectToOnSuccess
    }
  };
}
