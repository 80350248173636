import { Category } from "./Category";
import { LatLong } from "./GPSLocation";

export type ImageMetaData = {
  imgSrc: string;
  imgExif: any;
  imgLocation: LatLong;
  imgIptc: any;
};

export const photoIsMetaData = (
  str: ImageMetaData | string
): str is ImageMetaData => {
  return (str as ImageMetaData).imgSrc !== undefined;
};

type Photo = {
  id: any;
  main: any;
  thumbnail: any;
  created?: Date;
  updated: Date;
  moderated?: Date;
  owner_id: string;
  pieces: number;
  location: firebase.default.firestore.GeoPoint;
  published: boolean;
  categories: Category[];
  missions: string[];
};

export default Photo;
