import firebase from "firebase/compat/app";

import {
  PHOTO_UPLOAD_NAME,
  SURVEYS_PHOTO_STORAGE_BUCKET
} from "../../../shared/utils";

import { ImageMetaData } from "../../../types/Photo";

export const uploadSurveyPhoto = (
  storage: firebase.storage.Storage,
  firestoreId: string,
  photo: ImageMetaData,
  subFolder?: string
): firebase.storage.UploadTask | undefined => {
  try {
    let photoStorageRef = storage
      .ref()
      .child(SURVEYS_PHOTO_STORAGE_BUCKET)
      .child(firestoreId);

    if (subFolder !== undefined) {
      photoStorageRef = photoStorageRef.child(subFolder);
    }

    photoStorageRef = photoStorageRef.child(PHOTO_UPLOAD_NAME);

    const base64Image = photo.imgSrc.split(",")[1];
    return photoStorageRef.putString(base64Image, "base64", {
      contentType: "image/jpeg"
    });
  } catch (err) {
    console.error(`Failed to upload survey photo: ${err}`);
  }
};
