import { getLastSixMonths } from "./utils";
import { FeaturesProviderData } from "providers/FeaturesProvider";

import Feature from "types/Feature";

import { toOutcode } from "postcode";

import { MapReadingData, ReadingFormData } from "types/Readings/Base";
import { AnyReadingFormData } from "types/Readings/Shared";

import styles from "standard.module.scss";

import {
  NITRATE_READING_TYPE,
  NitrateFormData,
  NitrateReadingMapLabel
} from "./../types/Readings/Nitrate";
import {
  PHOSPHATE_READING_TYPE,
  PhosphateFormData,
  PhosphateReadingMapLabel
} from "./../types/Readings/Phosphate";
import {
  PH_READING_TYPE,
  PhFormData,
  PhReadingMapLabel
} from "./../types/Readings/Ph";
import {
  TEMPERATURE_READING_TYPE,
  TemperatureFormData,
  TemperatureReadingMapLabel
} from "./../types/Readings/Temperature";
import {
  COLIFORMS_READING_TYPE,
  ColiformsFormData,
  ColiformsReadingMapLabel
} from "./../types/Readings/Coliforms";

export const getReadingDate = (reading: Feature) => {
  if (!reading?.properties) return 0;
  const readingData = reading.properties as MapReadingData;
  if (readingData.dateTime) {
    return new Date(readingData.dateTime);
  }
  return 0;
};

export const getReadingTime = (reading: Feature) => {
  if (!reading?.properties) return 0;
  const readingDate = getReadingDate(reading);
  if (readingDate !== 0) {
    return (readingDate as Date).getTime();
  }
  return 0;
};

export const getDaysSinceReading = (reading: Feature) => {
  const readingTime = getReadingTime(reading);
  if (readingTime !== 0) {
    const daysSinceReading = Math.floor(
      (new Date().getTime() - readingTime) / (1000 * 60 * 60 * 24)
    );
    return daysSinceReading;
  }
  return 0;
};

export const getCurrentReadingsStreak = (userReadingPhotos: Feature[]) => {
  const intervalDays = 30;
  let currentStreak = 0;

  if (userReadingPhotos && userReadingPhotos.length > 0) {
    const readingsClone = [...userReadingPhotos];
    readingsClone.sort((a, b) => {
      return getReadingTime(b) - getReadingTime(a);
    });

    let index = 0;
    while (
      index < readingsClone.length &&
      getDaysSinceReading(readingsClone[index]) <= intervalDays
    ) {
      index++;
      currentStreak++;
    }
  }
  return currentStreak;
};

export const getMaximumReadingsStreak = (userReadingPhotos: Feature[]) => {
  const intervalDays = 30;
  let maxStreak = 0;

  if (userReadingPhotos.length > 0) {
    const readingsClone = [...userReadingPhotos];
    readingsClone.sort((a, b) => {
      return getReadingTime(b) - getReadingTime(a);
    });

    let currentStreak = 0;
    readingsClone.forEach((reading) => {
      if (getDaysSinceReading(reading) <= intervalDays) {
        currentStreak++;
        if (currentStreak > maxStreak) maxStreak = currentStreak;
      } else {
        currentStreak = 0;
      }
    });
  }

  return maxStreak;
};

export const getReadingsByLastSixMonths = (
  readings: Feature[],
  userId: string | null = null,
  postcode: string | null = null
): any[] => {
  let months = [...getLastSixMonths()];
  months.forEach((month) => (month.waterReadings = 0));
  if (readings && readings.length > 0) {
    readings.forEach((reading) => {
      const readingDate = getReadingDate(reading);
      let readingUserId = "";
      if (reading.properties && (reading.properties as any).userId) {
        readingUserId = (reading.properties as any).userId;
      }

      // If we have a userId and it doesn't match the reading's userId, skip this reading
      if (userId && userId !== readingUserId) return;

      // If we have a postcode and it doesn't match the reading's postcode, skip this reading
      const readingPostcode = (reading.properties as ReadingFormData).geodata
        ?.postcode;
      const readingOutcode = readingPostcode
        ? toOutcode(readingPostcode)
        : null;
      const userOutcode = postcode ? toOutcode(postcode) : null;
      if (
        postcode &&
        userOutcode &&
        (!readingPostcode || !readingOutcode || readingOutcode !== userOutcode)
      ) {
        return;
      }

      if (readingDate !== 0 && (!userId || userId === readingUserId)) {
        months.forEach((month) => {
          if (
            readingDate.getFullYear() === month.year &&
            readingDate.getMonth() === month.monthNumber
          ) {
            month.waterReadings++;
          }
        });
      }
    });
  }
  return months;
};

export const extractNumericReading = (
  data: Map<string, string>,
  readingName: string
): string => {
  const numberExtractRegex = /\d+/g;
  const reading = data.get(readingName);
  if (!reading) return "";
  const readingRegexMatches = reading.match(numberExtractRegex);
  if (!readingRegexMatches) return "";
  return readingRegexMatches[0];
};

export const getReadingResultContext = (
  readingType: string,
  readingValue: number | string | boolean | undefined
) => {
  switch (readingType) {
    case NITRATE_READING_TYPE:
      if (!readingValue || readingValue === "" || isNaN(+readingValue)) {
        break;
      }
      if (+readingValue < 50) {
        return `Safe - ${+readingValue} mg/l is below the UK and European standard for concentration of nitrate (50 mg/l)`;
      } else if (+readingValue > 50) {
        return `Polluted - ${+readingValue} mg/l is above the UK and European standard for concentration of nitrate (50 mg/l)`;
      }
      return `Safe - 50 mg/l is the UK and European standard for concentration of nitrate`;
    case PHOSPHATE_READING_TYPE:
      if (!readingValue || readingValue === "" || isNaN(+readingValue)) {
        break;
      }
      if (+readingValue < 100) {
        return `Safe - ${+readingValue} ppb is below the threshold for Defra’s general quality assessment`;
      } else if (+readingValue > 100) {
        return `Polluted - ${+readingValue} ppb is above the threshold (100pb) for Defra’s general quality assessment`;
      }
      return `Safe - 100 ppb is the threshold for Defra’s general quality assessment`;
    case PH_READING_TYPE:
      return `Inland waterways generally range between pH 5 (acidic) and pH 9 (alkaline). On average, the optimum pH level is 7.4 (this varies depending on habitat type).`;
    case COLIFORMS_READING_TYPE:
      if (readingValue === true) {
        return `Coliform bacteria are present (above 500 colony forming units per 100ml). This waterway does not meet excellent UK bathing standards.`;
      } else {
        return `Coliform bacteria are absent (below 500 colony forming units per 100ml). This waterway meets excellent UK bathing standards.`;
      }
  }
  return "";
};

export const getReadingResultContextFromReadingData = (
  reading: AnyReadingFormData
) => {
  const readingType = reading.readingType;
  switch (readingType) {
    case NITRATE_READING_TYPE:
      return getReadingResultContext(
        readingType,
        (reading as NitrateFormData).nitrateReading
      );
    case PHOSPHATE_READING_TYPE:
      return getReadingResultContext(
        readingType,
        (reading as PhosphateFormData).phosphateReading
      );
    case PH_READING_TYPE:
      return getReadingResultContext(readingType, "");
    case TEMPERATURE_READING_TYPE:
      return getReadingResultContext(readingType, "");
    case COLIFORMS_READING_TYPE:
      return getReadingResultContext(
        readingType,
        (reading as ColiformsFormData).coliforms
      );
  }
};

export const getReadingResultContextFromFeature = (feature: Feature) => {
  const mapReadingData = feature.properties as MapReadingData;
  const readingType = mapReadingData.readingType;

  switch (readingType) {
    case NITRATE_READING_TYPE:
      const nitrateReading = extractNumericReading(
        mapReadingData.data,
        NitrateReadingMapLabel
      );
      return getReadingResultContext(readingType, nitrateReading);
    case PHOSPHATE_READING_TYPE:
      const phosphateReading = extractNumericReading(
        mapReadingData.data,
        PhosphateReadingMapLabel
      );
      return getReadingResultContext(readingType, phosphateReading);
    case PH_READING_TYPE:
      return getReadingResultContext(readingType, "");
    case TEMPERATURE_READING_TYPE:
      return getReadingResultContext(readingType, "");
    case COLIFORMS_READING_TYPE:
      const coliformsReading = mapReadingData.data.get(
        ColiformsReadingMapLabel
      );
      return getReadingResultContext(
        readingType,
        coliformsReading === "Positive"
      );
  }
};

export const getNitrateReadingGraphData = (
  reading: AnyReadingFormData | null,
  readings: Feature[]
): any | null => {
  const nitrateRange = [0, 10, 25, 50, 100, 250, 500];
  const nitrateReadingNumber = reading
    ? (reading as NitrateFormData).nitrateReading
    : 0;
  const nitrateReadingValues = nitrateRange.map((r) => {
    return {
      value: r,
      count: 0
    };
  });
  readings.forEach((otherReading) => {
    const otherReadingType = (otherReading.properties as MapReadingData)
      .readingType;
    if (otherReadingType === NITRATE_READING_TYPE) {
      const otherReadingValue = extractNumericReading(
        (otherReading.properties as MapReadingData).data,
        NitrateReadingMapLabel
      );
      if (otherReadingValue !== "" && !isNaN(+otherReadingValue)) {
        nitrateReadingValues.forEach((r) => {
          if (+otherReadingValue === r.value) {
            r.count++;
          }
        });
      }
    }
  });

  const chartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    legend: {
      display: false
    },
    plugins: {
      legend: {
        display: false
      }
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: "Readings"
        }
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Nitrate value"
        }
      }
    }
  };

  const chartData = {
    labels: nitrateRange,
    datasets: [
      {
        label: "Nitrate readings",
        data: nitrateReadingValues.map((r) => r.count),
        borderColor: styles.water
      }
    ]
  };
  return { nitrateReadingNumber, chartData, chartOptions };
};

export const getPhosphateReadingGraphData = (
  reading: AnyReadingFormData | null,
  readings: Feature[]
): any | null => {
  const phosphateRange = [0, 10, 25, 50, 100, 250, 500];
  const readingValue = reading
    ? (reading as NitrateFormData).nitrateReading
    : 0;
  const phosphateReadingValues = phosphateRange.map((r) => {
    return {
      value: r,
      count: 0
    };
  });
  readings.forEach((otherReading) => {
    const otherReadingType = (otherReading.properties as MapReadingData)
      .readingType;

    if (otherReadingType === PHOSPHATE_READING_TYPE) {
      const otherReadingValue = extractNumericReading(
        (otherReading.properties as MapReadingData).data,
        PhosphateReadingMapLabel
      );
      if (otherReadingValue !== "" && !isNaN(+otherReadingValue)) {
        phosphateReadingValues.forEach((r) => {
          if (+otherReadingValue === r.value) {
            r.count++;
          }
        });
      }
    }
  });

  const chartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    legend: {
      display: false
    },
    plugins: {
      legend: {
        display: false
      }
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: "Readings"
        }
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Phosphate value"
        }
      }
    }
  };

  const chartData = {
    labels: phosphateRange,
    datasets: [
      {
        label: "Phosphate readings",
        data: phosphateReadingValues.map((r) => r.count),
        borderColor: styles.water
      }
    ]
  };
  return { readingValue, chartData, chartOptions };
};

export const getPhReadingGraphData = (
  reading: AnyReadingFormData | null,
  readings: Feature[]
): any | null => {
  const phRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const readingValue = reading ? (reading as PhFormData).ph : 0;
  const phReadingValues = phRange.map((r) => {
    return {
      value: r,
      count: 0
    };
  });
  readings.forEach((otherReading) => {
    const otherReadingType = (otherReading.properties as MapReadingData)
      .readingType;
    if (otherReadingType === PH_READING_TYPE) {
      const otherReadingValue = extractNumericReading(
        (otherReading.properties as MapReadingData).data,
        PhReadingMapLabel
      );
      if (otherReadingValue !== "" && !isNaN(+otherReadingValue)) {
        phReadingValues.forEach((r) => {
          if (+otherReadingValue === r.value) {
            r.count++;
          }
        });
      }
    }
  });

  const chartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    legend: {
      display: false
    },
    plugins: {
      legend: {
        display: false
      }
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: "Readings"
        }
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "pH value"
        }
      }
    }
  };

  const chartData = {
    labels: phRange,
    datasets: [
      {
        label: "PH readings",
        data: phReadingValues.map((r) => r.count),
        borderColor: styles.water
      }
    ]
  };
  return { readingValue, chartData, chartOptions };
};

export const getTemperatureReadingGraphData = (
  reading: AnyReadingFormData | null,
  readings: Feature[]
): any | null => {
  const temperatureRange = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
  ];
  const readingValue = reading
    ? (reading as TemperatureFormData).temperature
    : 0;
  const temperatureReadingValues = temperatureRange.map((r) => {
    return {
      value: r,
      count: 0
    };
  });
  readings.forEach((otherReading) => {
    const otherReadingType = (otherReading.properties as MapReadingData)
      .readingType;
    if (otherReadingType === TEMPERATURE_READING_TYPE) {
      const otherReadingValue = extractNumericReading(
        (otherReading.properties as MapReadingData).data,
        TemperatureReadingMapLabel
      );
      if (otherReadingValue !== "" && !isNaN(+otherReadingValue)) {
        temperatureReadingValues.forEach((r) => {
          if (+otherReadingValue === r.value) {
            r.count++;
          }
        });
      }
    }
  });

  const chartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    legend: {
      display: false
    },
    plugins: {
      legend: {
        display: false
      }
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: "Readings"
        }
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Temperature"
        }
      }
    }
  };

  const chartData = {
    labels: temperatureRange,
    datasets: [
      {
        label: "Temperature readings",
        data: temperatureReadingValues.map((r) => r.count),
        borderColor: styles.water,
        pointRadius: 0,
        pointStyle: false
      }
    ]
  };
  return { readingValue, chartData, chartOptions };
};

export const getColiformsReadingGraphData = (
  reading: AnyReadingFormData | null,
  readings: Feature[]
): any | null => {
  const readingValue = reading
    ? (reading as ColiformsFormData).coliforms
    : false;

  const historicColiforms = [...readings].filter((otherReading) => {
    const otherReadingType = (otherReading.properties as MapReadingData)
      .readingType;
    return otherReadingType === COLIFORMS_READING_TYPE;
  });

  const positiveValueCount = historicColiforms.filter((reading) => {
    const coliformsReading = (reading.properties as MapReadingData).data.get(
      ColiformsReadingMapLabel
    );
    return coliformsReading === "Positive";
  }).length;

  const negativeValueCount = historicColiforms.filter((reading) => {
    const coliformsReading = (reading.properties as MapReadingData).data.get(
      ColiformsReadingMapLabel
    );
    return coliformsReading === "Negative";
  }).length;

  const chartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    legend: {
      display: false
    },
    plugins: {
      legend: {
        display: false
      }
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: "Readings"
        }
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Recorded result"
        }
      }
    }
  };

  const chartData = {
    labels: ["Positive", "Negative"],
    datasets: [
      {
        label: "Coliform readings",
        data: [positiveValueCount, negativeValueCount],
        borderColor: styles.water
      }
    ]
  };
  return { readingValue, chartData, chartOptions };
};

export const getReadingGraphData = (
  reading: AnyReadingFormData | null,
  readingsProviderData: FeaturesProviderData,
  readingType: string,
  postcode: string | null = null
) => {
  const type = reading?.readingType || readingType;

  const readingsToUse =
    readingsProviderData?.features?.geojson?.features?.filter((reading) => {
      // If we have a postcode and it doesn't match the reading's postcode, skip this reading
      const readingPostcode = (reading.properties as ReadingFormData).geodata
        ?.postcode;
      const readingOutcode = readingPostcode
        ? toOutcode(readingPostcode)
        : null;
      const userOutcode = postcode ? toOutcode(postcode) : null;
      if (
        postcode &&
        userOutcode &&
        (!readingPostcode || !readingOutcode || readingOutcode !== userOutcode)
      ) {
        return false;
      }
      return true;
    });

  if (type && type !== "") {
    switch (type) {
      case NITRATE_READING_TYPE:
        return getNitrateReadingGraphData(reading, readingsToUse);
      case PHOSPHATE_READING_TYPE:
        return getPhosphateReadingGraphData(reading, readingsToUse);
      case PH_READING_TYPE:
        return getPhReadingGraphData(reading, readingsToUse);
      case COLIFORMS_READING_TYPE:
        return getColiformsReadingGraphData(reading, readingsToUse);
      case TEMPERATURE_READING_TYPE:
        return getTemperatureReadingGraphData(reading, readingsToUse);
      default:
        return null;
    }
  }
};
