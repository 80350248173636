import { useState } from "react";

import { useTranslation } from "react-i18next";

import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";

import CancelIcon from "@material-ui/icons/Cancel";
import CropFreeIcon from "@material-ui/icons/CropFree";

import dbFirebase, {
  ProductInfo
} from "../../../../features/firebase/dbFirebase";

import { BarcodeScanner as capBarcodeScanner } from "@capacitor-community/barcode-scanner";

import { makeStyles } from "@material-ui/core";

declare global {
  interface Window {
    cordova?: any;
  }
}

const { getBarcodeInfo } = dbFirebase;

export type BarcodeResult =
  | ProductInfo
  | "cancelled"
  | "not found"
  | "scan error"
  | "lookup error";

const useStyles = makeStyles(() => ({
  barcodeIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

interface Props {
  onResult: (result: BarcodeResult) => void;
  className?: string;
}

export function isProductInfo(result: BarcodeResult): result is ProductInfo {
  return result !== undefined && (result as ProductInfo).brand !== undefined;
}

const BarcodeScanner = ({ onResult, className }: Props) => {
  const [scanning, setScanning] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const stopScan = async () => {
    document.body.classList.remove("scannerActive");
    capBarcodeScanner.showBackground();
    capBarcodeScanner.stopScan();
    setScanning(false);
  };

  const startScan = async () => {
    setScanning(true);
    document.body.classList.add("scannerActive");
    await capBarcodeScanner.checkPermission({ force: true });
    capBarcodeScanner.hideBackground();
    const result = await capBarcodeScanner.startScan();
    setScanning(false);
    if (result.hasContent) {
      return result.content;
    } else {
      return null;
    }
  };

  return (
    <div className={classes.barcodeIcon}>
      {!scanning ? (
        <>
          <p>{t("record_litter_barcodescan_hint")}</p>
          <IconButton
            disableRipple
            className={className}
            onClick={async () => {
              var id;
              id = await startScan();

              stopScan();

              if (!id) {
                onResult("cancelled");
              } else {
                const result = await getBarcodeInfo(id);
                onResult(result || t("record_litter_barcodescan_not_found"));
              }
            }}
          >
            <CropFreeIcon />
          </IconButton>
        </>
      ) : (
        <Fab
          color="primary"
          style={{
            visibility: "visible",
            position: "absolute",
            bottom: 0,
            right: 0,
            margin: "1rem"
          }}
          onClick={async () => {
            stopScan();
          }}
        >
          <CancelIcon />
        </Fab>
      )}
    </div>
  );
};

export default BarcodeScanner;
