import { useState } from "react";

import { makeStyles } from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { Question } from "types/Survey";
import useEffectOnMount from "hooks/useEffectOnMount";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(2)
  }
}));

type Props = {
  question: Question;
  onChangeQuestionValue: (
    questionId: string,
    value: string,
    valid: boolean
  ) => void;
};

export default function QuestionRadio({
  question,
  onChangeQuestionValue
}: Props) {
  const classes = useStyles();

  const [value, setValue] = useState((question.default as string) || "");
  const [valid, setValid] = useState(
    question.required ? (question.default ? true : false) : true
  );
  const [validationTriggered, setValidationTriggered] = useState(false);

  useEffectOnMount(() => {
    onChangeQuestionValue(question.id, value, valid);
  });

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChangeQuestionValue(question.id, newValue, validate(newValue));

    setValidationTriggered(true);
  };

  const validate = (currentValue: string) => {
    if (question.required && currentValue && currentValue === "") {
      setValid(false);
      return false;
    } else {
      setValid(true);
      return true;
    }
  };

  const error = !valid && validationTriggered;

  return (
    <div className={classes.root}>
      <FormControl
        required={question.required}
        error={error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend">{question.name}</FormLabel>
        <RadioGroup value={value} onChange={handleChangeValue}>
          {question.options.map((option) => (
            <FormControlLabel
              key={`lbl_${option.id}`}
              value={option.value}
              control={<Radio />}
              label={option.name}
            />
          ))}
        </RadioGroup>
        <FormHelperText>
          <span dangerouslySetInnerHTML={{ __html: question.description }} />
        </FormHelperText>
      </FormControl>
    </div>
  );
}
