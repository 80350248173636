import {
  getReadingMapDataEntries,
  MapReadingData,
  Reading,
  ReadingFormData
} from "./Base";

export const PHOSPHATE_READING_TYPE = "phosphate";
export const PHOSPHATE_PLANET_PATROL_UNITS = "ppb";
export const PHOSPHATE_VIDEO_ID = "cIihA1HN6Qo";

export type PhosphateInputs = {
  phosphateReading: number;
  phosphateUnits: string; // With Planet Patrol test kit will be numeric ppb, otherwise user manual entry
};

export type PhosphateFormData = ReadingFormData & PhosphateInputs;
export type Phosphate = Reading & PhosphateInputs;

export const PhosphateReadingMapLabel = "Phosphate Reading";
export const phosphateToMapData = (phosphate: Phosphate): MapReadingData => {
  return {
    ...phosphate,
    data: new Map<string, string>([
      ...getReadingMapDataEntries(phosphate),
      [
        PhosphateReadingMapLabel,
        phosphate.phosphateReading.toString() + phosphate.phosphateUnits
      ]
    ])
  };
};
