import { makeStyles } from "@material-ui/core/styles";
import React, { createRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import styles from "../../../../standard.module.scss";
import {
  CordovaCameraImage,
  isCordovaCameraImageFile
} from "../../../photo/state/types";
import { DesktopPhotoFallback } from "../../../../components/common/DesktopPhotoFallback";
import AddPhotoDialog from "../../../photo/components/AddPhotoDialog";
import { useGPSLocation } from "../../../../providers/LocationProvider";
import { Capacitor } from "@capacitor/core";
import loadPhoto from "../../../photo/pages/CategorisePhotoPage/utils";
import { ReadingSpecificInput } from "../../../../types/Readings/Shared";
import {
  NITRATE_PLANET_PATROL_UNITS,
  NITRATE_READING_TYPE,
  NitrateInputs
} from "../../../../types/Readings/Nitrate";
import {
  PHOSPHATE_PLANET_PATROL_UNITS,
  PHOSPHATE_READING_TYPE,
  PhosphateInputs
} from "../../../../types/Readings/Phosphate";
import {
  COLIFORMS_READING_TYPE,
  ColiformsInputs
} from "../../../../types/Readings/Coliforms";
import { PH_READING_TYPE, PhInputs } from "../../../../types/Readings/Ph";
import {
  TEMPERATURE_READING_TYPE,
  TemperatureInputs
} from "../../../../types/Readings/Temperature";
import { forceNumericInput } from "../../../../utils";
import { ImageMetaData, photoIsMetaData } from "../../../../types/Photo";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  heading: {
    fontWeight: "bold",
    margin: `${theme.spacing(2)}px 0`
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  inputTextBox: {
    border: "none",
    borderRadius: "5px",
    padding: `${theme.spacing(1)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    background: styles.lightGrey,
    fontSize: 16,
    boxSizing: "border-box",
    width: "100%",
    textOverflow: "ellipsis",
    "&:focus": {
      outline: "none"
    }
  },
  radioButtonSection: {
    fontSize: "1em",
    display: "flex",
    marginBottom: `${theme.spacing(2)}px`
  },
  radioButton: {
    flex: "0 0 auto",
    height: `${theme.spacing(2)}px`,
    width: `${theme.spacing(2)}px`,
    marginRight: `${theme.spacing(2)}px`
  },
  radioButtonText: {
    flexGrow: 1,
    marginTop: `${theme.spacing(0.3)}px`
  },
  testPhotoWrapper: {
    width: "100%",
    textAlign: "center",
    paddingBottom: `${theme.spacing(2)}px`
  },
  testPhotoPreview: {
    maxWidth: "100%",
    maxHeight: "180px"
  },
  button: {
    width: "100%",
    marginTop: `${theme.spacing(2)}px`
  }
}));

type Props = {
  readingData: ReadingSpecificInput;
  usingPlanetPatrolKit: boolean;
  onSubmit: (data: ReadingSpecificInput) => void;
};

const NitrateDataEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  return (
    <>
      {usingPlanetPatrolKit ? (
        <PlanetPatrolNitrateInputsEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      ) : (
        <CustomNitrateInputsEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

const PlanetPatrolNitrateInputsEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  const classes = useStyles();

  const reading = readingData.data as NitrateInputs;
  const [nitrateReading, setNitrateReading] = useState<number>(
    reading?.nitrateReading || 0
  );
  const [nitriteReading, setNitriteReading] = useState<number>(
    reading?.nitriteReading || 0
  );

  return (
    <>
      <div>Look at the test strip and choose one reading for each section.</div>
      <div className={classes.heading}>
        Nitrate reading ({NITRATE_PLANET_PATROL_UNITS})
      </div>
      {[0, 10, 25, 50, 100, 250, 500].map((nitrate) => (
        <div key={`nitrate-${nitrate}`} className={classes.radioButtonSection}>
          <input
            type="radio"
            className={classes.radioButton}
            checked={nitrate === nitrateReading}
            value={nitrate}
            onChange={() => {}}
            onClick={() => {
              setNitrateReading(nitrate);
            }}
          />
          <div className={classes.radioButtonText}>{nitrate}</div>
        </div>
      ))}
      <div className={classes.heading}>
        Nitrite reading ({NITRATE_PLANET_PATROL_UNITS})
      </div>
      {[0, 1, 5, 10, 20, 40, 80].map((nitrite) => (
        <div key={`nitrite-${nitrite}`} className={classes.radioButtonSection}>
          <input
            type="radio"
            className={classes.radioButton}
            checked={nitrite === nitriteReading}
            value={nitrite}
            onChange={() => {}}
            onClick={() => {
              setNitriteReading(nitrite);
            }}
          />
          <div className={classes.radioButtonText}>{nitrite}</div>
        </div>
      ))}
      <Button
        className={classes.button}
        onClick={() =>
          onSubmit({
            ...readingData,
            data: {
              nitrateReading,
              nitriteReading,
              nitrateUnits: NITRATE_PLANET_PATROL_UNITS,
              nitriteUnits: NITRATE_PLANET_PATROL_UNITS
            } as NitrateInputs
          })
        }
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

const CustomNitrateInputsEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  const classes = useStyles();

  const reading = readingData.data as NitrateInputs;

  const [hasNitrateReading, setHasNitrateReading] = useState(
    reading?.nitrateReading !== undefined
  );
  const [nitrateReading, setNitrateReading] = useState(
    reading?.nitrateReading || 0
  );
  const [nitrateUnits, setNitrateUnits] = useState(reading?.nitrateUnits || "");

  const [hasNitriteReading, setHasNitriteReading] = useState(
    reading?.nitriteReading !== undefined
  );
  const [nitriteReading, setNitriteReading] = useState(
    reading?.nitriteReading || 0
  );
  const [nitriteUnits, setNitriteUnits] = useState(reading?.nitriteUnits || "");

  const DisplayReadingInput = (
    name: string,
    hasReading: boolean,
    setHasReading: (reading: boolean) => void,
    reading: number,
    setReading: (reading: number) => void,
    units: string,
    setUnits: (units: string) => void
  ) => {
    return (
      <>
        <div className={classes.heading}>{name} reading</div>
        <div
          className={classes.radioButtonSection}
          onClick={() => setHasReading(true)}
        >
          <input
            type="radio"
            className={classes.radioButton}
            checked={hasReading}
            onChange={() => {}}
          />
          <div className={classes.radioButtonText}>
            <div className={classes.text}>
              I have a {name.toLowerCase()} reading.
            </div>
            {hasReading && (
              <>
                <div className={classes.text}>Enter the reading</div>
                <input
                  className={classes.inputTextBox}
                  value={reading}
                  onChange={(e) =>
                    forceNumericInput(e.target.value, setReading)
                  }
                />
                <div className={classes.text}>
                  Enter the reading units (e.g. mg/l).
                </div>
                <input
                  className={classes.inputTextBox}
                  value={units}
                  onChange={(e) => setUnits(e.target.value)}
                />
              </>
            )}
          </div>
        </div>
        <div
          className={classes.radioButtonSection}
          onClick={() => setHasReading(false)}
        >
          <input
            type="radio"
            className={classes.radioButton}
            checked={!hasReading}
            onChange={() => {}}
          />
          <div className={classes.radioButtonText}>
            I don't have a {name.toLowerCase()} reading.
          </div>
        </div>
      </>
    );
  };

  const nitrateInputValid = !hasNitrateReading || nitrateUnits !== "";
  const nitriteInputValid = !hasNitriteReading || nitriteUnits !== "";
  const inputIsValid = nitrateInputValid && nitriteInputValid;

  return (
    <>
      <div>Look at the test and add your reading(s).</div>
      {DisplayReadingInput(
        "Nitrate",
        hasNitrateReading,
        setHasNitrateReading,
        nitrateReading,
        setNitrateReading,
        nitrateUnits,
        setNitrateUnits
      )}
      {DisplayReadingInput(
        "Nitrite",
        hasNitriteReading,
        setHasNitriteReading,
        nitriteReading,
        setNitriteReading,
        nitriteUnits,
        setNitriteUnits
      )}
      <Button
        className={classes.button}
        onClick={() =>
          onSubmit({
            ...readingData,
            data: {
              nitrateReading: hasNitrateReading ? nitrateReading : undefined,
              nitrateUnits: hasNitrateReading ? nitrateUnits : undefined,
              nitriteReading: hasNitriteReading ? nitriteReading : undefined,
              nitriteUnits: hasNitriteReading ? nitriteUnits : undefined
            } as NitrateInputs
          })
        }
        color="primary"
        disabled={!inputIsValid}
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

const PhosphateInputsEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  return (
    <>
      {usingPlanetPatrolKit ? (
        <PhosphatePlanetPatrolEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      ) : (
        <PhosphateCustomEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

const PhosphatePlanetPatrolEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  const classes = useStyles();

  const reading = readingData.data as PhosphateInputs;
  const [phosphateReading, setPhosphateReading] = useState<number>(
    reading?.phosphateReading || 0
  );

  return (
    <>
      <div>
        Look down through the open test tube from above and match the colour to
        the colour chart. Choose a reading.
      </div>
      <div className={classes.heading}>
        Phosphate reading ({PHOSPHATE_PLANET_PATROL_UNITS})
      </div>
      {[0, 100, 200, 300, 500, 1000, 2500].map((phosphate) => (
        <div
          key={`phosphate-${phosphate}`}
          className={classes.radioButtonSection}
          onClick={() => setPhosphateReading(phosphate)}
        >
          <input
            type="radio"
            className={classes.radioButton}
            checked={phosphate === phosphateReading}
            value={phosphate}
            onChange={() => {}}
          />
          <div className={classes.radioButtonText}>{phosphate}</div>
        </div>
      ))}
      <Button
        className={classes.button}
        onClick={() =>
          onSubmit({
            ...readingData,
            data: {
              phosphateReading,
              phosphateUnits: PHOSPHATE_PLANET_PATROL_UNITS
            } as PhosphateInputs
          })
        }
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

const PhosphateCustomEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  const classes = useStyles();

  const reading = readingData.data as PhosphateInputs;
  const [phosphateReading, setPhosphateReading] = useState<number>(
    reading?.phosphateReading || 0
  );
  const [phosphateUnits, setPhosphateUnits] = useState<string>(
    reading?.phosphateUnits || ""
  );

  return (
    <>
      <div>Look at the test and add your reading.</div>
      <div className={classes.heading}>Phosphate reading</div>
      <input
        className={classes.inputTextBox}
        value={phosphateReading}
        onChange={(e) => forceNumericInput(e.target.value, setPhosphateReading)}
      />
      <div className={classes.text}>Enter the reading units (e.g. ppb).</div>
      <input
        className={classes.inputTextBox}
        value={phosphateUnits}
        onChange={(e) => setPhosphateUnits(e.target.value)}
      />
      <Button
        className={classes.button}
        onClick={() =>
          onSubmit({
            ...readingData,
            data: {
              phosphateReading,
              phosphateUnits
            } as PhosphateInputs
          })
        }
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

const PhDataEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  const classes = useStyles();

  const reading = readingData.data as PhInputs;
  const [ph, setPh] = useState<string>(reading?.ph?.toString() || "7");

  return (
    <>
      <div>Look at the test after 20 seconds and add a reading.</div>
      <div className={classes.heading}>pH reading</div>
      <div className={classes.text}>
        Please enter a reading between 1 and 14.
      </div>
      <input
        className={classes.inputTextBox}
        value={ph}
        onChange={(e) => setPh(e.target.value)}
      />
      <Button
        className={classes.button}
        onClick={() =>
          onSubmit({
            ...readingData,
            data: {
              ph: +ph
            } as PhInputs
          })
        }
        color="primary"
        disabled={isNaN(+ph) || +ph < 0 || +ph > 14}
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

const TemperatureDataEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  const classes = useStyles();

  const reading = readingData.data as TemperatureInputs;
  const [temperature, setTemperature] = useState<string>(
    reading?.temperature?.toString() || ""
  );

  return (
    <>
      <div>Using a thermometer take the temperature of the water in degrees Celsius.</div>
      <div className={classes.heading}>Temperature reading (°C)</div>
      <input
        className={classes.inputTextBox}
        value={temperature}
        onChange={(e) => setTemperature(e.target.value)}
      />
      <Button
        className={classes.button}
        onClick={() =>
          onSubmit({
            ...readingData,
            data: {
              temperature: +temperature
            } as TemperatureInputs
          })
        }
        color="primary"
        disabled={isNaN(+temperature) || +temperature < 0}
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

type ColiformsProps = Props & {
  photoUploaded: boolean;
};

const ColiformsDataEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  const reading = readingData.data as ColiformsInputs;

  const classes = useStyles();
  const gpsLocation = useGPSLocation();

  const [photo, setPhoto] = useState<ImageMetaData | string | undefined>(
    reading?.postIncubationPhoto
  );
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);

  // Magic for handling photo uploads.
  const desktopPhotoRef = createRef<HTMLInputElement>();
  const handlePhotoSelect = (
    image: File | CordovaCameraImage,
    fromCamera: boolean
  ) => {
    const fileState = isCordovaCameraImageFile(image)
      ? {
          fileOrFileName: Capacitor.convertFileSrc(image.filename),
          cordovaMetadata: JSON.parse(image.json_metadata),
          fromCamera: fromCamera
        }
      : { fileOrFileName: image, fromCamera: fromCamera };
    loadPhoto({
      ...fileState,
      fromCamera,
      gpsLocation,
      callback: setPhoto
    });

    if (Capacitor.platform !== "web") {
      setOpenPhotoDialog(false);
    }
  };

  const onButtonPress = (reading: ReadingSpecificInput) => {
    onSubmit({
      ...reading,
      data: {
        ...(reading.data as ColiformsInputs),
        postIncubationPhoto: photo
      }
    });
  };

  return (
    <>
      <div>Look at the test kit and choose one result for each section.</div>
      <div className={classes.heading}>
        Upload a photo of the test kit after incubation.
      </div>
      {photo && (
        <div className={classes.testPhotoWrapper}>
          <img
            src={photo && (photoIsMetaData(photo) ? photo.imgSrc : photo)}
            className={classes.testPhotoPreview}
            alt={""}
          />
        </div>
      )}
      <Button
        onClick={() =>
          !!window.cordova
            ? setOpenPhotoDialog(true)
            : desktopPhotoRef.current && desktopPhotoRef.current.click()
        }
        color={photo === undefined ? "primary" : "secondary"}
        variant="contained"
        disableElevation
        size="large"
      >
        {photo === undefined ? "Upload or take photo" : "Change photo"}
      </Button>
      <DesktopPhotoFallback
        ref={desktopPhotoRef}
        handlePhotoSelect={handlePhotoSelect}
      />
      {openPhotoDialog && (
        <AddPhotoDialog
          onClose={() => setOpenPhotoDialog(false)}
          handlePhotoSelect={handlePhotoSelect}
        />
      )}
      {usingPlanetPatrolKit ? (
        <ColiformsPlanetPatrolEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onButtonPress}
          photoUploaded={photo !== undefined}
        />
      ) : (
        <ColiformsCustomEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onButtonPress}
          photoUploaded={photo !== undefined}
        />
      )}
    </>
  );
};

const ColiformsCustomEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit,
  photoUploaded
}: ColiformsProps) => {
  const classes = useStyles();

  const reading = readingData.data as ColiformsInputs;
  const [coliforms, setColiforms] = useState(reading?.coliforms);
  const [ecoli, setEcoli] = useState(reading?.ecoli);
  const [additionalReadings, setAdditionalReadings] = useState(
    reading?.additionalReadings
  );

  return (
    <>
      <div className={classes.heading}>
        Was the sample positive or negative for total coliforms?
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setColiforms(true)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={coliforms === true}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>Positive</div>
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setColiforms(false)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={coliforms === false}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>Negative</div>
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setColiforms(undefined)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={coliforms === undefined}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>
          My kit doesn't test for total coliforms.
        </div>
      </div>
      <div className={classes.heading}>
        Was the sample positive or negative for E. coli?
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setEcoli(true)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={ecoli === true}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>Positive</div>
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setEcoli(false)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={ecoli === false}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>Negative</div>
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setEcoli(undefined)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={ecoli === undefined}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>
          My kit doesn't test for E. coli.
        </div>
      </div>
      <div className={classes.heading}>
        Additional readings from your test kit
      </div>
      <div className={classes.text}>
        If your test kit includes extra readings for total coliforms and E.
        coli, include them below.
        <br />
        <br />
        Example of additional readings include:
        <ul>
          <li>The row in a most probable number table,</li>
          <li>MPN/100ml value,</li>
          <li>Upper 95% confidence level/100ml,</li>
          <li>Concentration.</li>
        </ul>
        <br />
        Follow your test kit instructions and input readings if applicable.
      </div>
      <input
        className={classes.inputTextBox}
        value={additionalReadings || ""}
        onChange={(e) => setAdditionalReadings(e.target.value)}
      />
      <Button
        className={classes.button}
        onClick={() =>
          onSubmit({
            ...readingData,
            data: {
              coliforms,
              ecoli,
              additionalReadings
            } as ColiformsInputs
          })
        }
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        {photoUploaded ? "Continue" : "Continue without photo"}
      </Button>
    </>
  );
};

const ColiformsPlanetPatrolEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit,
  photoUploaded
}: ColiformsProps) => {
  const classes = useStyles();

  const reading = readingData.data as ColiformsInputs;
  const [coliforms, setColiforms] = useState<boolean>(
    reading?.coliforms || true
  );

  return (
    <>
      <div className={classes.heading}>
        Was the sample positive or negative for total coliforms?
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setColiforms(true)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={coliforms}
          value={"True"}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>Positive</div>
      </div>
      <div
        className={classes.radioButtonSection}
        onClick={() => setColiforms(false)}
      >
        <input
          type="radio"
          className={classes.radioButton}
          checked={!coliforms}
          value={"False"}
          onChange={() => {}}
        />
        <div className={classes.radioButtonText}>Negative</div>
      </div>
      <Button
        className={classes.button}
        onClick={() =>
          onSubmit({
            ...readingData,
            data: {
              coliforms
            } as ColiformsInputs
          })
        }
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        {photoUploaded ? "Continue" : "Continue without photo"}
      </Button>
    </>
  );
};

const ReadingExplicitDataEntry = ({
  readingData,
  usingPlanetPatrolKit,
  onSubmit
}: Props) => {
  const history = useHistory();

  switch (readingData.readingType) {
    case NITRATE_READING_TYPE:
      return (
        <NitrateDataEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      );
    case PHOSPHATE_READING_TYPE:
      return (
        <PhosphateInputsEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      );
    case COLIFORMS_READING_TYPE:
      return (
        <ColiformsDataEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      );
    case PH_READING_TYPE:
      return (
        <PhDataEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      );
    case TEMPERATURE_READING_TYPE:
      return (
        <TemperatureDataEntry
          readingData={readingData}
          usingPlanetPatrolKit={usingPlanetPatrolKit}
          onSubmit={onSubmit}
        />
      );
    default:
      return (
        <>
          <div>
            Failed to display reading entry for reading type:{" "}
            {readingData.readingType}. Please report this!
          </div>
          <Button
            onClick={history.goBack}
            color="primary"
            variant="contained"
            disableElevation
          >
            Go back
          </Button>
        </>
      );
  }
};

export default ReadingExplicitDataEntry;
