import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import _ from "lodash";
import { getIncidentStorageBucket, uploadIncidentPhoto } from "./utils";
import { Incident, IncidentData, IncidentUploadHandle } from "types/Incident";
import { photoIsMetaData } from "../../../types/Photo";
import {
  HIGH_RES_PHOTO_NAME,
  INCIDENTS_FIRESTORE_COLLECTION,
  THUMBNAIL_PHOTO_NAME
} from "../../../shared/utils";

export const uploadIncident = async (
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  incident: IncidentData
): Promise<IncidentUploadHandle> => {
  // We get rid of photo from the Firestore data because it's kept in Storage instead.
  let firestoreData: Omit<IncidentData, "photo"> = _.omit(incident, "photo");

  // Create the Firestore entry.
  let firestoreId;
  try {
    const doc = await firestore
      .collection(INCIDENTS_FIRESTORE_COLLECTION)
      .add(firestoreData);
    firestoreId = doc.id;
  } catch (err) {
    throw new Error(
      `Failed creating pollution incident firestore document. ${err}`
    );
  }

  let uploadTasks = [];

  // Upload reading photo (if exists).
  if (incident.photo !== undefined && photoIsMetaData(incident.photo)) {
    const readingPhotoUploadTask = uploadIncidentPhoto(
      storage,
      firestoreId,
      incident.photo
    );
    if (readingPhotoUploadTask !== undefined) {
      uploadTasks.push(readingPhotoUploadTask);
    }
  }

  return { firestoreId, uploadTasks };
};

export const fetchIncidents = async (
  storage: firebase.storage.Storage,
  functionsApiURL: string
): Promise<Incident[]> => {
  const incidentsResponse = await fetch(`${functionsApiURL}/incidents.json`, {
    mode: "cors"
  });
  const incidentsJson = await incidentsResponse.json();

  return incidentsJson.incidents.map((incident: Incident) => {
    const incidentStorage = getIncidentStorageBucket(storage, incident.id);
    return {
      ...incident,
      main: `${incidentStorage}/${HIGH_RES_PHOTO_NAME}`,
      thumbnail: `${incidentStorage}/${THUMBNAIL_PHOTO_NAME}`
    };
  });
};
