import { makeStyles } from "@material-ui/core/styles";

import { useState } from "react";

import { COLIFORMS_READING_TYPE } from "../../../../types/Readings/Coliforms";
import { NITRATE_READING_TYPE } from "../../../../types/Readings/Nitrate";
import { PH_READING_TYPE } from "../../../../types/Readings/Ph";
import { PHOSPHATE_READING_TYPE } from "../../../../types/Readings/Phosphate";
import { TEMPERATURE_READING_TYPE } from "../../../../types/Readings/Temperature";

import Button from "@material-ui/core/Button";

import ReadingVideoDialog from "./ReadingVideoDialog";
import { getEmbeddedVideoId } from "types/Readings/Shared";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(2)}px`
  },
  instructions: {
    "& ol": {
      padding: 0,
      margin: 0,
      marginBottom: `${theme.spacing()}px`,
      "& li": {
        listStylePosition: "inside",
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        "&:nth-child(odd)": {
          backgroundColor: theme.palette.grey[200]
        }
      }
    }
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  youtubeIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0
  },
  youtubeIframeWrapper: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%"
  }
}));

type Props = {
  readingName: string;
};

const ReadingInstructions = ({ readingName }: Props) => {
  const classes = useStyles();

  const [showVideoDialog, setShowVideoDialog] = useState(false);

  let instructions;
  switch (readingName) {
    case PH_READING_TYPE:
      instructions = (
        <div className={classes.instructions}>
          <ol>
            <li>
              Dip the strip into the surface water for <b>ten full seconds</b>,
              with both testing fields submerged, with a gentle, constant back
              and forth motion
            </li>
            <li>Remove the strip and give it one brisk shake</li>
            <li>
              <b>Wait 20 seconds</b>, then hold the strip parallel with the
              colour chart to compare the colour combination
            </li>
            <li>
              Select the colour combination and associated pH number that is
              closest to what you can see on the test strip
            </li>
          </ol>
        </div>
      );
      break;
    case TEMPERATURE_READING_TYPE:
      instructions = (
        <div className={classes.instructions}>
          <ol>
            <li>
              Measure the water temperature by submerging the thermometer below
              the surface of the water.
            </li>
            <li>Take the measurement in a central flowing location.</li>
            <li>
              Allow at least a minute for the thermometer to adjust to the water
              temperature
            </li>
            <li>
              Record the temperature and then remove the thermometer, or quickly
              record the temperature after removing from the water.
            </li>
          </ol>
        </div>
      );
      break;
    case PHOSPHATE_READING_TYPE:
      instructions = (
        <div className={classes.instructions}>
          <ol>
            <li>
              Rinse the measuring beaker <b>three times</b> in the waterbody to
              be tested, then fill the beaker with water from the surface
            </li>
            <li>
              Pour water from the beaker into the clear plastic test tube{" "}
              <b>up to the 10 mL line</b>
            </li>
            <li>
              Take one test strip and gently bend it in half into a{" "}
              <b>
                U-shape with the testing pads facing inwards and place inside
                the test tube cap
              </b>
              . See image on box for an example.
            </li>
            <li>
              Put the test strip into the water in the test tube by putting on
              the cap and screwing it.
            </li>
            <li>
              Slowly turn the test tube upside down and back again{" "}
              <b>five times</b>, which should take about <b>10 seconds</b>
            </li>
            <li>Remove the cap and test strip and put to one side</li>
            <li>
              Place the bottom of the test tube on the white boxed area on the
              colour chart of the test strip pot
            </li>
            <li>
              In natural light,{" "}
              <b>look down through the top of the open test tube from above</b>{" "}
              to see the colour of the water
            </li>
            <li>
              Compare the colour of the water at the bottom of the test tube to
              the colour chart and, if the colour falls between two options,
              select the lower value
            </li>
          </ol>
        </div>
      );
      break;
    case NITRATE_READING_TYPE:
      instructions = (
        <div className={classes.instructions}>
          <ol>
            <li>
              Dip the strip into the water for <b>one full second</b>, with both
              testing fields submerged
            </li>
            <li>
              Shake off excess water from the strip and <b>lay it flat</b> to
              stop the colour running
            </li>
            <li>
              <b>Wait for 60 seconds</b>, then hold the strip next to the colour
              scale
            </li>
            <li>
              The first square at the end of the test strip indicates the level
              of nitrates present and the second square indicates the level of
              nitrites
            </li>
            <li>
              Compare the colour of the boxes to the colour chart to work out
              the concentration of nitrate (first square) and nitrite (second
              square) present. If the colour falls between two options, select
              the lower value
            </li>
          </ol>
        </div>
      );
      break;
    case COLIFORMS_READING_TYPE:
      instructions = (
        <div className={classes.instructions}>
          <ol>
            <li>
              Rinse the measuring beaker <b>three times</b> in the waterbody to
              be tested, then fill with water from the surface.
            </li>
            <li>
              Remove the lid from the test pot and pour water from the beaker{" "}
              <b>up to the fill line on the test pot</b>
            </li>
            <li>
              Tighten the cap on the test pot and shake it continuously for{" "}
              <b>20 seconds until all the powder is dissolved</b> and the water
              turns purple. If powder is still visible, keep sharing for another
              10 seconds.
            </li>
            <li>
              Take a photo of the test pot with your <b>phone camera</b> (not
              through the app) at the test site. <b>Set a reminder or alarm</b>{" "}
              on your phone for 48 hours.
            </li>
            <li>
              Take the test pot home - transported upright - and leave it away
              from direct sunlight at room temperature (20-32 degrees Celsius)
              for <b>48 hours</b>.
            </li>
            <li>
              After 48 hours, check the test pot for colour changes. If the
              colour is still purple, leave for another 24 hours. If the colour
              is a shade of yellow at 48 hours, coliform bacteria are present
              and you can upload your results.
            </li>
            <li>
              If the colour was purple at 48 hours, upload your result 24 hours
              later. If the colour is still purple, coliform bacteria are
              absent, if the colour has changed to a shade of yellow, coliform
              bacteria are present.
            </li>
          </ol>
        </div>
      );
      break;
  }

  return (
    <>
      {instructions}
      {getEmbeddedVideoId(readingName) !== "" && (
        <>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => setShowVideoDialog(true)}
          >
            Watch a video for help
          </Button>
          <ReadingVideoDialog
            showVideoDialog={showVideoDialog}
            setShowVideoDialog={setShowVideoDialog}
            readingName={readingName}
          />
        </>
      )}
    </>
  );
};

export default ReadingInstructions;
