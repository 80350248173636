import firebase from "firebase/compat/app";
import User, {
  TesterEmailCheck,
  TesterUserEmails,
  TesterUserIds
} from "types/User";
import { getFunctionApiUrl } from "./firebaseInit";

const firestore = firebase.firestore();

const usersPath = () => "users";

export const fetchTesters = async () => {
  const snap = await firestore
    .collection(usersPath())
    .where("isTester", "==", true)
    .get();

  return snap.docs.map((val) => ({
    ...val.data(),
    id: val.id
  })) as User[];
};

export const addTester = async (testerUID: string) => {
  const userRef = firestore.collection(usersPath()).doc(testerUID);

  try {
    await userRef.set(
      {
        isTester: true
      },
      { merge: true }
    );
    alert("Successfully added as a beta tester!");
  } catch (err) {
    console.error(`Failed to set isTester to ${testerUID}, Error: ${err}`);
  }
};

export const getUserIdFromEmail = async (
  email: string
): Promise<TesterEmailCheck> => {
  const response = await fetch(`${getFunctionApiUrl()}/get_user_id/${email}`, {
    mode: "cors"
  });
  if (response.type === "error") {
    console.error(response);
  }

  return (await response.json()) as TesterEmailCheck;
};

export const getEmailsFromUserIds = async (
  userIds: TesterUserIds
): Promise<TesterUserEmails> => {
  const response = await fetch(`${getFunctionApiUrl()}/get_emails`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(userIds)
  });
  if (response.type === "error") {
    console.error(response);
  }

  const jsonRes = await response.json();

  return jsonRes as TesterUserEmails;
};
