import { Location } from "history";

import { getDaysBetweenTimes } from "../types/Missions";

import i18n from "../custom/i18n";

import MapLocation from "./MapLocation";

import config from "../custom/config";

type PathParams = {
  featureType?: string;
  featureId?: string;
  mapLocation: MapLocation;
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const getLastSixMonths = (): any[] => {
  const today = new Date();
  let months = [];
  for (var i = 5; i >= 0; i -= 1) {
    let monthDate = new Date(today.getFullYear(), today.getMonth() - i, 1);
    months.push({
      monthName: monthNames[monthDate.getMonth()],
      year: monthDate.getFullYear(),
      monthNumber: monthDate.getMonth()
    });
  }
  return months;
};

export const extractPathnameParams = (location: Location<any>): PathParams => {
  // Extract feature type
  const regexFeatureTypeMatch = location.pathname.match(
    new RegExp(`${config.PAGES.displayFeature.path}\\/(\\w+)`)
  );
  let featureType = undefined;
  if (regexFeatureTypeMatch !== null) {
    featureType = regexFeatureTypeMatch[1];
  }

  // Extract feature ID
  let featureId = undefined;
  if (featureType !== undefined) {
    const regexFeatureIDMatch = location.pathname.match(
      new RegExp(
        `${config.PAGES.displayFeature.path}\\/${featureType}\\/(\\w+)`
      )
    );
    if (regexFeatureIDMatch !== null) {
      featureId = regexFeatureIDMatch[1];
    }
  }

  // Extracts map location
  const regexMapLocationMatch = location.pathname.match(
    new RegExp("@(-?\\d*\\.?\\d*),(-?\\d*\\.?\\d*),(\\d*\\.?\\d*)z")
  );
  const mapLocation =
    (regexMapLocationMatch &&
      new MapLocation(
        regexMapLocationMatch[1],
        regexMapLocationMatch[2],
        regexMapLocationMatch[3]
      )) ||
    new MapLocation();
  if (!regexMapLocationMatch) {
    mapLocation.zoom = config.ZOOM;
  }

  return { featureType, featureId, mapLocation };
};

export const getTextDurationBetweenTimes = (
  startTimeMs: number,
  endTimeMs: number
): string => {
  const daysRemaining = getDaysBetweenTimes(startTimeMs, endTimeMs);

  let duration;
  if (daysRemaining >= 31) {
    const months = Math.floor(daysRemaining / 31);
    duration = i18n.t("month_with_count", { count: Math.abs(months) });
  } else if (daysRemaining >= 7) {
    const weeks = Math.floor(daysRemaining / 7);
    const days = daysRemaining % 7;
    duration = `${i18n.t("week_with_count", { count: Math.abs(weeks) })}${
      days > 0
        ? ` ${i18n.t("and_conjunction")} ${i18n.t("day_with_count", {
            count: Math.abs(days)
          })}`
        : ``
    }`;
  } else {
    duration = i18n.t("day_with_count", { count: Math.abs(daysRemaining) });
  }

  if (daysRemaining < 0) {
    return i18n.t("missions_finished_text", { duration });
  }

  return i18n.t("missions_remaining_count_text", { duration });
};
