import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { getFunctionApiUrl } from "../../../features/firebase/firebaseInit";
import PageWrapper from "../../../components/PageWrapper";
import { ALL_READING_TYPES } from "../../../types/Readings/Shared";
import { getReadingsCsvURL } from "../../../features/firebase/readings/utils";

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    margin: `${theme.spacing(1)}px`,
    padding: `${theme.spacing(2)}px`
  }
}));

const ReadingsDownload = () => {
  const classes = useStyles();
  const history = useHistory();

  const downloadReadings = (reading: string) => {
    const downloadUrl = getReadingsCsvURL(getFunctionApiUrl(), reading);
    window.open(downloadUrl);
  };

  return (
    <PageWrapper
      label="Readings Admin Page"
      navigationHandler={{ handleBack: () => history.goBack() }}
    >
      <div>
        {ALL_READING_TYPES.map((reading: string) => (
          <Button
            variant="contained"
            color="primary"
            key={`${reading}-download-button`}
            className={classes.downloadButton}
            onClick={() => downloadReadings(reading)}
          >
            {`Downloading ${reading} reading CSV`}
          </Button>
        ))}
      </div>
    </PageWrapper>
  );
};

export default ReadingsDownload;
