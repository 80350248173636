import firebase from "firebase/compat/app";
import {
  READING_PHOTO_UPLOAD_NAME,
  READINGS_STORAGE_FOLDER
} from "../../../types/Readings/Shared";
import { ImageMetaData } from "../../../types/Photo";
import { Reading, ReadingFormData } from "../../../types/Readings/Base";
import {
  getStorageUrlRoot,
  HIGH_RES_PHOTO_NAME,
  READINGS_PHOTOS_STORAGE_BUCKET,
  THUMBNAIL_PHOTO_NAME
} from "../../../shared/utils";
import { COLIFORMS_READING_TYPE } from "../../../types/Readings/Coliforms";

export const getReadingStorageBucket = (
  storage: firebase.storage.Storage,
  readingType: string,
  readingId: string
) => {
  const bucket = storage.ref().bucket;
  return `${getStorageUrlRoot()}/${bucket}/${READINGS_PHOTOS_STORAGE_BUCKET}/${readingType}/${readingId}`;
};

export const getReading = async (
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  readingType: string,
  readingId: string
): Promise<Reading> => {
  let readingRef;
  try {
    readingRef = await firestore.collection(readingType).doc(readingId).get();
  } catch (error) {
    throw new Error(`Failed retrieving reading data from Firestore. ${error}`);
  }

  const data = readingRef.data() as ReadingFormData;

  const readingStorage = getReadingStorageBucket(
    storage,
    readingType,
    readingId
  );

  if (readingType === COLIFORMS_READING_TYPE) {
    return {
      ...data,
      id: readingId,
      main: `${readingStorage}/${HIGH_RES_PHOTO_NAME}`,
      second: `${readingStorage}/post_incubation/${HIGH_RES_PHOTO_NAME}`,
      thumbnail: `${readingStorage}/${THUMBNAIL_PHOTO_NAME}`
    };
  }

  return {
    ...data,
    id: readingId,
    main: `${readingStorage}/${HIGH_RES_PHOTO_NAME}`,
    thumbnail: `${readingStorage}/${THUMBNAIL_PHOTO_NAME}`
  };
};

export const uploadReadingPhoto = (
  storage: firebase.storage.Storage,
  readingStorageFolder: string,
  firestoreId: string,
  photo: ImageMetaData,
  subFolder?: string
): firebase.storage.UploadTask | undefined => {
  try {
    let photoStorageRef = storage
      .ref()
      .child(READINGS_STORAGE_FOLDER)
      .child(readingStorageFolder)
      .child(firestoreId);

    if (subFolder !== undefined) {
      photoStorageRef = photoStorageRef.child(subFolder);
    }

    photoStorageRef = photoStorageRef.child(READING_PHOTO_UPLOAD_NAME);

    const base64Image = photo.imgSrc.split(",")[1];
    return photoStorageRef.putString(base64Image, "base64", {
      contentType: "image/jpeg"
    });
  } catch (err) {
    console.error(
      `Failed to upload ${readingStorageFolder} reading photo: ${err}`
    );
  }
};

export const getReadingsCsvURL = (functionsUrl: string, reading: string) => {
  return `${functionsUrl}/download/${reading}`;
};
