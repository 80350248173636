import { useRef, useState } from "react";

import firebase from "firebase/compat/app";

import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useSurveys } from "providers/SurveysProvider";

import ReactSwipe from "react-swipe";

import PageWrapper from "components/PageWrapper";
import SurveySection from "./components/SurveySection";
import SurveyUploadDialog from "./SurveyUploadDialog";

import { uploadSurvey } from "features/firebase/surveys/api";

import {
  Answer,
  SectionAnswers,
  SurveyResponse,
  SurveyUploadHandle
} from "types/Survey";
import { useUser } from "providers/UserProvider";

import { INVALID_USER_ID } from "types/User";
import { linkToHome } from "custom/config";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    overflow: "hidden"
  },
  flexGrow: {
    flexGrow: 1
  },
  swipeActionsBottom: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-between",
  },
  button: {
    margin: theme.spacing(1)
  },
  uploadError: {
    color: "red"
  },
  progressContainer: {
    verticalAlign: "middle",
    marginRight: theme.spacing(2),
    padding: theme.spacing(1)
  }
}));

const carouselStyle = {
  wrapper: {
    height: "calc(100vh - 180px)",
    display: "flex",
    minHeight: "-webkit-fill-available"
  }
};

export default function SurveyPage() {
  const handleBack = {
    handleBack: () => history.goBack(),
    confirm: true,
    closeIcon: true
  };

  const { user } = useUser();
  const userId = user?.id || INVALID_USER_ID;

  const [uploadHandle, setUploadHandle] = useState<
    SurveyUploadHandle | undefined
  >();
  const [uploadError, setUploadError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const surveyData = useSurveys();
  const { surveyId } = useParams<{ surveyId: string }>();
  const surveys = surveyData?.surveys || [];
  const survey = surveys.find((s) => s.id === surveyId);

  const [pageIndex, setPageIndex] = useState(0);

  const generateSectionAnswers = () => {
    const surveySections = survey?.sections.map((section) => {
      let sectionAnswers: SectionAnswers = {
        sectionId: section.id,
        answers: [],
        valid: false
      };
      return sectionAnswers;
    });

    return surveySections;
  };

  const getPageTitle = () => {
    if (survey && survey.sections && survey.sections.length > 0) {
      return survey.sections[pageIndex].name;
    } else {
      return "Survey";
    }
  };

  let initialSurveyAnswers = generateSectionAnswers();

  const [surveyAnswers, setSurveyAnswers] = useState(
    initialSurveyAnswers as SectionAnswers[]
  );

  const reactSwipeEl = useRef<ReactSwipe | null>(null);

  const history = useHistory();

  const classes = useStyles();

  const onChangeSectionAnswers = (sectionId: string, answers: Answer[]) => {
    let currentAnswers = surveyAnswers.map((x) => x);
    const sectionIndex = currentAnswers.findIndex(
      (a) => a.sectionId === sectionId
    );
    currentAnswers[sectionIndex].answers = answers;
    currentAnswers[sectionIndex].valid = answers.every((a) => a.valid);
    setSurveyAnswers(currentAnswers as SectionAnswers[]);
  };

  const prevPage = () => {
    reactSwipeEl.current?.prev();
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    reactSwipeEl.current?.next();
    setPageIndex(pageIndex + 1);
  };

  const validPage = surveyAnswers[pageIndex].valid;
  const validSurvey = surveyAnswers.every((section) => section.valid);

  const onSubmitSurvey = async () => {
    setIsUploading(true);
    setUploadError("");

    let surveyResponse: SurveyResponse = {
      surveyId: surveyId,
      userId: userId,
      dateTime: Date.now()
    };
    surveyAnswers.forEach((sectionAnswers) => {
      sectionAnswers.answers.forEach((answer) => {
        surveyResponse[answer.questionId] = answer.value;
      });
    });

    try {
      const result = await uploadSurvey(
        firebase.firestore(),
        firebase.storage(),
        surveyResponse
      );
      setUploadHandle(result);
    } catch (err) {
      const errorMessage = `Error trying to upload reading. ${err}`;
      setUploadError(errorMessage);
    }
    setIsUploading(false);
  };

  const onCancelUpload = () => {
    setUploadHandle(undefined);
  };

  const onFinishUpload = () => {
    history.push(linkToHome());
  };

  return (
    <PageWrapper label={getPageTitle()} navigationHandler={handleBack}>
      <div className={classes.container}>
        {/* @ts-ignore */}
        <ReactSwipe
          style={carouselStyle}
          // @ts-ignore
          ref={(el) => (reactSwipeEl.current = el)}
          swipeOptions={{
            widthOfSiblingSlidePreview: 0,
            disableScroll: true,
            startSlide: pageIndex,
            continuous: false
          }}
        >
          {survey?.sections.map((section, index) => (
            <SurveySection
              key={`section_${section.id}`}
              onChangeSectionAnswers={(answers: Answer[]) =>
                onChangeSectionAnswers(section.id, answers)
              }
              section={section}
            />
          ))}
        </ReactSwipe>
        <div className={classes.swipeActionsBottom}>
          <Box display="inline" className={classes.progressContainer}>
            <Typography variant="caption" color="textSecondary">
              {`Page ${pageIndex + 1} of ${survey?.sections.length || 1}`}
            </Typography>
          </Box>

          {uploadError !== "" && (
            <div className={classes.uploadError}>{uploadError}</div>
          )}
          <div className={classes.flexGrow} />
          <div>
            {pageIndex > 0 && (
              <Button
                color="secondary"
                className={classes.button}
                onClick={() => prevPage()}
                disableElevation
              >
                Previous
              </Button>
            )}

            {survey && pageIndex < survey?.sections.length - 1 && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => nextPage()}
                disabled={!validPage}
                disableElevation
              >
                Next
              </Button>
            )}

            {survey && pageIndex === survey?.sections.length - 1 && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={onSubmitSurvey}
                disabled={!validSurvey || isUploading}
                disableElevation
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
      {uploadHandle !== undefined && (
        <SurveyUploadDialog
          uploadHandle={uploadHandle}
          closeModal={onCancelUpload}
          onSuccessfulContinue={onFinishUpload}
        />
      )}
    </PageWrapper>
  );
}
