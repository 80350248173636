import { useHistory } from "react-router";

import Alert from "@material-ui/lab/Alert";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { linkToLoginWithRedirectOnSuccess } from "routes/login/links";
import { linkToSurveyPage } from "routes/surveys/links";

import { useUser } from "./../providers/UserProvider";
import { useSurveys } from "./../providers/SurveysProvider";

import { isIphoneWithNotchAndCordova } from "./../utils";

import { Survey } from "types/Survey";

import { makeStyles } from "@material-ui/core/styles";

import config from "./../custom/config";

const useStyles = makeStyles((theme) => ({
  alert: {
    backgroundColor: theme.palette.background.paper
  },
  surveysContainer: {
    zIndex: 1000,
    position: "fixed",
    padding: theme.spacing(1),
    top: isIphoneWithNotchAndCordova()
      ? "calc(80px + env(safe-area-inset-top))"
      : "80px"
  },
  surveyInfo: {
    paddingBottom: theme.spacing(1)
  }
}));

const SurveyAlerts = (): JSX.Element => {
  const history = useHistory();
  const surveysData = useSurveys();
  const surveys = surveysData?.surveys || undefined;

  const { user } = useUser();

  const classes = useStyles();

  const handleLaunchSurvey = (surveyId: string) => {
    if (config.SECURITY.UPLOAD_REQUIRES_LOGIN && !user) {
      history.push(
        linkToLoginWithRedirectOnSuccess(linkToSurveyPage(surveyId))
      );
    } else {
      history.push(linkToSurveyPage(surveyId));
    }
  };

  return (
    <div className={classes.surveysContainer}>
      {surveys
        ? surveys.map((survey: Survey) => {
            const startDateTime = new Date(survey.fromDate);
            const endDateTime = new Date(survey.toDate);
            const now = new Date();
            const isSurveyActive = now >= startDateTime && now <= endDateTime;
            const milliSecondsToFinish = Math.floor(
              endDateTime.getTime() - now.getTime()
            );

            const days = Math.floor(milliSecondsToFinish / 86400000);
            const hours = Math.floor(
              (milliSecondsToFinish - days * 86400000) / 3600000
            );

            return (
              isSurveyActive && (
                <Alert key={`alert_survey_${survey.id}`} severity="info" variant="outlined" className={classes.alert}>
                  <Typography variant="body1" className={classes.surveyInfo}>
                    {`${survey.name} ends in ${days} days and ${hours} hours. `}
                    {survey.completed && survey.completed > 99
                      ? ` Over ${survey.completed} surveys completed!`
                      : ``}
                  </Typography>
                  <Button
                    key={`btn_survey_${survey.id}`}
                    fullWidth
                    disableElevation
                    onClick={() => handleLaunchSurvey(survey.id)}
                    variant="contained"
                    color="primary"
                  >
                    {`Join ${survey.name}`}
                  </Button>
                </Alert>
              )
            );
          })
        : null}
    </div>
  );
};

export default SurveyAlerts;
