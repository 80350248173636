import { createRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";

import { Capacitor } from "@capacitor/core";

import { DesktopPhotoFallback } from "components/common/DesktopPhotoFallback";

import { useGPSLocation } from "providers/LocationProvider";

import AddPhotoDialog from "pages/photo/components/AddPhotoDialog";
import loadPhoto from "pages/photo/pages/CategorisePhotoPage/utils";

import {
  CordovaCameraImage,
  isCordovaCameraImageFile
} from "../../photo/state/types";
import { Question } from "types/Survey";
import { ImageMetaData, photoIsMetaData } from "../../../types/Photo";

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(2)
  },
  legend: {
    marginBottom: theme.spacing(2)
  },
  photoWrapper: {
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(2)
  },
  photoPreview: {
    maxWidth: "100%",
    maxHeight: "180px"
  }
}));

type Props = {
  question: Question;
  onChangeQuestionValue: (
    questionId: string,
    value: ImageMetaData[] | string | undefined,
    valid: boolean
  ) => void;
};

export default function QuestionMultiplePhotos({
  question,
  onChangeQuestionValue
}: Props) {
  const classes = useStyles();
  const gpsLocation = useGPSLocation();

  const [photos, setPhotos] = useState<ImageMetaData[]>([]);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [valid, setValid] = useState(
    question.required ? (question.default ? true : false) : true
  );
  const [validationTriggered, setValidationTriggered] = useState(false);

  const desktopPhotoRef = createRef<HTMLInputElement>();

  const onPhotoLoaded = (photo: ImageMetaData) => {
    const newPhotos = (photos || []) as ImageMetaData[];
    newPhotos.push(photo);
    setPhotos(newPhotos);
    setValid(true);
    onChangeQuestionValue(question.id, photos, true);
    setValidationTriggered(true);
  };

  const handlePhotoSelect = (
    image: File | CordovaCameraImage,
    fromCamera: boolean
  ) => {
    const fileState = isCordovaCameraImageFile(image)
      ? {
          fileOrFileName: Capacitor.convertFileSrc(image.filename),
          cordovaMetadata: JSON.parse(image.json_metadata),
          fromCamera: fromCamera
        }
      : { fileOrFileName: image, fromCamera: fromCamera };

    loadPhoto({
      ...fileState,
      fromCamera,
      gpsLocation,
      callback: onPhotoLoaded
    });

    if (Capacitor.platform !== "web") {
      setOpenPhotoDialog(false);
    }
  };

  const error = !valid && validationTriggered;

  return (
    <>
      <FormControl
        required={question.required}
        error={error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend" className={classes.legend}>
          {question.name}
        </FormLabel>
        {photos.map((photo, index) => (
          <div className={classes.photoWrapper}>
            <img
              alt="Preview"
              src={photo && (photoIsMetaData(photo) ? photo.imgSrc : photo)}
              className={classes.photoPreview}
            />
          </div>
        ))}

        <Button
          className={classes.button}
          onClick={() =>
            !!window.cordova
              ? setOpenPhotoDialog(true)
              : desktopPhotoRef.current && desktopPhotoRef.current.click()
          }
          color="primary"
          variant="contained"
          disableElevation
          size="large"
        >
          Upload or take a photo
        </Button>
        <DesktopPhotoFallback
          ref={desktopPhotoRef}
          handlePhotoSelect={handlePhotoSelect}
        />
        {openPhotoDialog && (
          <AddPhotoDialog
            onClose={() => setOpenPhotoDialog(false)}
            handlePhotoSelect={handlePhotoSelect}
          />
        )}
        <FormHelperText>
          <span dangerouslySetInnerHTML={{ __html: question.description }} />
        </FormHelperText>
      </FormControl>
    </>
  );
}
