import { Component } from "react";
import { withRouter } from "react-router-dom";

import { withTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";

import AccountIcon from "@material-ui/icons/AccountCircleRounded";
import AddIcon from "@material-ui/icons/AddRounded";
import MoreIcon from "@material-ui/icons/MoreVertRounded";
import StarIcon from "@material-ui/icons/StarRounded";
import GraphIcon from "@material-ui/icons/BarChartRounded";

import { withStyles } from "@material-ui/core/styles";

import { Routes } from "routes/Routes";
import { linkToRecordPollutionPage } from "routes/record/links";
import {
  linkToLoginWithRedirectOnSuccess,
  linkToLogin
} from "routes/login/links";

import getMapIsVisible from "utils/getMapIsVisible";
import { isIphoneWithNotchAndCordova } from "utils";
import MapLocation from "./utils/MapLocation";
import { extractPathnameParams } from "./utils/utils";

import AppUrlListener from "./pages/AppUrlListener";

import Map from "./components/MapPage/Map";
import DrawerContainer from "./components/DrawerContainer";

import config from "./custom/config";

import { gtagEvent } from "./gtag.js";

import "./App.scss";

import { signOut } from "./features/firebase/authFirebase";

import { linkToMap } from "custom/config";
import { linkToAccountPage } from "routes/account/links";
import { linkToDashboardPage } from "routes/dashboard/links";
import { linkToMissionsPage } from "routes/missions/links";

const styles = theme => ({
  appBar: {
    top: "auto",
    bottom: 0
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    color: "white"
  },
  navigationCentre: {
    flexGrow: 1
  },
  label: {
    flexDirection: "column"
  },
  recordButton: {
    width: "72px",
    height: "72px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: isIphoneWithNotchAndCordova()
      ? "calc(env(safe-area-inset-bottom) + 40px)"
      : "40px",
    zIndex: 1250,
    "&:hover": {
      backgroundColor: "#fff"
    }
  }
});

class App extends Component {
  constructor (props) {
    super(props);

    this.state = {
      user: null,
      leftDrawerOpen: false,
      mapLocation: new MapLocation()
    };

    this.domRefInput = {};
  }

  async componentDidMount () {
    let { mapLocation } = extractPathnameParams(this.props.location);
    this.setState({ mapLocation });
  }

  async componentWillUnmount () {
    // Terrible hack !!! it will be fixed with redux
    this.setState = console.log;
  }

  handleRecordClick = () => {
    if (config.SECURITY.UPLOAD_REQUIRES_LOGIN && !this.props.user) {
      this.props.history.push(
        linkToLoginWithRedirectOnSuccess(linkToRecordPollutionPage())
      );
    } else {
      this.props.history.push(linkToRecordPollutionPage());
    }
  };

  toggleLeftDrawer = isItOpen => () => {
    this.setState({ leftDrawerOpen: isItOpen });
    gtagEvent(isItOpen ? "Opened" : "Closed", "Menu");
  };

  handleMapLocationChange = mapLocation => {
    const { location } = this.props.history;

    const pageName = location.pathname.split("@")[0].replaceAll("/", "");
    if (pageName !== "map") return;

    const currentMapLocation = extractPathnameParams(location).mapLocation;

    // change url coords if the coords are different and if we are in the map
    if (
      currentMapLocation == null ||
      !currentMapLocation.isEqual(mapLocation)
    ) {
      const currentUrl = this.props.location;
      const prefix = currentUrl.pathname.split("@")[0];
      const withSlash = prefix.endsWith("/") ? prefix : `${prefix}/`;
      const newUrl = `${withSlash}@${mapLocation.urlFormatted()}`;
      this.props.history.replace(newUrl);
      this.setState({ mapLocation });
    }
  };

  handleLocationClick = () => {
    gtagEvent("Location FAB clicked", "Map");
    const { latitude, longitude } = this.props.gpsLocation;
    this.setState({
      mapLocation: new MapLocation(
        latitude,
        longitude,
        this.state.mapLocation.zoom
      )
    });
  };

  handleFeatureClick = feature => {
    const { history } = this.props;

    let feature_type = "photos";
    if (feature.properties.readingType !== undefined) {
      feature_type = feature.properties.readingType;
    }

    if (feature.properties.description !== undefined) {
      feature_type = "incidents";
    }

    let pathname = `${config.PAGES.displayFeature.path}/${feature_type}/${feature.properties.id}`;
    const currentPath = history.location.pathname;

    const coordsUrl =
      currentPath.split("@")[1] ||
      new MapLocation(
        feature.geometry.coordinates[1],
        feature.geometry.coordinates[0],
        config.ZOOM_FLYTO
      ).urlFormatted();
    pathname =
      currentPath === config.PAGES.embeddable.path
        ? currentPath + pathname
        : pathname;

    // if it is in map, change the url
    if (getMapIsVisible(history.location.pathname)) {
      history.replace(`${currentPath.split("@")[0]}@${coordsUrl}`);
    }

    history.push(`${pathname}@${coordsUrl}`);
  };

  handleClickLoginLogout = async () => {
    if (this.props.user) {
      await signOut();
    } else {
      this.props.history.push(linkToLogin());
    }
  };

  handleExploreClick = () => {
    this.props.history.push(linkToMap());
  };

  handleMissionsClick = () => {
    this.props.history.push(linkToMissionsPage());
  };

  handleDashboardClick = () => {
    this.props.history.push(linkToDashboardPage());
  };

  handleMoreClick = () => {
    this.setState({ leftDrawerOpen: true });
  };

  handleAccountClick = () => {
    if (!this.props.user) {
      this.props.history.push(
        linkToLoginWithRedirectOnSuccess(linkToAccountPage())
      );
    } else {
      this.props.history.push(linkToAccountPage());
    }
  };

  render () {
    const {
      classes,
      user,
      setUser,
      history,
      gpsLocation,
      geojson,
      online,
      reloadFeatures,
      sponsorImage,
      selectedFeature
    } = this.props;
    const { leftDrawerOpen, mapLocation } = this.state;

    const pageName = history.location.pathname
      .split("@")[0]
      .replaceAll("/", "");

    return (
      <div className='geovation-app'>
        <main>
          <Map
            visible={pageName === "map"}
            geojson={geojson}
            user={user}
            config={config}
            embeddable={history.location.pathname.match(
              new RegExp(config.PAGES.embeddable.path, "g")
            )}
            handleFeatureClick={this.handleFeatureClick}
            handleMoreClick={this.handleMoreClick}
            mapLocation={mapLocation}
            handleMapLocationChange={this.handleMapLocationChange}
            handleLocationClick={this.handleLocationClick}
            gpsOffline={!(gpsLocation && gpsLocation.online)}
            gpsDisabled={!(gpsLocation && gpsLocation.updated)}
          />
          <AppUrlListener />
          <Routes
            user={user}
            setUser={setUser}
            gpsLocation={gpsLocation}
            online={online}
            geojson={geojson}
            reloadFeatures={reloadFeatures}
            handleFeatureClick={this.handleFeatureClick}
            selectedFeature={selectedFeature}
            sponsorImage={sponsorImage}
          />
        </main>

        <Snackbar
          open={!geojson}
          message={this.props.t("app_loading_photos_message")}
        />

        <DrawerContainer
          user={user}
          online={online}
          handleClickLoginLogout={this.handleClickLoginLogout}
          leftDrawerOpen={leftDrawerOpen}
          toggleLeftDrawer={this.toggleLeftDrawer}
          sponsorImage={sponsorImage}
        />

        <Button
          classes={{ root: classes.recordButton, label: classes.label }}
          onClick={this.handleRecordClick}
          disableElevation
        >
          <AddIcon />
          Record
        </Button>
        <AppBar
          style={{
            paddingBottom: isIphoneWithNotchAndCordova()
              ? "env(safe-area-inset-bottom)"
              : "0px"
          }}
          position='fixed'
          color='primary'
          className={classes.appBar}
          elevation={0}
        >
          <Toolbar>
          <Button
              size='small'
              classes={{ root: classes.button, label: classes.label }}
              onClick={this.handleMissionsClick}
              disableElevation
            >
              <StarIcon />
              Missions
            </Button>
            <Button
              size='small'
              classes={{ root: classes.button, label: classes.label }}
              onClick={this.handleDashboardClick}
              disableElevation
            >
              <GraphIcon />
              Stats
            </Button>
            <div className={classes.navigationCentre} />
            <Button
              size='small'
              classes={{ root: classes.button, label: classes.label }}
              onClick={this.handleAccountClick}
              disableElevation
            >
              <AccountIcon />
              Account
            </Button>
            <Button
              size='small'
              classes={{ root: classes.button, label: classes.label }}
              onClick={this.handleMoreClick}
              disableElevation
            >
              <MoreIcon />
              More
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(withTranslation()(App))
);
