import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend
} from "chart.js";

import { Bar } from "react-chartjs-2";

import { getLastSixMonths } from "utils/utils";

import { usePhotos } from "./../../../../providers/PhotosProvider";
import { useReadings } from "./../../../../providers/ReadingsProvider";

import { getReadingsByLastSixMonths } from "utils/waterReadingAnalytics";
import { getLitterByLastSixMonths } from "utils/litterAnalytics";

import styles from "standard.module.scss";

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend);

const chartOptions = {
  responsive: true,
  scales: {
    y: {
      display: true,
      title: {
        display: true,
        text: "Uploads"
      }
    }
  }
};

const chartLabels = getLastSixMonths().map((m) => m.monthName);

type Props = {
  userId: string | undefined | null;
  postcode: string | undefined | null;
  readingTypes: string[];
};

const ReadingActivityChart = ({ userId, readingTypes, postcode }: Props) => {
  const readings = useReadings();
  const photos = usePhotos();

  const waterReadingsLastSixMonths = getReadingsByLastSixMonths(
    readings?.features?.geojson?.features,
    userId,
    postcode
  );
  const litterLastSixMonths = getLitterByLastSixMonths(photos, userId);

  let datasets = [];
  if (readingTypes.indexOf("litter") !== -1) {
    datasets.push({
      label: "Litter",
      data: litterLastSixMonths.map((r) => r.picks),
      backgroundColor: styles.litter
    });
  }
  if (readingTypes.indexOf("water") !== -1) {
    datasets.push({
      label: "Water readings",
      data: waterReadingsLastSixMonths.map((r) => r.waterReadings),
      backgroundColor: styles.water
    });
  }
  let chartData = {
    labels: chartLabels,
    datasets: datasets
  };

  return <Bar options={chartOptions} data={chartData} />;
};

export default ReadingActivityChart;
