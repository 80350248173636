export const LITTER_PHOTOS_STORAGE_BUCKET = "photos";
export const READINGS_PHOTOS_STORAGE_BUCKET = "readings";
export const INCIDENTS_PHOTOS_STORAGE_BUCKET = "incidents";
export const MISSION_PHOTO_STORAGE_BUCKET = "missions";
export const SURVEYS_PHOTO_STORAGE_BUCKET = "surveys";

export const PHOTO_UPLOAD_NAME = "original.jpg";
export const HIGH_RES_PHOTO_NAME = "1024.jpg";
export const THUMBNAIL_PHOTO_NAME = "thumbnail.jpg";

export const HIGH_RES_PHOTO_SIZE = 1024;
export const THUMBNAIL_PHOTO_SIZE = 50;

export const PHOTOS_FIRESTORE_COLLECTION = "photos";
export const MISSION_FIRESTORE_COLLECTION = "missions";
export const INCIDENTS_FIRESTORE_COLLECTION = "incidents";
export const SURVEYS_FIRESTORE_COLLECTION = "surveys";

export const usingFirebaseEmulator = (): boolean => {
  return (
    process.env.IS_FIREBASE_CLI === "true" ||
    process.env.REACT_APP_EMULATOR_RUNNING === "YES"
  );
};

export const getStorageUrlRoot = (): string => {
  if (usingFirebaseEmulator()) {
    return `http://localhost:9199`;
  } else {
    return "https://storage.googleapis.com";
  }
};
