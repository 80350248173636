import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import User from "types/User";

import { getAllBadges } from "utils/badges";

import DeleteIcon from "@material-ui/icons/StarBorderRounded";
import WaterIcon from "@material-ui/icons/OpacityRounded";

import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import { Badge as BadgeType } from "utils/badges";

const useStyles = makeStyles((theme) => ({
  badges: {
    display: "flex",
    flexFlow: "row wrap",
    marginBottom: theme.spacing(2)
  },
  badge: {
    margin: theme.spacing()
  },
  badgeInfo: {
    boxShadow: "none",
    border: "1px solid #ccc",
  },
  info: {
    padding: theme.spacing()
  },
  primary: {
    backgroundColor: theme.palette.primary.main
  }
}));

type Props = {
  user: User;
};

const getBadgeIcon = (icon: string) => {
  switch (icon) {
    case "trash":
      return <DeleteIcon />;
    case "water":
      return <WaterIcon />;
    default:
      return <DeleteIcon />;
  }
};

type ConditionalWrapperProps = {
  children: React.ReactElement;
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
};
const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children
}) => (condition ? wrapper(children) : children);

export default function UserBadges({ user }: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [badgeInfoOpen, setBadgeInfoOpen] = useState(false);
  const [infoContent, setInfoContent] = useState("");

  const badges = getAllBadges();

  const openBadgeInfo = (event: any, badge: BadgeType) => {
    setAnchorEl(event.currentTarget);
    setBadgeInfoOpen(true);
    setInfoContent(badge.description);
  };

  return (
    <>
      <Typography variant="body1">
        You have {user?.badges?.length || 0}{" "}
        {user?.badges?.length === 1 ? "badge" : "badges"}
      </Typography>
      <br />
      <div className={classes.badges}>
        {badges.map((badge, idx) => {
          const badgeActive = user?.badges?.includes(badge.name) || false;
          return (
            <div className={classes.badge} key={`badge_${idx}`}>
              <ConditionalWrapper
                condition={badge.count !== null && badgeActive}
                wrapper={(children) => (
                  <Badge
                    overlap="circular"
                    badgeContent={badge.count}
                    color={badgeActive ? "primary" : "default"}
                  >
                    {children}
                  </Badge>
                )}
              >
                <Avatar
                  className={badgeActive ? classes.primary : ""}
                  onClick={(e) => openBadgeInfo(e, badge)}
                >
                  {getBadgeIcon(badge.icon)}
                </Avatar>
              </ConditionalWrapper>
            </div>
          );
        })}
      </div>
      <Popover
        open={badgeInfoOpen}
        anchorEl={anchorEl}
        onClose={() => setBadgeInfoOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        PaperProps={{
          className: classes.badgeInfo
        }}
      >
        <Typography className={classes.info}>{infoContent}</Typography>
      </Popover>
    </>
  );
}
