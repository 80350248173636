import useEffectOnMount from "hooks/useEffectOnMount";

import { Question } from "types/Survey";

type Props = {
  question: Question;
  onChangeQuestionValue: (
    questionId: string,
    value: number,
    valid: boolean
  ) => void;
};

export default function QuestionLocation({
  question,
  onChangeQuestionValue
}: Props) {
  const time = Date.now();

  useEffectOnMount(() => {
    onChangeQuestionValue(question.id, time, true);
  });

  return <></>;
}
