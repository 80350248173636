import { Route, Switch } from "react-router-dom";

import {
  linkToRecordIncident,
  linkToRecordPollutionPage,
  linkToRecordReading,
  linkToRecordWaterTestResults
} from "./links";

import IncidentUpload from "pages/incident/IncidentUpload";
import ReadingUpload from "../../pages/readings/upload/ReadingUpload";
import RecordPollutionHome from "../../pages/record/RecordPollutionHome";
import WaterTestResultsHome from "../../pages/record/WaterTestResultsHome";

import User from "types/User";
import Geojson from "types/Geojson";

type Props = {
  user: User;
  geojson: Geojson;
  setUser: (user: User) => void;
};

export default function RecordPollutionRoute(props: Props) {
  return (
    <Switch>
      <Route exact path={linkToRecordPollutionPage()}>
        <RecordPollutionHome />
      </Route>
      <Route path={linkToRecordWaterTestResults()}>
        <WaterTestResultsHome {...props} />
      </Route>
      <Route path={linkToRecordReading()}>
        <ReadingUpload />
      </Route>
      <Route path={linkToRecordIncident()}>
        <IncidentUpload />
      </Route>
    </Switch>
  );
}
