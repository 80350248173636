import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import React, { createRef, useState } from "react";
import {
  CordovaCameraImage,
  isCordovaCameraImageFile
} from "../../../photo/state/types";
import { DesktopPhotoFallback } from "../../../../components/common/DesktopPhotoFallback";
import AddPhotoDialog from "../../../photo/components/AddPhotoDialog";
import { Capacitor } from "@capacitor/core";
import loadPhoto from "../../../photo/pages/CategorisePhotoPage/utils";
import { useGPSLocation } from "../../../../providers/LocationProvider";
import { ImageMetaData, photoIsMetaData } from "../../../../types/Photo";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    padding: "5%"
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  button: {
    marginBottom: `${theme.spacing(2)}px`
  },
  testPhotoDescription: {
    marginBottom: "10px"
  },
  testPhotoWrapper: {
    width: "100%",
    textAlign: "center",
    padding: `${theme.spacing(2)}px 0px`
  },
  testPhotoPreview: {
    maxWidth: "100%",
    maxHeight: "180px"
  }
}));

type Props = {
  currentPhoto: ImageMetaData | string | undefined;
  onSubmit: (image: ImageMetaData | string | undefined) => void;
};

const ReadingPhotoUpload = ({ currentPhoto, onSubmit }: Props) => {
  const classes = useStyles();
  const gpsLocation = useGPSLocation();

  const [testPhoto, setTestPhoto] = useState<
    ImageMetaData | string | undefined
  >(currentPhoto);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);

  // Magic for handling photo uploads.
  const desktopPhotoRef = createRef<HTMLInputElement>();
  const handlePhotoSelect = (
    image: File | CordovaCameraImage,
    fromCamera: boolean
  ) => {
    const fileState = isCordovaCameraImageFile(image)
      ? {
          fileOrFileName: Capacitor.convertFileSrc(image.filename),
          cordovaMetadata: JSON.parse(image.json_metadata),
          fromCamera: fromCamera
        }
      : { fileOrFileName: image, fromCamera: fromCamera };
    loadPhoto({
      ...fileState,
      fromCamera,
      gpsLocation,
      callback: setTestPhoto
    });

    if (Capacitor.platform !== "web") {
      setOpenPhotoDialog(false);
    }
  };

  return (
    <>
      <div className={classes.text}>
        Take a photo of your test kit near the body of water you took the sample
        from. This is so we can automatically capture the location, time and
        date of the sample.
      </div>
      {testPhoto && (
        <div className={classes.testPhotoWrapper}>
          <img
            src={
              testPhoto &&
              (photoIsMetaData(testPhoto) ? testPhoto.imgSrc : testPhoto)
            }
            className={classes.testPhotoPreview}
            alt={""}
          />
        </div>
      )}
      <Button
        className={classes.button}
        onClick={() =>
          !!window.cordova
            ? setOpenPhotoDialog(true)
            : desktopPhotoRef.current && desktopPhotoRef.current.click()
        }
        color="primary"
        variant={testPhoto === undefined ? "contained" : "outlined"}
        disableElevation
        size="large"
      >
        {testPhoto === undefined ? "Upload or take photo" : "Change photo"}
      </Button>
      <DesktopPhotoFallback
        ref={desktopPhotoRef}
        handlePhotoSelect={handlePhotoSelect}
      />
      {openPhotoDialog && (
        <AddPhotoDialog
          onClose={() => setOpenPhotoDialog(false)}
          handlePhotoSelect={handlePhotoSelect}
        />
      )}
      <Button
        className={classes.button}
        onClick={() => onSubmit(testPhoto)}
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        {testPhoto !== undefined ? "Continue" : "I can't take a photo"}
      </Button>
    </>
  );
};

export default ReadingPhotoUpload;
