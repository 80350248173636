import {
  getReadingMapDataEntries,
  MapReadingData,
  Reading,
  ReadingFormData
} from "./Base";

export const TEMPERATURE_READING_TYPE = "temperature";

export type TemperatureInputs = {
  temperature: number;
};

export type TemperatureFormData = ReadingFormData & TemperatureInputs;
export type Temperature = Reading & TemperatureInputs;

export const TemperatureReadingMapLabel = "Temperature reading";
export const temperatureToMapData = (
  temperature: Temperature
): MapReadingData => {
  return {
    ...temperature,
    data: new Map<string, string>([
      ...getReadingMapDataEntries(temperature),
      [TemperatureReadingMapLabel, temperature.temperature.toString()]
    ])
  };
};
