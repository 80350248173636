import firebase from "firebase/compat/app";
import { Incident, IncidentData } from "types/Incident";
import {
  getStorageUrlRoot,
  PHOTO_UPLOAD_NAME,
  INCIDENTS_PHOTOS_STORAGE_BUCKET,
  INCIDENTS_FIRESTORE_COLLECTION,
  HIGH_RES_PHOTO_NAME,
  THUMBNAIL_PHOTO_NAME
} from "../../../shared/utils";
import { ImageMetaData } from "../../../types/Photo";

export const getIncidentStorageBucket = (
  storage: firebase.storage.Storage,
  incidentId: string
) => {
  const bucket = storage.ref().bucket;
  return `${getStorageUrlRoot()}/${bucket}/${INCIDENTS_PHOTOS_STORAGE_BUCKET}/${incidentId}`;
};

export const getIncident = async (
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  incidentId: string
): Promise<Incident> => {
  let incidentRef;
  try {
    incidentRef = await firestore
      .collection(INCIDENTS_FIRESTORE_COLLECTION)
      .doc(incidentId)
      .get();
  } catch (error) {
    throw new Error(
      `Failed retrieving pollution incident data from Firestore. ${error}`
    );
  }

  const data = incidentRef.data() as IncidentData;

  const incidentStorage = getIncidentStorageBucket(storage, incidentId);

  return {
    ...data,
    id: incidentId,
    main: `${incidentStorage}/${HIGH_RES_PHOTO_NAME}`,
    thumbnail: `${incidentStorage}/${THUMBNAIL_PHOTO_NAME}`
  };
};

export const uploadIncidentPhoto = (
  storage: firebase.storage.Storage,
  firestoreId: string,
  photo: ImageMetaData,
  subFolder?: string
): firebase.storage.UploadTask | undefined => {
  try {
    let photoStorageRef = storage
      .ref()
      .child(INCIDENTS_PHOTOS_STORAGE_BUCKET)
      .child(firestoreId);

    if (subFolder !== undefined) {
      photoStorageRef = photoStorageRef.child(subFolder);
    }

    photoStorageRef = photoStorageRef.child(PHOTO_UPLOAD_NAME);

    const base64Image = photo.imgSrc.split(",")[1];
    return photoStorageRef.putString(base64Image, "base64", {
      contentType: "image/jpeg"
    });
  } catch (err) {
    console.error(`Failed to upload pollution incident photo: ${err}`);
  }
};
