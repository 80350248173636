import {
  LocalNotificationSchema,
  ScheduleEvery
} from "@capacitor/local-notifications";

import { addUserNotification } from "features/firebase/userNotifications";

export const scheduleWaterQualityNotificationForUser = async (
  userId: string,
  scheduleEvery: ScheduleEvery
) => {
  let startDate = new Date();
  if (scheduleEvery === "two-weeks") {
    startDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 7 * 2);
  } else if (scheduleEvery === "week") {
    startDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 7);
  } else if (scheduleEvery === "month") {
    startDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 30);
  } else if (scheduleEvery === "hour") {
    startDate = new Date(startDate.getTime() + 1000 * 60 * 60);
  }
  let notification = {
    id: 1,
    title: "Water quality reading reminder",
    body: "It's time to take another water quality reading.",
    group: "planet-patrol",
    schedule: {
      repeats: true,
      at: startDate,
      every: scheduleEvery,
      allowWhileIdle: true
    }
  } as LocalNotificationSchema;
  const notifications = await addUserNotification(userId, notification);
  return notifications;
};
