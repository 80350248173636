import React, { useCallback, useContext, useState } from "react";
import Feature, {
  EMPTY_FEATURES_CONTAINER,
  FeaturesContainer,
  merge
} from "types/Feature";
import useAsyncEffect from "hooks/useAsyncEffect";
import { Map as ImmutableMap } from "immutable";
import firebase from "firebase/compat/app";
import { getFunctionApiUrl } from "../features/firebase/firebaseInit";
import { INVALID_LAT_LON } from "../types/GPSLocation";
import { fetchIncidents } from "features/firebase/incidents/api";
import { Incident, MapIncidentData } from "types/Incident";

const toMapIncidentData = (incident: Incident): MapIncidentData => {
  try {
    const incidentDateTime = new Date(incident.dateTime);

    return {
      ...incident,
      data: new Map<string, string>([
        ["Waterway", incident.waterwayName.toString()],
        ["Description", incident.description.toString()],
        ["When", `${incidentDateTime!.toLocaleDateString()} ${incidentDateTime!.toLocaleTimeString()}`]
      ])
    };
  } catch (error) {
    console.error(`Failed extracting map reading data. Unknown reading type.`);
    return {
      ...incident,
      data: new Map()
    };
  }
};

export const incidentToFeature = (incident: Incident): Feature => {
  return {
    feature: "Feature",
    geometry: {
      type: "Point",
      coordinates:
        incident.location !== undefined && incident.location !== null
          ? [incident.location.longitude, incident.location.latitude]
          : [INVALID_LAT_LON.longitude, INVALID_LAT_LON.latitude]
    },
    properties: toMapIncidentData(incident)
  };
};

const incidentsToFeaturesContainer = (
  incidents: Incident[]
): FeaturesContainer => {
  const features = incidents.map((incident) => incidentToFeature(incident));
  return {
    featuresDict: ImmutableMap(
      features.map((feature) => [feature.properties.id, feature])
    ),
    geojson: {
      type: "FeatureCollection",
      features
    }
  };
};

type FeaturesProviderData = {
  features: FeaturesContainer;
  reload: () => Promise<void>;
};

const EMPTY_INCIDENTS_CONTEXT: FeaturesProviderData = {
  features: EMPTY_FEATURES_CONTAINER,
  reload: async () => {}
};

export const IncidentsContext = React.createContext<FeaturesProviderData>(
  EMPTY_INCIDENTS_CONTEXT
);

type Props = {
  children?: React.ReactChild[];
};

export const IncidentsProvider = ({ children }: Props) => {
  const updateIncidents = async () => {
    try {
      const newIncidents = await fetchIncidents(
        firebase.storage(),
        getFunctionApiUrl()
      );
      const newFeatures = incidentsToFeaturesContainer(newIncidents);
      setIncidents((current: FeaturesProviderData) => ({
        ...current,
        features: merge(current.features, newFeatures)
      }));
    } catch (error) {
      console.error(
        `Error fetching incidents from Firestore functions. ${error}`
      );
    }
  };

  const [incidents, setIncidents] = useState<FeaturesProviderData>({
    features: EMPTY_FEATURES_CONTAINER,
    reload: useCallback(async () => {
      setIncidents(EMPTY_INCIDENTS_CONTEXT);
      await updateIncidents();
    }, [])
  });

  useAsyncEffect(async () => {
    await updateIncidents();
  }, []);

  // TODO - Investigate whether we want to add realtime subscriptions like photos have?

  return (
    <IncidentsContext.Provider value={incidents}>
      {children}
    </IncidentsContext.Provider>
  );
};

export const useIncidents = () => useContext(IncidentsContext);
