import firebase from "firebase/compat/app";

const firestore = firebase.firestore();

const usersPath = () => "users";

export const updateUserBadges = async (userId: string, badges: string[]) => {
  const userRef = firestore.collection(usersPath()).doc(userId);

  try {
    await userRef.update({
      badges: badges
    });
  } catch (e) {}

  return badges;
};
