import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import _ from "lodash";

import { uploadSurveyPhoto } from "./utils";
import { ImageMetaData, photoIsMetaData } from "../../../types/Photo";
import { SURVEYS_FIRESTORE_COLLECTION } from "../../../shared/utils";

import { SurveyResponse, SurveyUploadHandle } from "types/Survey";

export const uploadSurvey = async (
  firestore: firebase.firestore.Firestore,
  storage: firebase.storage.Storage,
  survey: SurveyResponse
): Promise<SurveyUploadHandle> => {
  // We get rid of photo from the Firestore data because it's kept in Storage instead.

  let firestoreData: Omit<SurveyResponse, "photo"> = _.omit(survey, "photo");
  if (
    survey.photo !== undefined &&
    photoIsMetaData(survey.photo as ImageMetaData)
  ) {
    const surveyPhoto = survey.photo as ImageMetaData;
    firestoreData.photoLocation = surveyPhoto.imgLocation;
  }

  // Create the Firestore entry.
  let firestoreId;
  try {
    const doc = await firestore
      .collection(SURVEYS_FIRESTORE_COLLECTION)
      .add(firestoreData);
    firestoreId = doc.id;
  } catch (err) {
    throw new Error(`Failed creating survey firestore document. ${err}`);
  }

  let uploadTasks = [];

  // Upload reading photo (if exists).
  if (
    survey.photo !== undefined &&
    photoIsMetaData(survey.photo as ImageMetaData)
  ) {
    const readingPhotoUploadTask = uploadSurveyPhoto(
      storage,
      firestoreId,
      survey.photo as ImageMetaData
    );
    if (readingPhotoUploadTask !== undefined) {
      uploadTasks.push(readingPhotoUploadTask);
    }
  }

  return { firestoreId, uploadTasks };
};
