import { useState } from "react";

import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";

import styles from "standard.module.scss";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex111",
    flexFlow: "column",
    padding: "5%"
  },
  heading: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(2)}px`
  },
  text: {
    marginBottom: `${theme.spacing(2)}px`
  },
  waterwayInput: {
    border: "none",
    borderRadius: "5px",
    padding: `${theme.spacing(1)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    background: styles.lightGrey,
    fontSize: 16,
    boxSizing: "border-box",
    width: "100%",
    textOverflow: "ellipsis",
    "&:focus": {
      outline: "none"
    }
  },
  button: {
    width: "100%"
  }
}));

type Props = {
  description: string;
  onSubmit: (description: string) => void;
};

const IncidentDescription = ({ description, onSubmit }: Props) => {
  const classes = useStyles();

  const [descriptionCurrent, setDescriptionCurrent] = useState(description);

  return (
    <>
      <div className={classes.heading}>Description of the pollution</div>
      <div className={classes.text}>
        Provide as much information that you know as possible.
      </div>
      <div className={classes.text}>
        For example: potential pollution sources, any smells, how long the
        pollution has been there and whether it has happened before.
      </div>
      <textarea
        rows={6}
        className={classes.waterwayInput}
        value={descriptionCurrent}
        onChange={(e) => setDescriptionCurrent(e.target.value)}
      />
      <Button
        className={classes.button}
        onClick={() => onSubmit(descriptionCurrent)}
        disabled={descriptionCurrent === ""}
        color="primary"
        variant="contained"
        size="large"
        disableElevation
      >
        Continue
      </Button>
    </>
  );
};

export default IncidentDescription;
