import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";

import { linkToNewPhoto } from "../../routes/photo/routes/new/links";
import { linkToRecordWaterTestResults } from "../../routes/record/links";
import { linkToRecordIncident } from "../../routes/record/links";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import PageWrapper from "../../components/PageWrapper";
import { linkToMap } from "custom/config";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    backgroundColor: "#EDEDED",
    height: "100%"
  },
  button: {
    marginTop: `${theme.spacing(2)}px`,
    width: "100%"
  },
  subheadings: {
    fontWeight: "bold"
  },
  buttonCard: {
    marginBottom: `${theme.spacing(2)}px`,
    width: "100%"
  }
}));

const RecordPollutionHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleClose = () => history.push(linkToMap());

  return (
    <PageWrapper
      label={"Record pollution data"}
      navigationHandler={{ handleClose: handleClose }}
    >
      <div className={classes.wrapper}>
        <Card className={classes.buttonCard} variant="outlined">
          <CardContent>
            <Typography
              variant="h6"
              color="primary"
              className={classes.subheadings}
            >
              Record litter
            </Typography>
            <Button
              variant="contained"
              size="large"
              disableElevation
              className={classes.button}
              color="primary"
              onClick={() => history.push(linkToNewPhoto())}
            >
              Record litter details
            </Button>
          </CardContent>
        </Card>
        <Card className={classes.buttonCard} variant="outlined">
          <CardContent>
            <Typography
              variant="h6"
              color="primary"
              className={classes.subheadings}
            >
              Record water quality reading
            </Typography>
            <Button
              variant="contained"
              size="large"
              disableElevation
              className={classes.button}
              color="primary"
              onClick={() => history.push(linkToRecordWaterTestResults())}
            >
              Record water quality
            </Button>
          </CardContent>
        </Card>
        <Card className={classes.buttonCard} variant="outlined">
          <CardContent>
            <Typography
              variant="h6"
              color="primary"
              className={classes.subheadings}
            >
              Report a water pollution incident
            </Typography>
            <Button
              variant="contained"
              size="large"
              disableElevation
              className={classes.button}
              color="primary"
              onClick={() => history.push(linkToRecordIncident())}
            >
              Report water pollution
            </Button>
          </CardContent>
        </Card>
      </div>
    </PageWrapper>
  );
};

export default RecordPollutionHome;
