import { Route, Switch } from "react-router";

import ReadingsDownload from "../../../pages/admin/readings/ReadingsDownload";

import linkToReadingsDownload from "./link";

export default function ReadingsDownloadRoute() {
  return (
    <Switch>
      <Route path={linkToReadingsDownload()} exact>
        <ReadingsDownload />
      </Route>
    </Switch>
  );
}
